import React, { useContext, useRef, useState, useEffect } from "react";
import WaySideBarCoach from "./WaySideBarCoach";
import WaySideBarCoachCourse from "./WaySideBarCoachCourse";
import { Link, useHistory, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import WayBookContext from "./WaybookContext";
import { ApiUrl, AttachementsUrl } from "../../config";
import {
  bcrypt,
  dcrypt,
  uInfo,
  uToken,
  currentDate,
  NumberWithCommas,
  canPurchaseAddon,
  hasPurchasedAddon,
  includedInPlanAddon,
} from "../../useToken";
import swal from "sweetalert";

export default function WayCoachTraining(props) {
  const params = React.useContext(WayBookContext);

  const [addonData, setAddonData] = useState({});

  const [canEditCocah, setCanEditCocah] = useState(
    uInfo().type === "Coach"
      ? JSON.parse(localStorage.getItem("can_edit_coach"))
      : false
  );
  const [action, setAction] = useState(0);
  //const [coursePaid, setCoursePaid] = useState(uInfo().type === 'Coach' ? JSON.parse(localStorage.getItem("course_free_paid")) : false);
  const history = useHistory();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const [module, setModule] = useState(
    urlParams.get("module") ? urlParams.get("module") : ""
  );

  const checkSecParam = () => {
    return urlParams.has("sec");
  };

  const [categoriesList, setCategoriesList] = useState([]);

  const coachCategoriesList = (categories) => {
    setCategoriesList(categories);
  };

  useEffect(() => {
    if (module) {
      params.setAction(module);
    }

    getAddonDetail();
  }, []);

  useEffect(() => {
    if (categoriesList && categoriesList.length > 0) {
      props.updateCategoriesList(categoriesList);
      props.isCoachDataLoaded(true);
    }
  }, [categoriesList]);

  const handleSelect = (e) => {
    if (e == 2 || e == 3) {
      params.getTagLists("subject", 0);
    }
    if (!checkSecParam()) {
      history.push(
        `/training/${bcrypt(0)}/${bcrypt(0)}?sec=Y29hY2g=&module=${e}`
      );
    }
    params.setAction(e);
  };

  const getAddonDetail = () => {
    fetch(`${ApiUrl}addons/detail`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: 4 }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setAddonData(response.data.record);
        } else {
          //   toast(response.message, {
          //     hideProgressBar: true,
          //     position: "top-center",
          //     transition: Flip,
          //     className: "toast-custom-cls error",
          //   });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const createAddonInvoice = (addonData) => {
    // event.preventDefault();
    fetch(`${ApiUrl}` + "add/invoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        addon_id: addonData.id,
        package_id: "0",
        product_id: "0",
        master_type: "addon",
        created_for_id: uInfo().user_id,
        invoice_currency_code: "USD",
        invoice_for: "Coach",
        items_data: [
          {
            items: addonData.title,
            price: addonData.price,
            quantity: "1",
            readOnly: "0",
            total: addonData.price,
          },
        ],
        payment_method: "stripe",
        renewal_interval:
          addonData.pricing_interval == "Monthly" ? "Monthly" : "None",
        start_date: currentDate(),
        total_price: addonData.price,
        type:
          addonData.pricing_interval == "Monthly" ? "recurring" : "one_time",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          history.push(data.data.payment_link);
        } else {
          swal({
            title: data.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <WayBookContext.Provider value={params}>
      <div className={`MyCoaching ${canEditCocah ? "" : "mytraning"}`}>
        {hasPurchasedAddon(4) || includedInPlanAddon(4) ? (
          <>
            <div className="d-flex  w-100 WayBookDropDown py-2 justify-content-between mt-2 pe-2 ps-1">
              {/* {canEditCocah &&  uInfo().coach_can_sell_trainings === "active_paid" ? ( */}
              {canEditCocah &&
              (hasPurchasedAddon(4) || includedInPlanAddon(4)) ? (
                <>
                  <Link to={"/trainings"} className="p-1 OpenTraining">
                    {" "}
                    My Training
                  </Link>
                </>
              ) : (
                <>
                  {uInfo().type == "Client" ? (
                    <>
                      <Link to={"/trainings"} className="p-1">
                        My Training{" "}
                      </Link>

                      <Link to={"/client/profile"} className="p-1">
                        {" "}
                        Back
                      </Link>
                    </>
                  ) : (
                    <Link to={"/trainings"} className="p-1">
                      {" "}
                      My Training{" "}
                    </Link>
                  )}
                </>
              )}
              <div className="d-flex justify-content-between align-items-center">
                <div className="form-check form-switch py-0 d-flex px-0">
                  <label className="mb-0 me-2">Edit</label>
                  <input
                    type="checkbox"
                    className="form-check-input ms-0"
                    id="customSwitch2"
                    checked={JSON.parse(canEditCocah)}
                    onChange={(e) => {
                      setCanEditCocah(e.target.checked);
                      localStorage.setItem("can_edit_coach", e.target.checked);
                      window.location.reload();
                    }}
                  />
                </div>

                {canEditCocah && (
                  <Dropdown
                    className="align-self-center"
                    onSelect={handleSelect}
                  >
                    <Dropdown.Toggle
                      title="Add New"
                      variant="link"
                      id="dropdown-basic"
                    >
                      <i className="fas fa-plus"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="1">Course</Dropdown.Item>
                      <Dropdown.Item eventKey="2">Module</Dropdown.Item>
                      <Dropdown.Item eventKey="3">Lesson</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            </div>
            <div className="way-sidebarCoach px-2">
              <WaySideBarCoachCourse />
              <WaySideBarCoach
                updateCategoriesList={coachCategoriesList}
              />
            </div>
          </>
        ) : (
          <>
            <div className="d-flex  w-100 WayBookDropDown py-2 justify-content-between mt-2 pe-2 ps-1">
              My Trainings
            </div>
            <div className="Purchese m-3">
              <div className="mb-1">
                <h5 className="d-none">
                  Add-on: <span className="fst-italic"> {addonData.title}</span>
                </h5>
                <p>{addonData.description}</p>
                <h6 className=" mt-3">
                  Price: ${NumberWithCommas(Math.round(addonData.price))}{" "}
                  {addonData.pricing_interval === "Monthly" ? "/month" : ""}
                </h6>
              </div>
              <div className="mt-3">
                {canPurchaseAddon(addonData.id) ? (
                  <p className="fw-light fst-italic mb-2">
                    To enable and get access to this feature you need to
                    purchase this add-on.
                  </p>
                ) : (
                  <p className="fw-light fst-italic mb-2">
                    This add-on is not available for your current package. To
                    get access to this feature you need to upgrade your package.
                  </p>
                )}

                <div class="text-center mt-3">
                  {canPurchaseAddon(addonData.id) ? (
                    // <Link
                    //   className=""
                    //   to={"/addons/purchase/" + bcrypt(addonData.id)}
                    // >
                    <button
                      className="text-center btn btn-success"
                      type="button"
                      onClick={(e) => {
                        e.preventDefault();
                        createAddonInvoice(addonData);
                      }}
                    >
                      Purchase Add-on: $
                      {NumberWithCommas(Math.round(addonData.price))}
                    </button>
                  ) : (
                    // </Link>
                    <button
                      className="btn btn-secondary"
                      type="button"
                      disabled
                    >
                      Purchase Add-on: $
                      {NumberWithCommas(Math.round(addonData.price))}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </WayBookContext.Provider>
  );
}
