import React from "react";
// import DocViewer from 'react-doc-viewer';

function Test() {
  const questions = [
    { quiz_id: 1, question: "What is React?" },
    { quiz_id: 2, question: "What is JSX?" },
  ];

  const matchedQuestion = { question: "What is Redux?" }; // Notice it doesn't have a quiz_id

  const matchedIndex = questions.findIndex(
    (q) => q.quiz_id === matchedQuestion.quiz_id
  );

  console.log("matchedIndex", matchedIndex);

  return (
    <div>
      <h1>Document Viewer</h1>
      {/* <DocViewer documents={docs} /> */}
    </div>
  );
}

export default Test;
