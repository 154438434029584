import React, { useEffect, useState } from 'react';
import Loader from "react-loader";
import swal from 'sweetalert';
import { ApiUrl, AttachementsUrl } from '../../config';
import { bcrypt, uToken, uInfo } from '../../useToken';
import WayBookContext from './WaybookContext';
import Dropdown from 'react-bootstrap/Dropdown';
import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ProgressBar from 'react-bootstrap/ProgressBar';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function SubjectLists(props) {
	const params = React.useContext(WayBookContext);
	const { search } = useLocation();
    const url_params = new URLSearchParams(search);
	const is_coach_section = url_params.get('sec') ? 'Yes' : "No";
	const [lists, setLists] = useState([]);
	const [list, setList] = useState({});
	const [loaded, setLoaded] = useState(false);
	const [new_subject_i, setNewSubjectI] = useState(0);
	const [url_subject_id] = useState();
	useEffect(() => {
		getWayBookLists();
	}, []);

	const getWayBookLists = () => {
		fetch(`${ApiUrl}` + "get/waybook/subjects/" + params.subject, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ` + uToken(),
			},
			body: JSON.stringify({ is_coach_section: is_coach_section, can_edit: params.can_edit, can_edit_coach: params.can_edit_coach }),
		})
			.then((response) => response.json())
			//Then with the data from the response in JSON...
			.then((response) => {
				console.log("Responsejj:", response);
				if (response.status === true) {
					setLists(response.data.records);
					setList(response.data.record);

					response.data.records && response.data.records.map((item, idx) => {
						console.log(idx , 'url_subject_id')
						if (item.cat_id == url_subject_id) {
							setNewSubjectI(idx);
						}
						return null; // Need to return something for .map
					});
				} else {
					swal({
						title: response.message,
						icon: "warning",
					});
				}
				setLoaded(true);

			})
			//Then with the error genereted...
			.catch((error) => {
				setLoaded(true);

				console.error("Error:", error);
			});
	};

	const updateWayBookSubjectListOrder = (newlists) => {
		fetch(`${ApiUrl}` + "update/waybook/subjects/order/" + params.subject, {
			method: "POST",
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				Authorization: `Bearer ` + uToken(),
			},
			body: JSON.stringify({ can_edit: params.can_edit || params.can_edit_coach ? true : false, lists: newlists }),
		})
			.then((response) => response.json())
			//Then with the data from the response in JSON...
			.then((response) => {
				if (response.status === false) {
					swal({
						title: response.message,
						icon: "warning",
					});
					getWayBookLists();

				} else {
					params.updatetWayBookLists();
				}
			})
			//Then with the error genereted...
			.catch((error) => {
				console.error("Error:", error);
			});
	};

	const dragItem = useRef();
	const dragOverItem = useRef();
	const dragStart = (e, position) => {
		// e.prevenDefault(); remove this for reordering


		if (!params.can_edit) {
			e.prevenDefault();
		}
		dragItem.current = position;
	};

	const dragEnter = (e, position) => {
		// e.preventDefault();
		dragOverItem.current = position;
		// console.log(e.target.innerHTML);
	};

	const dropTwo = (e, i) => {
		const copyListItems = [...lists];
		const dragItemContent = copyListItems[dragItem.current];
		copyListItems.splice(dragItem.current, 1);
		copyListItems.splice(dragOverItem.current, 0, dragItemContent);
		dragItem.current = null;
		dragOverItem.current = null;

		if (!copyListItems.some(element => element === undefined)) {
			setLists(copyListItems);
			updateWayBookSubjectListOrder(copyListItems)
		}
		console.log(copyListItems)
	};


	return (
		<div>
			<Loader loaded={loaded}></Loader>
			<div className='row'>
				<div className='col-lg-12'>
					<div className='TraningRightContent'>
						<div className='way-subject-header pb-2'>
							<div className='modal-header pb-0 border-0'>
								<h5 className='mb-0'>
									{
										list.image && (
											<img className='me-2 d-inline-block' height={20} width={20} src={`${AttachementsUrl}/user_${list.user_id}/training/${list.image}`} alt="" />
										)
									}
									{list.title}
									{
										(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) && (
											<button className="btn btn-sm btn-link" onClick={() => params.subjectOrDocumentAction('Subject', list.cat_id, list.title, 'Update', list.training_for, list.tag_training, list.image, list.user_id,'left',list.master_id,list.badge_image,list.badge_description, list.coaching_points , '' , list.training_certifications)}>
												<i className='fas fa-edit'></i>
											</button>
										)
									}
								</h5>
								{(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) && (
									<div className='NextBackBtn edit-step-action'>
										<button onClick={() => {params.getTagLists('document', list.cat_id);params.Action(3, list.cat_id)}} className='BackBtn btn btn-secondary btn-sm'> Create New Lesson</button>
									</div>
								)}
							</div>
						</div>
						<div className='subject-document-lists'>
							<table className='table table-stripped content-document-card-item'>
								<tbody>
									{
									lists && lists.map((row, i) => {
											const queryParam1 = is_coach_section === "Yes" ? '&sec=Y29hY2g=' : '';
											const queryParam2 = is_coach_section === "Yes" ? '?sec=Y29hY2g=' : '';
											return <tr key={i}
											onDragEnd={(e) => dropTwo(e, i)}
											onDragEnter={(e) => dragEnter(e, i)}
											className="content-page-documents-list-item">
												{
													(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) && (
														<td onDragStart={(e) => dragStart(e, i)} draggable className='draggable drag-handle document-drag-handle has-tooltip'>
															<i className='fas fa-grip-vertical'></i>
														</td>
													)
												}
												<td>
													{/* <Link className={`${row.disabled ? 'disabled' : ''}`} to={`/${row.first_step_type == 'quiz' ? 'trainingsreview' : 'trainings'}/${bcrypt(params.category)}/${bcrypt(params.subject)}/${bcrypt(row.cat_id)}${row.first_step_link ? row.first_step_link : ''}${queryParam1}`}>
														{row.title}
													</Link> */}
													<Link
													className={`${row.disabled ? 'disabled' : ''}`}
													to={`/${
														row.first_step_type === 'quiz' ? 'trainingsreview' : 'trainings'
													}/${bcrypt(params.category)}/${bcrypt(params.subject)}/${bcrypt(row.cat_id)}${
														(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) ? queryParam2 : row.first_step_link ? row.first_step_link+queryParam1 : queryParam2
													}`}
													>
													{row.title}
													</Link>

													<br />
													<small> In <img className='mx-1' height={15} width={15} src={`${AttachementsUrl}/user_${list.user_id}/training/${list.image}`} alt="" /> {list.title} </small>
												</td>

										{
											!params.can_edit && !params.can_edit_coach &&  is_coach_section === "No" && (
												<td>
													<Link className={`${row.disabled ? 'disabled' : ''}`} to={`/trainings/${bcrypt(params.category)}/${bcrypt(params.subject)}/${bcrypt(row.cat_id)}${queryParam2}`}>
														Steps {row.total_steps}
													</Link>
												</td>

											)
										}

										{
											!(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) && (
												<td >
													<span className='d-flex align-items-center gap-1'>
															<OverlayTrigger
															placement="left"
															delay={{ show: 250, hide: 400 }}
															overlay={<Tooltip id="button-tooltip">{row.percentage}%</Tooltip>}
															>
																<ProgressBar now={row.percentage}  className="w-100"/>
															</OverlayTrigger> {row.percentage}% 
													</span>
												</td>

											)
										}

										{
											(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) && (
												<td>{row.status == 'Publish' ? 'Published' : row.status}</td>
											)
										}
										{
											(params.can_edit || (params.can_edit_coach && is_coach_section === "Yes")) && (
												<td>
													<Dropdown onSelect={(e) => params.subjectOrDocumentAction('Document', row.cat_id, row.title, e, row.training_for, row.tag_training,'','','center',row.master_id, '', '')}>
														<Dropdown.Toggle variant="link">
															<i className='fas fa-ellipsis-h'></i>
														</Dropdown.Toggle>

														<Dropdown.Menu>
															{
																row.status == 'Publish' ?
																	<Dropdown.Item eventKey="Draft">Switch to Unpublish</Dropdown.Item>
																	:
																	<Dropdown.Item eventKey="Publish">Switch to Publish</Dropdown.Item>

															}
															<Dropdown.Item eventKey="Update">Rename</Dropdown.Item>
															<Dropdown.Item eventKey="Move">Move</Dropdown.Item>
															<Dropdown.Item eventKey="Delete">Delete</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											)
										}

									</tr>

								})
							}
								</tbody>
							</table>
						</div>
					</div>
		    	</div>
	    	</div>
		</div>
	);
}

export default SubjectLists;
