import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";
import Select from "react-select";
import Helmet from "react-helmet";
import "../../assets/css/courses_list.css";
import { ApiUrl, ProductName, AttachementsUrl } from "../../config";
import { uToken, uInfo } from "../../useToken";
import { Link, useLocation, useHistory } from "react-router-dom";
import swal from "sweetalert";
import Dropdown from "react-bootstrap/Dropdown";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
// import Swal from 'sweetalert2';

export default function CoursesList() {
  const [pageName, setPageName] = useState("Courses List");
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseDetail, setCourseDetail] = useState([]);
  const [trainingTags, setTrainingTags] = useState([]);
  const [courseTags, setCourseTags] = useState([]);
  const [selectedCourseTag, setSelectedCourseTag] = useState("");
  const [editModal, setEditModal] = useState(false);
  const [levelInformation, setLevelInformation] = useState([]);
  const [level, setLevel] = useState("");
  const [type, setType] = useState("");
  const [inputs, setInputs] = useState({});
  const [inputValue, setInputValue] = useState("");
  const [noOptions, setNoOptions] = useState(false);
  const [new_training_tags, setNewTrainingTags] = useState([]);
  const [newTrainingFor, setNewTrainingFor] = useState([]);

  const [filterTriggered, setFilterTriggered] = useState(false);
  const routeHistory = useHistory();
  const [certifications, setCertifications] = useState([]);

  const { search } = useLocation();
  const url_params = new URLSearchParams(search);
  // const is_coach_section = url_params.get("sec") ? "Yes" : "No";
  const is_coach_section = uInfo().type === "Coach" ? "Yes" : "No";
  const [canEdit, setCanEdit] = useState(
    uInfo().user_id == 2 ? JSON.parse(localStorage.getItem("can_edit")) : false
  );
  const [list_tags, setListTags] = useState([]);
  const [training_fors, settrainingFors] = useState([]);

  const [checkedItems, setCheckedItems] = useState({});
  const [checkedTrainingForItems, setTrainingForCheckedItems] = useState({});

  const userType = uInfo().type;

  var user_types;

  if (is_coach_section === "Yes") {
    user_types = [
      { id: 3, title: "Client" },
      { id: 6, title: "Team Coach" },
    ];
  } else {
    user_types = [
      { id: 1, title: "Coach" },
      { id: 2, title: "Diamond Partner" },
      { id: 3, title: "Client" },
      { id: 4, title: "Team Admin" },
      { id: 6, title: "Team Coach" },
    ];
  }

  // const handleDownload = () => {
  //  var imageFilename = 'abc';
  //   fetch(`${ApiUrl}` + "test", {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ` + uToken(),
  //     },
  //   })
  //   .then((response) => response.json())

  //   .then((response) => {
  //       const blob = response.blob();

  //       // Create a temporary URL for the blob
  //       const url = window.URL.createObjectURL(blob);

  //       // Create a temporary anchor element
  //       const link = document.createElement('a');
  //       link.href = url;
  //       link.download = imageFilename;

  //       // Append to the document, click it, and remove it
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);

  //       // Release the URL object
  //       window.URL.revokeObjectURL(url);

  //     })

  // };

  const getTrainingCertifications = () => {
    fetch(`${ApiUrl}` + "training/certification/fetch", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      // body: JSON.stringify({ id: uInfo().user_id, status: badgeStatus }),
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          // console.log('cert' , response.data.records);
          setCertifications(response.data.records);
        } else {
          setCertifications([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  // fetching the list of all published courses to show in the dropdown
  const getCoursesList = () => {
    fetch(`${ApiUrl}` + "get/waybook/courses", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())

      .then((response) => {
        if (response.status === true) {
          setCourses(response.data.record);

          // const steps = ["1", "2", "3"];
          // const Queue = Swal.mixin({
          //   progressSteps: steps,
          //   confirmButtonText: "Next >",
          //   showClass: { backdrop: "swal2-noanimation" },
          //   hideClass: { backdrop: "swal2-noanimation" },
          // });

          // (async () => {
          //   await Queue.fire({
          //     title: "Uno",
          //     currentProgressStep: 0,
          //   });
          //   await Queue.fire({
          //     title: "Dos",
          //     currentProgressStep: 1,
          //   });
          //   await Queue.fire({
          //     title: "Tres",
          //     currentProgressStep: 2,
          //     confirmButtonText: "OK",
          //   });
          // })();
        } else {
          setCourses([]);
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {});
  };

  // get the detial of selected course from the dropdown
  const getCourseDetail = (courseId) => {
    var formData;
    if (is_coach_section === "Yes") {
      formData = JSON.stringify({ can_edit: true, is_coach_section: "Yes" });
    } else {
      formData = JSON.stringify({ can_edit: true });
    }

    fetch(`${ApiUrl}` + "get/waybook/courses/detail/" + courseId, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCourseDetail(response.data.record);
          setSelectedCourseTag("");
        } else {
          setCourseDetail([]);
          setCourseTags([]);
          setSelectedCourseTag("");
        }
      })
      .catch((error) => {});
  };

  // fetch the list of training tags
  const fetchTrainingTagsList = () => {
    var formData;
    if (is_coach_section === "Yes") {
      formData = JSON.stringify({ get_all: true, login_user_type: "coach" });
    } else {
      formData = JSON.stringify({ get_all: true });
    }
    // fetch(`${ApiUrl}` + "get/trainingTagOnly", {
    fetch(`${ApiUrl}` + "get/trainings/list", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: formData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setTrainingTags(response.data.records);
          console.log("fetch tags list");
        } else {
          setTrainingTags([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    getCoursesList();
    fetchTrainingTagsList();

    if (userType == "WlAdmin") {
      getTrainingCertifications();
    }
  }, []);

  // function to be called on course selection
  const handleCourseSelect = (selectedOption) => {
    setSelectedCourse(selectedOption);
    getCourseDetail(selectedOption.value);
  };
  // course tag selection from dropdown
  const handleCourseTagSelect = (selectedOption) => {
    setSelectedCourseTag(selectedOption);
    // getCourseDetail(selectedOption.value);
    setFilterTriggered(false);
  };

  const courseOptions = courses.map((course) => ({
    value: course.cat_id,
    label: course.title,
  }));

  // setting a course as preselected by default in case of first relaod and after update
  useEffect(() => {
    if (selectedCourse.length == 0) {
      if (courseOptions.length > 0) {
        setSelectedCourse(courseOptions[0]);
        getCourseDetail(courseOptions[0].value);
      }
    } else {
      setSelectedCourse(
        courseOptions.filter((item) => item.value === selectedCourse.value)[0]
      );
      getCourseDetail(selectedCourse.value);
    }
  }, [courses]);

  useEffect(() => {
    if (courseDetail.length != 0) {
      setCourseTags(
        getTrainingTagsLevelWise(courseDetail.training_tags, "array")
      );
    }
  }, [courseDetail]);

  // get the titles of training tags from comma sperated of each level and map ahgainst tags array
  const getTrainingTagsLevelWise = (tagsString, level) => {
    const tagsArray = tagsString.split(",");
    const levelTags = tagsArray.map((tag_id) => {
      const match = trainingTags.find((tag) => tag.id == tag_id);
      return {
        tag_id,
        name: match ? match.name : null,
      };
    });

    if (level == "array") {
      const defaultOption = {
        value: "All",
        label: "All Tags",
      };

      const tagOptions = levelTags.map((item) => ({
        value: item.tag_id,
        label: item.name,
      }));

      const optionsWithDefault = [defaultOption, ...tagOptions];
      return tagsString === "" ? [defaultOption] : optionsWithDefault;
    }

    // const getBadgeClass = (level) => {
    //   switch (level) {
    //     case 1:
    //       return "bg-success";
    //     case 2:
    //       return "bg-warning";
    //     case 3:
    //       return "bg-info";
    //     case 4:
    //       return "bg-secondary";
    //     default:
    //       return "bg-secondary";
    //   }
    // };

    return levelTags.map((course, index) => (
      <span
        key={index}
        // className={`badge ${getBadgeClass(level)} ms-3 my-auto`}
        className={`badge custom-bg text-dark ms-1 my-auto fw-normal`}
      >
        {course.name}
      </span>
    ));
  };

  // filter tree hirerachy based on selected tag from dropdown
  const filterByTag = (tags) => {
    // if (!selectedCourseTag) return true;
    if (!selectedCourseTag || selectedCourseTag.value == "All") return true;
    return tags.includes(selectedCourseTag.value);
  };

  // handle the tags selection filter button click
  const handleFilter = () => {
    setFilterTriggered(true);
  };

  //  reset the tags selection filter reset button
  const handleReset = () => {
    setSelectedCourseTag(null);
    setFilterTriggered(false);
  };

  // get the titles of training for user types from comma sperated of each level and map ahgainst user types array
  const getTrainingForLevelWise = (trainingFor, level) => {
    const trainingForArray = trainingFor.split(",");
    const levelTrainingFor = trainingForArray.map((training_for) => {
      const match = user_types.find((type) => type.id == training_for);
      return {
        training_for,
        name: match ? match.title : null,
      };
    });

    const names = levelTrainingFor
      .map((training_for) => training_for.name)
      .filter(Boolean)
      .join(", ");

    return <p className="fw-normal fst-italic">{names} </p>;

    // return levelTrainingFor.map((training_for, index) => (
    //   <p className="fw-normal fst-italic" key={index}>
    //     {training_for.name}
    //   </p>
    // ));
  };

  const handleTrainingForCheckboxChange = (event, index) => {
    const { checked } = event.target;
    setTrainingForCheckedItems((prevState) => ({
      ...prevState,
      [index]: checked,
    }));
  };

  // generate dropdow to show actions for each course hirerachy level
  const generateDropdown = (category) => {
    const catType = category.section_type
      ? category.section_type === 1
        ? "Course"
        : category.section_type === 2
        ? "Module"
        : "Lesson"
      : "Training";

    return (
      <>
        <Dropdown>
          <Dropdown.Toggle variant="link">
            <span className="fw-bolder"> {category.title}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {category.status === "Publish" ? (
              <Dropdown.Item
                eventKey="Draft"
                onClick={(e) => {
                  e.preventDefault();
                  changePublishOrUnPublishStatus(
                    catType === "Training" ? category.step_id : category.cat_id,
                    "Draft",
                    catType
                  );
                }}
              >
                Switch to Unpublish
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                eventKey="Publish"
                onClick={(e) => {
                  e.preventDefault();
                  changePublishOrUnPublishStatus(
                    catType === "Training" ? category.step_id : category.cat_id,
                    "Publish",
                    catType
                  );
                }}
              >
                Switch to Publish
              </Dropdown.Item>
            )}
            <Dropdown.Item
              eventKey="Update"
              onClick={(e) => {
                e.preventDefault();
                handleLevelClick(category);
              }}
            >
              Edit
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  const draftStatusInfo = (category) => {
    return (
      <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Tooltip id="button-tooltip">{`This ${category} is unpublished`}</Tooltip>
        }
      >
        <i className="fas fa-info-circle warning-info"></i>
      </OverlayTrigger>
    );
  };

  // course sub level detail hirerachy of listing
  const generateListItems = () => {
    if (Object.keys(courseDetail).length == 0)
      return (
        <p className="text-center">
          Please select a course to view it's detail
        </p>
      );

    return (
      <ul className="tree Course">
        <li className="CourseName">
          <div className="CourseTag">
            <button className="btn btn-success">C</button>
          </div>
          <div className="d-flex flex-column">
            <div className="d-flex">
              {/* {generateDropdown(courseDetail)}
              {courseDetail.status !== "Publish" && draftStatusInfo("Course")} */}

              <span className="fw-bolder ">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    handleLevelClick(courseDetail);
                  }}
                >
                  {" "}
                  {courseDetail.title}
                </a>
              </span>
              {courseDetail.status !== "Publish" && (
                <small className="ms-1 text-danger status_text">
                  (<p className="">Unpublished</p>)
                </small>
              )}

              {courseDetail.training_tags !== "" && (
                <div className="d-flex justify-content-start">
                  {getTrainingTagsLevelWise(courseDetail.training_tags, 1)}
                </div>
              )}
            </div>
            <div className="d-flex justify-content-start">
              <small>
                ({getTrainingForLevelWise(courseDetail.training_for, 1)})
              </small>
            </div>
          </div>

          {/* <hr className="dotted" /> */}
          <ul className="MOduleSec">
            {courseDetail.sub_cat &&
              courseDetail.sub_cat
                .filter((module) => filterByTag(module.training_tags))
                .map((module) => (
                  <li className="ModuleTitle" key={module.cat_id}>
                    <div className="CourseTag ModuleTag">
                      <button className="btn btn-info">M</button>
                    </div>
                    <div className="d-flex mt-3 ms-2 mb-2 flex-column">
                      <div className="d-flex">
                        {/* {generateDropdown(module)}
                        {module.status !== "Publish" && draftStatusInfo("Module")} */}
                        <span className="fw-bold">
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleLevelClick(module);
                            }}
                          >
                            {" "}
                            {module.title}
                          </a>
                        </span>
                        {module.status !== "Publish" && (
                          <small className="ms-1 text-danger status_text">
                            (<p className="">Unpublished</p>)
                          </small>
                        )}
                        {module.training_tags !== "" && (
                          <div className="d-flex justify-content-center">
                            {getTrainingTagsLevelWise(module.training_tags, 2)}
                          </div>
                        )}
                      </div>
                      <div className="d-flex justify-content-left ms-2">
                        <small>
                          ({getTrainingForLevelWise(module.training_for, 1)})
                        </small>
                      </div>
                    </div>

                    <ul className="LessonTitle">
                      {module.sub_cat &&
                        module.sub_cat
                          .filter((lesson) => filterByTag(lesson.training_tags))
                          .map((lesson) => (
                            <li className="Tilts" key={lesson.cat_id}>
                              <div className="CourseTag ModuleTag LessonTag">
                                <button className="btn btn-warning">L</button>
                              </div>
                              <div className="d-flex my-3 flex-column">
                                <div className="d-flex">
                                  {/* {generateDropdown(lesson)}
                                  {lesson.status !== "Publish" &&
                                    draftStatusInfo("Lesson")} */}
                                  <span className="fw-bold">
                                    <a
                                      href="#"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleLevelClick(lesson);
                                      }}
                                    >
                                      {lesson.title}
                                    </a>
                                  </span>
                                  {lesson.status !== "Publish" && (
                                    <small className="ms-1 text-danger status_text">
                                      (<p className="">Unpublished</p>)
                                    </small>
                                  )}
                                  {lesson.training_tags !== "" && (
                                    <div className="d-flex justify-content-center">
                                      {getTrainingTagsLevelWise(
                                        lesson.training_tags,
                                        3
                                      )}
                                    </div>
                                  )}
                                </div>
                                <div className="d-flex justify-content-left ms-2">
                                  <small>
                                    (
                                    {getTrainingForLevelWise(
                                      lesson.training_for,
                                      1
                                    )}
                                    )
                                  </small>
                                </div>
                              </div>

                              <ul className="TraningTitle">
                                {lesson.sub_steps &&
                                  lesson.sub_steps
                                    .filter((training) =>
                                      filterByTag(training.training_tags)
                                    )
                                    .map((training) => (
                                      <li
                                        className="TitleTrinng"
                                        key={training.step_id}
                                      >
                                        <div className="CourseTag ModuleTag LessonTag QuizTag">
                                          <i className="fas fa-file"></i>
                                        </div>
                                        <div className="d-flex my-3 flex-column">
                                          <div className="d-flex">
                                            {/* {generateDropdown(training)}
                                            {training.status !== "Publish" &&
                                              draftStatusInfo("Training")} */}
                                            <span className="fw-bold ">
                                              {" "}
                                              <a
                                                href="#"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  handleLevelClick(training);
                                                }}
                                              >
                                                {training.title}{" "}
                                              </a>
                                            </span>
                                            {training.status !== "Publish" && (
                                              <small className="ms-1 text-danger status_text">
                                                (<p className="">Unpublished</p>
                                                )
                                              </small>
                                            )}
                                            {training.training_tags !== "" && (
                                              <div className="d-flex justify-content-center">
                                                {getTrainingTagsLevelWise(
                                                  training.training_tags,
                                                  4
                                                )}
                                              </div>
                                            )}
                                          </div>
                                          <div className="d-flex justify-content-left">
                                            <small>
                                              (
                                              {getTrainingForLevelWise(
                                                training.training_for,
                                                1
                                              )}
                                              )
                                            </small>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                              </ul>
                            </li>
                          ))}
                    </ul>
                    {/* <hr className="dotted" /> */}
                  </li>
                ))}
          </ul>
        </li>
      </ul>
    );
  };

  const listItems = generateListItems();

  // UPDATE RELATED FUNCTIONALITY
  const getTagLists = async (section, record_id) => {
    // fetch(`${ApiUrl}` + "get/user/trainingtags", {
    fetch(`${ApiUrl}` + "get/section/trainingtags", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        can_edit: "yes",
        user_id: uInfo().user_id,
        section: section,
        record_id: record_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          settrainingFors(response.data.training_fors);
          setListTags(response.data.records);
          setNewTrainingTags([]);
          setNewTrainingFor([]);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // setting level and level tags based on course hirerachy category
  const setSelectedCategoryLevel = (category) => {
    switch (category.section_type) {
      case 1:
        setLevel("Course");
        setType("Category");
        getTagLists("category", 0);
        break;
      case 2:
        setLevel("Module");
        setType("Subject");
        getTagLists("subject", category.master_id);
        break;
      case 3:
        setLevel("Lesson");
        setType("Document");
        getTagLists("document", category.master_id);
        break;
      default:
        setLevel("Training");
        setType("Step");
        getTagLists("step", category.cat_id);
    }
  };

  // pass level information to a function/component and fill the fields and set
  const handleLevelClick = (clickedLevel) => {
    setEditModal(true);
    setLevelInformation(clickedLevel);

    setSelectedCategoryLevel(clickedLevel);

    // switch (clickedLevel.section_type) {
    //   case 1:
    //     setLevel("Course");
    //     setType("Category");
    //     getTagLists("category", 0);
    //     break;
    //   case 2:
    //     setLevel("Module");
    //     setType("Subject");
    //     getTagLists("subject", clickedLevel.master_id);
    //     break;
    //   case 3:
    //     setLevel("Lesson");
    //     setType("Document");
    //     getTagLists("document", clickedLevel.master_id);
    //     break;
    //   default:
    //     setLevel("Training");
    //     setType("Step");
    //     getTagLists("step", clickedLevel.cat_id);
    // }
  };

  // modal close functionality
  const handleModalClose = () => {
    setEditModal(false);
    setLevelInformation([]);
    setLevel("");
    setType("");
    setListTags([]);
  };

  const afterUpdate = () => {
    getCoursesList();
    getTrainingCertifications();
  };

  // form submit from update modal
  const handleUpdateLevelInformationSubmit = async (event) => {
    var updateURL;
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const submitData = new FormData();
    for (let [key, value] of formData.entries()) {
      submitData.append(key, value);
    }

    // const currentUrl = window.location.href;
    submitData.append("is_coach_section", is_coach_section);
    // console.log("Form Data:");
    // for (let [key, value] of submitData.entries()) {
    //   console.log(`${key}: ${value}`);
    // }

    if (level == "Training") {
      updateURL = `${ApiUrl}` + "update/waybook/category/step";
    } else {
      updateURL = `${ApiUrl}` + "submit/waybook/category";
    }

    console.log(updateURL);
    fetch(`${updateURL}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ` + uToken(),
      },
      body: submitData,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          handleModalClose();
          afterUpdate();
        } else {
        }
      })
      .catch((error) => {});
  };

  // switching to publish or unpublish the course hirerachy level
  const changePublishOrUnPublishStatus = (cat_id, action, type) => {
    var updateURL;
    var formBody;

    if (type == "Training") {
      updateURL = `${ApiUrl}` + "status/update/waybook/category/step";
      formBody = JSON.stringify({
        step_id: cat_id,
        status: action,
        is_coach_section: is_coach_section,
      });
    } else {
      updateURL = `${ApiUrl}` + "status/update/waybook/category";
      formBody = JSON.stringify({ cat_id: cat_id, status: action });
    }
    console.log(updateURL);
    console.log(formBody);
    fetch(`${updateURL}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: formBody,
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log(response);
          handleModalClose();
          afterUpdate();
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const changeHandler = (e) => {
    if (!e.target.files[0].type.match("image.*")) {
      swal({
        title: "Please upload a valid image file",
        icon: "warning",
      });
    }
  };

  // const handleTrainingForChange_old = (e) => {

  //   console.log("subAction.training_for", subAction.training_for);

  //   const trainingForArray =
  //     typeof levelInformation.training_for === "string"
  //       ? levelInformation.training_for.split(",").map((item) => item.trim())
  //       : [];
  //   let status = e.some((obj) => !trainingForArray.includes(obj.id));
  //   if (status) {
  //     var res = e.map((s) => s.id);
  //     setLevelInformation((prevState) => ({
  //       ...prevState,
  //       training_for: res.toString(),
  //     }));

  //     return false;
  //   }
  //   swal({
  //     title: "After the remove and update action",
  //     text: "The sublevel of this section will also be hidden for the selected training-for option. Are you sure you want to proceed?",
  //     icon: "warning",
  //     buttons: {
  //       cancel: {
  //         text: "No",
  //         value: false,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //       confirm: {
  //         text: "Yes",
  //         value: true,
  //         visible: true,
  //         className: "",
  //         closeModal: true,
  //       },
  //     },
  //     dangerMode: true,
  //   }).then((confirmation) => {
  //     if (confirmation) {
  //       var res = e.map((s) => s.id);
  //       setLevelInformation((prevState) => ({
  //         ...prevState,
  //         training_for: res.toString(),
  //       }));
  //     } else {
  //       return false;
  //     }
  //   });
  // };

  const handleTrainingForChange = (selectedOptions) => {
    console.log("subAction.training_for", levelInformation.training_for);

    const selectedIds = selectedOptions.map((option) => option.id);
    const isStatusRequired =
      levelInformation.training_for &&
      Array.isArray(levelInformation.training_for.split(",").map(Number)) &&
      levelInformation.training_for
        .split(",")
        .map(Number)
        .some((id) => selectedIds && !selectedIds.includes(id));
    const newTrainingFors = selectedOptions.filter(
      (user_types) =>
        Array.isArray(levelInformation.training_for.split(",").map(Number)) &&
        !levelInformation.training_for
          .split(",")
          .map(Number)
          .includes(user_types.id)
    );

    if (isStatusRequired) {
      // Only show SweetAlert if required
      swal({
        title: "After the remove and update action",
        text: "The sublevel of this section will also be hidden for the selected training-for option. Are you sure you want to proceed?",
        icon: "warning",
        buttons: {
          cancel: {
            text: "No",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Yes",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      }).then((confirmation) => {
        if (confirmation) {
          setNewTrainingFor((prevTags) => {
            const filteredExistingTrainingFor = prevTags.filter((user_types) =>
              selectedIds.includes(user_types.id)
            );
            const combinedTags = [
              ...filteredExistingTrainingFor,
              ...newTrainingFors,
            ];
            return combinedTags.filter((user_types) =>
              selectedIds.includes(user_types.id)
            );
          });

          setLevelInformation((prevState) => ({
            ...prevState,
            training_for: selectedIds.toString(),
          }));
        }
      });
    } else {
      setNewTrainingFor((prevTags) => {
        const filteredExistingTrainingFor = prevTags.filter((user_types) =>
          selectedIds.includes(user_types.id)
        );
        const combinedTags = [
          ...filteredExistingTrainingFor,
          ...newTrainingFors,
        ];
        return combinedTags.filter((user_types) =>
          selectedIds.includes(user_types.id)
        );
      });
      setLevelInformation((prevState) => ({
        ...prevState,
        training_for: selectedIds.toString(),
      }));
    }
  };

  const handleCheckboxChange = (event, index) => {
    const { checked } = event.target;
    setCheckedItems((prevState) => ({
      ...prevState,
      [index]: checked,
    }));
  };

  const handleTagChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    const isStatusRequired =
      levelInformation.tag_training &&
      Array.isArray(levelInformation.tag_training) &&
      levelInformation.tag_training.some(
        (id) => selectedIds && !selectedIds.includes(id)
      );
    const newTrainingTags = selectedOptions.filter(
      (tag) =>
        Array.isArray(levelInformation.tag_training) &&
        !levelInformation.tag_training.includes(tag.id)
    );

    if (isStatusRequired) {
      swal({
        title: "After the remove and update action",
        text: "The sublevel of this section will also be hidden for the selected training option. Are you sure you want to proceed?",
        icon: "warning",
        buttons: {
          cancel: {
            text: "No",
            value: false,
            visible: true,
            className: "",
            closeModal: true,
          },
          confirm: {
            text: "Yes",
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        dangerMode: true,
      }).then((confirmation) => {
        if (confirmation) {
          setNewTrainingTags((prevTags) => {
            const filteredExistingTags = prevTags.filter((tag) =>
              selectedIds.includes(tag.id)
            );
            const combinedTags = [...filteredExistingTags, ...newTrainingTags];
            return combinedTags.filter((tag) => selectedIds.includes(tag.id));
          });

          setLevelInformation((prevState) => ({
            ...prevState,
            tag_training: selectedIds,
          }));
        }
      });
    } else {
      setNewTrainingTags((prevTags) => {
        const filteredExistingTags = prevTags.filter((tag) =>
          selectedIds.includes(tag.id)
        );
        const combinedTags = [...filteredExistingTags, ...newTrainingTags];
        return combinedTags.filter((tag) => selectedIds.includes(tag.id));
      });
      setLevelInformation((prevState) => ({
        ...prevState,
        tag_training: selectedIds,
      }));
    }
  };

  const handleTrainingTagChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    setLevelInformation((prevState) => ({
      ...prevState,
      tag_training: selectedIds,
    }));
  };

  const customNoOptionsMessage = (record) => {
    setInputValue(record.inputValue);
    const matchingOptions = list_tags.filter((tag) =>
      tag.name.toLowerCase().includes(record.inputValue.toLowerCase())
    );
    setNoOptions(record.inputValue && matchingOptions.length === 0);
  };
  // UPDATE RELATED FUNCTIONALITY

  const handleCertificationChange = (selectedOptions) => {
    const selectedIds = selectedOptions.map((option) => option.id);
    setLevelInformation((prevState) => ({
      ...prevState,
      training_certifications: selectedIds,
    }));
  };

  return (
    <>
      <Helmet>
        <title>{pageName}</title>
      </Helmet>

      <Row>
        <Col sm={6}>
          <div className="page-title-box">
            <h4>{pageName}</h4>
            <ol className="breadcrumb m-0">
              <li key={0} className="breadcrumb-item active">
                {ProductName}
              </li>
              <li key={1} className="breadcrumb-item">
                <Link to="#">{pageName}</Link>
              </li>
            </ol>
          </div>
        </Col>
        <Col sm={6}>
          <div className="page-title-box text-align-right">
            <Button
              className="btn-sm"
              type="button"
              color="secondary"
              onClick={() => routeHistory.goBack()}
            >
              Back
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="4">
          <div className="ms-3 mb-3">
            <div className="CourseSelect">
              <Select
                options={courseOptions}
                onChange={handleCourseSelect}
                value={selectedCourse}
                placeholder="Choose a Course"
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
          </div>
        </Col>
        <Col lg="4">
          <div className="ms-3 mb-3">
            <div className="CourseSelect">
              <Select
                options={courseTags}
                onChange={handleCourseTagSelect}
                value={selectedCourseTag}
                placeholder="Choose a Tag"
                className="basic-single"
                classNamePrefix="select"
              />
            </div>
          </div>
        </Col>
        <Col md="2" className="d-none">
          <div className="button-items">
            <Button
              className="btn-sm d-none"
              color="primary"
              onClick={() => handleFilter()}
              type="submit"
            >
              Filter
            </Button>
            <Button
              // onClick={() => this.cancelFilter()}
              onClick={() => handleReset()}
              outline
              color="secondary"
              className="waves-effect btn-sm"
              type="button"
            >
              Reset
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col lg="12">
          <Card className="TagsLeson">
            <CardBody className="pt-0">
              <div className="p-4 courses_list">{listItems}</div>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={editModal}
        role="dialog"
        centered={true}
        className="exampleModal modal-md UpdateInformationModal --"
        tabIndex="-1"
        toggle={() => {
          setEditModal(false);
        }}
      >
        <div className="modal-content">
          <ModalHeader className="text-transform-capitalize p-2 UpdateInformationUser">
            <div>Update {level}</div>
            <button
              onClick={() => handleModalClose()}
              className="btn btn-link pull-right BtnCenterClose me-end px-2"
            >
              <i className="fas fa-times"></i>
            </button>
          </ModalHeader>

          <ModalBody>
            <div className="form-action">
              <form
                method="post"
                onSubmit={handleUpdateLevelInformationSubmit}
                encType="multipart/form-data"
                className="form-horizontal"
                id="selection-myform"
              >
                <div className="form-group mb-3">
                  <label
                    htmlFor="example-text-input"
                    className="form-label mb-0"
                  >
                    Name
                  </label>
                  <input
                    defaultValue={levelInformation.title}
                    placeholder={`The name of this ${level}`}
                    required
                    className="form-control"
                    type={"text"}
                    name="title"
                  />

                  <input
                    type={"hidden"}
                    name="id"
                    value={levelInformation.cat_id}
                  />
                  {level == "Training" ? (
                    <>
                      <input type={"hidden"} name="action" value="Update" />
                      <input
                        type={"hidden"}
                        name="step_id"
                        value={levelInformation.step_id}
                      />
                    </>
                  ) : (
                    <>
                      <input type={"hidden"} name="master_type" value={type} />
                      <input type={"hidden"} name="type" value={1} />
                    </>
                  )}
                </div>

                {level == "Module" && (
                  <>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="form-label mb-0"
                      >
                        Module Image
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          accept="image/*"
                          className="form-control UploadImage me-2"
                          type={"file"}
                          onChange={(e) => changeHandler(e)}
                          name="image"
                        />

                        {levelInformation.image && (
                          <img
                            width={40}
                            src={`${AttachementsUrl}/user_${levelInformation.user_id}/training/${levelInformation.image}`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>

                    <div className="form-group mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="form-label mb-0"
                      >
                        Badge Image
                      </label>
                      <div className="d-flex align-items-center">
                        <input
                          accept="image/*"
                          className="form-control UploadImage me-2"
                          type={"file"}
                          onChange={(e) => changeHandler(e)}
                          name="badge_image"
                        />
                        {levelInformation.badge_image && (
                          <img
                            width={40}
                            src={`${AttachementsUrl}/user_${levelInformation.user_id}/training/${levelInformation.badge_image}`}
                            alt=""
                          />
                        )}
                      </div>
                    </div>
                    <div className="form-group mb-3">
                      <label
                        htmlFor="badge_description"
                        className="form-label mb-0"
                      >
                        Module Objective
                      </label>
                      <textarea
                        defaultValue={levelInformation.badge_description}
                        placeholder="Enter description"
                        className="form-control"
                        name="badge_description"
                        rows="3"
                      ></textarea>
                    </div>

                    <div className="form-group mb-3">
                      <label
                        htmlFor="example-text-input"
                        className="form-label mb-0"
                      >
                        Coaching Points (CP){" "}
                      </label>
                      <input
                        placeholder="Enter coaching points"
                        required
                        className="form-control"
                        type={"number"}
                        name="coaching_points"
                        defaultValue={levelInformation.coaching_points}
                      />
                    </div>
                  </>
                )}

                <div className="form-group mb-3">
                  <label
                    htmlFor="training_for-text-label"
                    className="form-label mb-0"
                  >
                    Training for
                  </label>
                  <div className="LowIndexothers UpdateStep">
                    <Select
                      getOptionLabel={(option) => option.title}
                      getOptionValue={(option) => option.id}
                      isMulti={true}
                      // value={user_types.filter(
                      //   (obj) =>
                      //     levelInformation.training_for &&
                      //     levelInformation.training_for.includes(obj.id)
                      // )}
                      // options={user_types}

                      value={user_types.filter(
                        (obj) =>
                          levelInformation.training_for &&
                          levelInformation.training_for
                            .split(",")
                            .map(Number)
                            .includes(obj.id)
                      )}
                      options={user_types.filter((obj) =>
                        training_fors.map(Number).includes(obj.id)
                      )}
                      onChange={
                        level == "Training"
                          ? (e) => {
                              const res = e.map((s) => s.id);
                              setLevelInformation((prevState) => ({
                                ...prevState,
                                training_for: res.toString(),
                              }));
                            }
                          : handleTrainingForChange
                      }
                      classNamePrefix="select2-selection position-releative"
                      name="training_for[]"
                    />
                  </div>

                  {newTrainingFor && newTrainingFor.length > 0 && (
                    <div className="col-md-12 mt-3">
                      <label
                        htmlFor="training_for-text-label"
                        className="form-label"
                      >
                        Apply selected training-for at the sub-level
                      </label>
                      {newTrainingFor.map((row) => (
                        <div className="col-md-3 mb-3 mt-3" key={row.id}>
                          <div className="form-check">
                            <input
                              name={`sub_level_training_for[]`}
                              type="checkbox"
                              className="form-check-input"
                              checked={checkedTrainingForItems[row.id] || false}
                              onChange={(e) =>
                                handleTrainingForCheckboxChange(e, row.id)
                              }
                              value={row.id}
                            />
                            <label
                              htmlFor={`sub-level-training-for-${row.id}`}
                              className="form-check-label"
                            >
                              {row.title}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>

                <div className="form-group mb-3">
                  <label
                    htmlFor="training_tag-text-label"
                    className="form-label mb-0"
                  >
                    Who are tagged as
                  </label>
                  <div className="position-relative">
                    <div
                      className="CommonSelectTwo ComMain TraningSubject TraningUpdate tags-selection-div"
                      style={{ minHeight: "38px", height: "auto" }}
                    >
                      <Select
                        getOptionLabel={(option) => option.name}
                        getOptionValue={(option) => option.id}
                        isMulti={true}
                        value={
                          list_tags && list_tags.length > 0
                            ? list_tags.filter(
                                (obj) =>
                                  levelInformation.tag_training &&
                                  levelInformation.tag_training.includes(obj.id)
                              )
                            : []
                        }
                        options={
                          list_tags && list_tags.length > 0 ? list_tags : []
                        }
                        onChange={
                          level == "Training"
                            ? handleTrainingTagChange
                            : handleTagChange
                        }
                        classNamePrefix="select2-selection position-relative"
                        name="tags[]"
                        // noOptionsMessage={customNoOptionsMessage}
                        isDisabled={!list_tags || list_tags.length === 0}
                      />
                      {/* {noOptions && levelInformation.type === 'Category' && (
                            <button className={`btn btn-sm btn-success ${renderTrainingTag(action)}`}
                                onClick={handleCreateTag}>
                                <i className="fas fa-plus"></i> Training Tag
                            </button>
                            )} */}
                    </div>
                  </div>
                  {level != "Training" &&
                    new_training_tags &&
                    new_training_tags.length > 0 && (
                      <div className="col-md-12 mt-3">
                        <label
                          htmlFor="training_tags-text-label"
                          className="form-label mb-0"
                        >
                          Apply selected tags at the sub-level
                        </label>
                        <div className="row">
                          {new_training_tags.map((row) => (
                            <div className="col-md-4 mb-1 mt-2" key={row.id}>
                              <div className="form-check">
                                <input
                                  name={`sub_level_tags[]`}
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={checkedItems[row.id] || false}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, row.id)
                                  }
                                  value={row.id}
                                />
                                <label
                                  htmlFor={`sub-level-tags-${row.id}`}
                                  className="form-check-label mb-0"
                                >
                                  {row.name}
                                </label>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                </div>
                {/* {level == "Module" && userType == "WlAdmin" && (
                  <div className="form-group mb-3">
                    <label className="form-label">Training Certification</label>
                    <div className="">
                      <Select
                        getOptionLabel={(option) => option.title}
                        getOptionValue={(option) => option.id}
                        isMulti={true}
                        value={certifications.filter(
                          (obj) =>
                            levelInformation.training_certifications &&
                            levelInformation.training_certifications.includes(
                              obj.id
                            )
                        )}
                        options={certifications}
                        onChange={handleCertificationChange}
                        classNamePrefix="select2-selection position-releative"
                        name="training_certifications[]"
                      />
                    </div>
                  </div>
                )} */}

                <div className="d-flex justify-content-end">
                  <button
                    onClick={() => handleModalClose()}
                    type="button"
                    className="bwaves-effect waves-light btn btn-outline-dark  btn-sm"
                  >
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary ms-2 btn-sm">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </ModalBody>

          {/* <ModalFooter className="modal-footer d-inline-block"></ModalFooter> */}
        </div>
      </Modal>
    </>
  );
}
