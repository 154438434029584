import { NylasSchedulerEditor } from "@nylas/react";

function SchedulerEditor() {
  return (
    <div className="d-flex justify-content-center NylasEditor">
      <NylasSchedulerEditor
        schedulerPreviewLink={`${window.location.origin}/?config_id={config.id}`}
        nylasSessionsConfig={{
          clientId: "2f5b0bbe-2299-4c35-87c9-f6cbd54afbd4", 
          redirectUri: `${window.location.origin}/scheduler-editor`,
          domain: "https://api.us.nylas.com/v3",
          hosted: true,
          accessType: "offline",
        }}
        defaultSchedulerConfigState={{
          selectedConfiguration: {
            requires_session_auth: false, 
            // scheduler: {
            //   rescheduling_url: `${window.location.origin}/reschedule/:booking_ref`, 
            //   cancellation_url: `${window.location.origin}/cancel/:booking_ref`,
            // },
          },
        }}
      />
    </div>
  );
}

export default SchedulerEditor;
