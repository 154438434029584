import React, { Component, Fragment } from "react";
import Loader from "react-loader";
import meetGoogle from "../../assets/images/meet.png";
import {
  Row,
  Col,
  Card,
  Button,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import SweetAlert from "react-bootstrap-sweetalert";

import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";
import { dcrypt, uToken, uInfo } from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose, WebUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";
import Select from "react-select";
import moment from "moment";
import swal from "sweetalert";

export default class MettingCreate extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      is_sync: query.get("param") ? "Yes" : "No",
      page_name: this.props.match.params.id ? "Edit Event" : "Create Event",
      from: this.props.from ? this.props.from : "",
      loaded: true,
      records: [],
      selected_calendar_id: "",
      record: {
        meeting_type: "one-on-one",
        internal_name: "",
        event_title: "",
        location: "",
        location_type: "None",
        description: "",
        permission: "",
        group_id: "",
        duration: "15",
        event_participants: "",
        nylas_calendar_id: "",
      },
      meeting_participants: "",
      scheduling: {
        title: "Sample Calendar",
        WorkingDays: [
          {
            key: "1",
            day: "Mon",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "2",
            day: "Tue",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "3",
            day: "Wed",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "4",
            day: "Thu",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "5",
            day: "Fri",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "6",
            day: "Sat",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "off",
          },
          {
            key: "7",
            day: "Sun",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "off",
          },
        ],
      },
      scheduling_forms: [
        // {
        //   key: "first_name",
        //   label: "First Name",
        //   alternative_text: "",
        //   required: true,
        // },
        {
          key: "Name",
          label: "name",
          alternative_text: "",
          required: true,
        },
        {
          key: "email",
          label: "Email",
          alternative_text: "",
          required: true,
        },
        {
          key: "phone_number",
          label: "Phone Number",
          alternative_text: "",
          required: true,
        },
      ],

      automation: {
        confirmation_email: false,
        confirmation_sms: false,
        reminder: false,
        reminders: [
          {
            interval: "1",
            type: "day_before",
          },
        ],
      },
      groups: [],
      permissions: [],
      tag_id: [],
      tab: "1",
      tokens: [],
      warningAlert: false,
      loading: true,
    };

    this.addField = this.addField.bind(this);
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.getFormData();
    // this.state.id != null && this.state.is_sync==="Yes"  && this.getSycnCalendarData();
  }

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/meeting/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          this.setState({
            groups: response.data.groups,
            permissions: response.data.coachPartners,
            // tokens: response.data.tokens.default_fields,
            tokens: response.data.tokens.default_fields.filter(
              (field) =>
                field.key !== "first_name" &&
                field.key !== "last_name" &&
                field.key !== "phone_number"
            ),
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/meeting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("response", response);
        if (response.status === true) {
          this.setState({
            record: response.data.record,
            selectedPermission: response.data.record.permissions,
          });

          if (response.data.schedule_settings) {
            this.setState({
              scheduling: response.data.schedule_settings,
            });
          }

          if (response.data.record.nylas_calendar_id) {
            this.setState({
              selected_calendar_id: response.data.record.nylas_calendar_id,
            });
          }

          if (response.data.schedule_form) {
            this.setState({
              scheduling_forms: response.data.schedule_form,
            });
          }

          if (response.data.automation_settings) {
            this.setState({
              automation: response.data.automation_settings,
            });
          }

          if (response.data.meeting_participants) {
            this.setState({
              meeting_participants: response.data.meeting_participants,
            });
          }

          this.setState((prevState) => {
            let record = Object.assign({}, prevState.record);
            record.permission = response.data.record.permission;
            return { record };
          });

          this.setState({
            loading: false,
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleSubmit = async (event, errors, values) => {
    values.id = this.state.id;

    if (this.state.tab == "1") {
      if (values.event_title == "") {
        swal({
          title: "Please add your event title",
          icon: "warning",
        });
        return false;
      }
      if (values.group_id == "") {
        swal({
          title: "Please select group",
          icon: "warning",
        });
        return false;
      }
      this.toggle("2");
      return;
    } else if (this.state.tab == "2") {
      this.toggle("3");
      return;
    } else if (this.state.tab == "3" && errors.length < 1) {
      try {
        this.setState({ loaded: false });

        values.schedule_settings = JSON.stringify(this.state.scheduling);
        values.schedule_form = JSON.stringify(this.state.scheduling_forms);
        values.automation_settings = JSON.stringify(this.state.automation);
        values.location_type = this.state.record.location_type;
        values.duration = this.state.record.duration;

        const response = await fetch(`${ApiUrl}add/meeting`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${uToken()}`,
          },
          body: JSON.stringify(values),
        });

        const data = await response.json();
        console.log(data, "Editing");

        this.setState({ loaded: true });

        if (data.status === true) {
          if (this.state.id == null) {
            this.form && this.form.reset();
          }

          this.state.id != null && this.getSycnCalendarData();
          this.toggle("4");

          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          // setTimeout(() => this.props.history.push('/meeting/list'), 1000);
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
        // this.setState({ loaded: true }); // Ensure UI updates on error
      }
    } else {
      console.log("not found");
      this.setState({ warningAlert: true });
    }
  };

  // handleSubmit = async (event, errors, values) => {
  //   values.id = this.state.id;

  //   if (this.state.tab == "1") {
  //     if(values.event_title == "") {
  //       swal({
  //         title: "Please add your event title",
  //         icon: "warning",
  //       });
  //       return false;
  //     }
  //     if(values.group_id == "") {
  //       swal({
  //         title: "Please select group",
  //         icon: "warning",
  //       });
  //       return false;
  //     }
  //     this.toggle("2");
  //     return;
  //   }else if (this.state.tab == "2") {
  //     this.toggle("3");
  //     return;
  //   } else if (this.state.tab == "3" && errors.length < 1) {
  //     this.setState({ loaded: false });

  //     values.schedule_settings = JSON.stringify(this.state.scheduling);
  //     values.schedule_form = JSON.stringify(this.state.scheduling_forms);
  //     values.automation_settings = JSON.stringify(this.state.automation);
  //     values.location_type = this.state.record.location_type;
  //     values.duration = this.state.record.duration;
  //     fetch(`${ApiUrl}` + "add/meeting", {
  //       method: "POST",
  //       headers: {
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ` + uToken(),
  //       },
  //       body: JSON.stringify(values),
  //     })
  //       .then((response) => response.json())
  //       .then((data) => {
  //         console.log(data, 'Editing');
  //         if (data.status === true) {
  //           if (this.state.id == null) {
  //             this.form && this.form.reset();
  //           }
  //           //toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
  //           this.props.history.push('/settings/calendar');

  //           // setTimeout(
  //           //   () => this.props.history.push('/meeting/list'),
  //           //   1000
  //           // );

  //         } else {
  //           toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
  //         }
  //       })
  //       //Then with the error genereted...
  //       .catch((error) => {
  //         console.error("Error:", error);
  //       });
  //   } else {

  //     console.log('not fount');
  //     this.setState({
  //       warningAlert: true
  //     })

  //   }
  // };

  handleGroup = (selectedGroup) => {
    this.setState((prevState) => {
      let record = Object.assign({}, prevState.record);
      record.group_id = selectedGroup.group_id;
      return { record };
    });
  };

  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState((prevState) => {
      let record = Object.assign({}, prevState.record);
      record.permission = filter.toString();
      return { record };
    });

    this.setState({
      selectedPermission: selectedPermission,
    });
  };

  toggle = (index) => {
    if (this.state.tab !== index) {
      this.setState({
        tab: index,
      });
    }
  };

  StartHours = () => {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format("h:mm A"));
      items.push(moment({ hour: index, minute: 30 }).format("h:mm A"));
    });
    return items;
  };

  addField = (data) => {
    this.setState((prevState) => ({
      scheduling_forms: [
        ...prevState.scheduling_forms,
        {
          [`label`]: data.label,
          key: data.key,
          [`alternative_text`]: data.alternative_text,
          required: false,
        },
      ],
    }));
    this.setState({
      addMore: false,
    });
  };

  removeReminder = (index) => {
    let automation = this.state.automation;
    automation.reminders.splice(index, 1);
    this.setState(automation);
  };

  removeOptionClick(k, e) {
    let scheduling_forms = [...this.state.scheduling_forms];
    scheduling_forms.splice(k, 1);
    this.setState({ scheduling_forms });
  }

  handleSelectDurationChange = (e) => {
    console.log("Selected duration :", e.target.value);
    this.setState({
      record: {
        ...this.state.record,
        duration: e.target.value,
      },
    });
  };

  handleSelectChange = (e) => {
    console.log("Selected location type:", e.target.value);
    this.setState({
      record: {
        ...this.state.record,
        location_type: e.target.value,
      },
    });
  };
  // if (this.state.selected_calendar_id === null) {
  //     this.props.history.push("/settings/calendar");
  //     return;
  // }
  getSycnCalendarData = () => {
    this.setState({ loaded: false });

    fetch(`${ApiUrl}get/google/calander`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: this.state.id }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            records: response.data.calArr,
            loaded: true,
          });
        } else {
          this.setState({ loaded: true, records: [] });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loaded: true });
      });
  };

  submitGoogleCalanderId = () => {
    if (this.state.selected_calendar_id === "") {
      swal({
        title: "Please choose a calendar",
        icon: "warning",
      });
      return false;
    }
    const {
      selected_calendar_summary,
      selected_calendar_name,
      selected_calendar_id,
    } = this.state;

    fetch(`${ApiUrl}update/google/calander`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        id: this.state.id,
        google_id: selected_calendar_id || "",
        nylas_calendar_name:
          selected_calendar_summary || selected_calendar_name || null,
        calendar_type: "Nylas",
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.props.history.push("/settings/calendar");
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.props.history.push("/settings/calendar");
        toast("An error occurred. Please try again later.", {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
      });
  };

  //   submitGoogleCalanderId = () => {

  //     const {
  //         selected_calendar_summary,
  //         selected_calendar_name,
  //         selected_calendar_id,
  //     } = this.state;

  //     const isDefaultCalendar = selected_calendar_id === null;

  //     fetch(`${ApiUrl}` + "update/google/calander", {
  //         method: "POST",
  //         headers: {
  //             Accept: "application/json",
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ` + uToken(),
  //         },
  //         body: JSON.stringify({
  //             id: this.state.id,
  //             google_id: isDefaultCalendar ? null : selected_calendar_id,
  //             nylas_calendar_name: selected_calendar_summary || selected_calendar_name || null,
  //             calendar_type: isDefaultCalendar ? "None" : "Nylas",
  //         }),
  //     })
  //     .then((response) => response.json())
  //     .then((response) => {
  //         if (response.status === true) {
  //             this.props.history.push("/settings/calendar");
  //         } else {
  //             toast(response.message, {
  //                 hideProgressBar: true,
  //                 position: "top-center",
  //                 transition: Flip,
  //                 className: "toast-custom-cls error",
  //             });
  //         }
  //     })
  //     .catch((error) => {
  //         console.error("Error:", error);
  //         this.props.history.push("/settings/calendar");
  //         toast("An error occurred. Please try again later.", {
  //             hideProgressBar: true,
  //             position: "top-center",
  //             transition: Flip,
  //             className: "toast-custom-cls error",
  //         });
  //     });
  // };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                onClick={() => this.props.history.goBack()}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        {this.state.warningAlert ? (
          <SweetAlert
            title="Please fill all fields"
            warning
            onConfirm={() => {
              this.setState({
                warningAlert: false,
              });
            }}
          />
        ) : null}

        {!this.state.loading && (
          <Row>
            <Col className="col-lg-8 offset-lg-2">
              <Card className="CustomShadow NlyasTabs">
                <Row className="h-100">
                  <div className="col-lg-3 pe-0">
                    <Nav tabs className="d-flex flex-column pt-0">
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tab == 1 ? "active me-0" : "me-0"
                          }
                          onClick={() => {
                            this.toggle("1");
                          }}
                        >
                          Overview
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tab == 2 ? "active me-0" : "me-0"
                          }
                          onClick={() => {
                            this.toggle("2");
                          }}
                        >
                          Scheduling
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tab == 3 ? "active me-0" : "me-0"
                          }
                          onClick={() => {
                            this.toggle("3");
                          }}
                        >
                          Booking Form
                        </NavLink>
                      </NavItem>

                      <NavItem>
                        <NavLink
                          className={
                            this.state.tab == 4 ? "active me-0" : "me-0"
                          }
                          onClick={() => {
                            this.getSycnCalendarData();
                            this.toggle("4");
                          }}
                        >
                          Event Calendar
                        </NavLink>
                      </NavItem>

                      {uInfo().type === "Coach" && (
                        <NavItem>
                          <NavLink
                            className={
                              this.state.tab == 5 ? "active me-0" : "me-0"
                            }
                            onClick={() => {
                              this.toggle("5");
                            }}
                          >
                            Participants
                          </NavLink>
                        </NavItem>
                      )}
                    </Nav>
                  </div>
                  <div className="col-lg-9 ps-0">
                    <div className="CustomBorder">
                      <Loader loaded={this.state.loaded} className="spinner">
                        <AvForm
                          onSubmit={this.handleSubmit}
                          ref={(c) => (this.form = c)}
                          className="needs-validation"
                        >
                          <TabContent activeTab={this.state.tab}>
                            <TabPane tabId="1">
                              <Row>
                                <Col md={12}>
                                  <div className="EventHead">
                                    <h2>Event information</h2>
                                  </div>
                                  <ModalBody className="row">
                                    <div className="col-md-12 mb-3  d-none">
                                      <AvField
                                        name="meeting_type"
                                        value={this.state.record.meeting_type}
                                        label="Calendar type"
                                        className="form-select"
                                        type="select"
                                        disabled
                                      >
                                        <option value={"one-on-one"}>
                                          One-on-one
                                        </option>
                                        <option value={"group"}>Group</option>
                                        <option value={"round-robin"}>
                                          Round-robin
                                        </option>
                                      </AvField>
                                    </div>

                                    <div className="col-md-12 mb-3 d-none">
                                      <AvField
                                        name="calendar_title"
                                        value={this.state.record.internal_name}
                                        label="Calendar Title"
                                        className="form-control"
                                        required
                                      />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                      <AvField
                                        name="event_title"
                                        value={this.state.record.event_title}
                                        label="Event title"
                                        className="form-control"
                                        required
                                      />
                                    </div>
                                    <div className="col-md-4 mb-3">
                                      <label>Event duration </label>
                                      <div className="d-flex">
                                        <select
                                          className="form-select me-2"
                                          value={this.state.record.duration}
                                          onChange={
                                            this.handleSelectDurationChange
                                          }
                                        >
                                          <option value="15">15</option>
                                          <option value="30">30</option>
                                          <option value="45">45</option>
                                          <option value="60">60</option>
                                        </select>
                                        <input
                                          className="form-control"
                                          value={"Minutes"}
                                          readOnly
                                        />
                                      </div>
                                    </div>
                                    <div className="col-md-8 mb-3"></div>
                                    <div className="col-md-4 mb-3">
                                      <label>Event meeting or location</label>
                                      <div className="d-flex">
                                        <div class="input-group">
                                          <div class="input-group-prepend">
                                            <span
                                              class="input-group-text h-100"
                                              id="validationTooltipUsernamePrepend"
                                              style={{ width: "50px" }}
                                            >
                                              {this.state.record
                                                .location_type === "None" ? (
                                                <i
                                                  className="mdi mdi-map-marker-off-outline"
                                                  style={{ fontSize: "15.8px" }}
                                                ></i>
                                              ) : this.state.record
                                                  .location_type ===
                                                "conference_meeting" ? (
                                                <img src={meetGoogle} />
                                              ) : (
                                                <i
                                                  className="mdi mdi-map-marker-outline"
                                                  style={{ fontSize: "15.8px" }}
                                                ></i>
                                              )}
                                            </span>
                                          </div>
                                          <select
                                            className="form-select me-2"
                                            value={
                                              this.state.record.location_type
                                            }
                                            onChange={this.handleSelectChange}
                                          >
                                            <option value="None">None</option>
                                            <option value="custom">
                                              Custom Location
                                            </option>
                                            <option value="conference_meeting">
                                              Google Meet
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    {this.state.record.location_type &&
                                      this.state.record.location_type !==
                                        "None" &&
                                      this.state.record.location_type !==
                                        "conference_meeting" && (
                                        <div className="col-md-12 mb-3">
                                          <AvField
                                            name="location"
                                            value={this.state.record.location}
                                            label="Location"
                                            className="form-control"
                                          />
                                        </div>
                                      )}

                                    <div className="col-md-12 mb-3">
                                      <label className="control-label">
                                        {" "}
                                        Group{" "}
                                      </label>
                                      <Select
                                        getOptionLabel={(option) =>
                                          option.title
                                        }
                                        getOptionValue={(option) =>
                                          option.group_id
                                        }
                                        value={this.state.groups.filter(
                                          ({ group_id }) =>
                                            group_id ===
                                            this.state.record.group_id
                                        )}
                                        isMulti={false}
                                        options={this.state.groups}
                                        classNamePrefix="select2-selection"
                                        name="groups_id"
                                        onChange={(e) => {
                                          this.handleGroup(e);
                                        }}
                                      />
                                      <AvField
                                        required
                                        type="hidden"
                                        name="group_id"
                                        value={this.state.record.group_id}
                                      />
                                    </div>

                                    <div className="col-md-12 mb-3 d-none">
                                      <label className="control-label">
                                        {" "}
                                        Assign to{" "}
                                      </label>
                                      <Select
                                        getOptionLabel={(option) => option.name}
                                        getOptionValue={(option) =>
                                          option.user_id
                                        }
                                        isMulti={true}
                                        value={this.state.selectedPermission}
                                        options={this.state.permissions}
                                        classNamePrefix="select2-selection"
                                        onChange={(e) => {
                                          this.handlePermission(e);
                                        }}
                                      />
                                      <AvField
                                        type="hidden"
                                        name="permission"
                                        value={this.state.record.permission}
                                      />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <AvField
                                        name="description"
                                        value={this.state.record.description}
                                        label="Description"
                                        className="form-control"
                                        type="textarea"
                                      />
                                    </div>
                                  </ModalBody>
                                  <ModalFooter className="border-0 pt-0">
                                    <button
                                      className="btn btn-primary waves-effect waves-light"
                                      type="submit"
                                    >
                                      Next
                                    </button>
                                  </ModalFooter>
                                </Col>
                                <Col md={6}></Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={"2"}>
                              <Row>
                                <Col md={12}>
                                  <div className="EventHead">
                                    <h2>Event Schedule</h2>
                                  </div>
                                  <ModalBody>
                                    <div className="row">
                                      <div className="col-md-12 mb-3">
                                        <AvField
                                          name="calendar_url"
                                          value={this.state.record.calendar_url}
                                          label="Scheduling page link"
                                          className="form-control"
                                          readOnly
                                        />
                                      </div>

                                      <div className="col-md-12 mb-3 d-none">
                                        <AvField
                                          name="title"
                                          value={this.state.scheduling.title}
                                          label="Title"
                                          className="form-control"
                                          onChange={(e) =>
                                            this.setState((prevState) => {
                                              let scheduling = Object.assign(
                                                {},
                                                prevState.scheduling
                                              );
                                              scheduling.title = e.target.value;
                                              return { scheduling };
                                            })
                                          }
                                        />
                                      </div>
                                    </div>
                                    {this.state.scheduling.WorkingDays.map(
                                      (d, j) => {
                                        return (
                                          <div key={j} className="row mb-3">
                                            <div className="col-md-3">
                                              <AvGroup>
                                                <AvInput
                                                  placeholder="Enter business day"
                                                  className="form-control"
                                                  name={"day_" + d.key}
                                                  value={d.day}
                                                  readOnly
                                                  required
                                                />
                                                <AvFeedback>
                                                  {" "}
                                                  Required Field *
                                                </AvFeedback>
                                              </AvGroup>
                                            </div>
                                            <div className="col-md-3">
                                              <AvField
                                                type="select"
                                                name={"available_" + d.key}
                                                label=""
                                                value={d.available}
                                                onChange={(e) =>
                                                  this.setState((prevState) => {
                                                    let WorkingDays =
                                                      Object.assign(
                                                        {},
                                                        prevState.scheduling
                                                          .WorkingDays
                                                      );
                                                    WorkingDays[j].available =
                                                      e.target.value;
                                                    return { WorkingDays };
                                                  })
                                                }
                                              >
                                                <option value="on">On</option>
                                                <option value="off">Off</option>
                                              </AvField>
                                            </div>
                                            <div className="col-md-3">
                                              <AvField
                                                type="select"
                                                name={"start_time_" + d.key}
                                                label=""
                                                value={d.start}
                                                onChange={(e) =>
                                                  this.setState((prevState) => {
                                                    let WorkingDays =
                                                      Object.assign(
                                                        {},
                                                        prevState.scheduling
                                                          .WorkingDays
                                                      );
                                                    WorkingDays[j].start =
                                                      e.target.value;
                                                    return { WorkingDays };
                                                  })
                                                }
                                                disabled={
                                                  d.available == "off"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {this.StartHours().map(
                                                  (hour) => {
                                                    return (
                                                      <option
                                                        key={hour}
                                                        value={hour}
                                                      >
                                                        {hour}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </AvField>
                                            </div>
                                            <div className="col-md-3">
                                              <AvField
                                                type="select"
                                                name={"end_time_" + d.key}
                                                label=""
                                                value={d.end}
                                                onChange={(e) =>
                                                  this.setState((prevState) => {
                                                    let WorkingDays =
                                                      Object.assign(
                                                        {},
                                                        prevState.scheduling
                                                          .WorkingDays
                                                      );
                                                    WorkingDays[j].end =
                                                      e.target.value;
                                                    return { WorkingDays };
                                                  })
                                                }
                                                disabled={
                                                  d.available == "off"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {this.StartHours().map(
                                                  (hour) => {
                                                    return (
                                                      <option
                                                        key={hour}
                                                        value={hour}
                                                      >
                                                        {hour}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </AvField>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                  </ModalBody>
                                  <ModalFooter>
                                    <button
                                      className="btn btn-secondary waves-effect waves-light"
                                      type="button"
                                      onClick={() => this.toggle("1")}
                                    >
                                      Back
                                    </button>
                                    <button
                                      className="btn btn-primary waves-effect waves-light pull-left"
                                      type="submit"
                                    >
                                      Next
                                    </button>
                                  </ModalFooter>
                                </Col>
                                <Col md={6}></Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={"3"}>
                              <Row>
                                <Col md={12}>
                                  <div className="EventHead">
                                    <h2>Booking Form</h2>
                                  </div>
                                  <ModalBody>
                                    <div className="metting-details">
                                      <p>
                                        Ask prospects and customers a few
                                        qualifying questions as they are booking
                                        meetings with you.
                                      </p>
                                      {this.state.scheduling_forms.map(
                                        (row, k) => {
                                          return (
                                            <>
                                              <div
                                                className="row mb-3 mt-3"
                                                key={k}
                                              >
                                                <div className="col-md-4">
                                                  <AvField
                                                    name={row.key}
                                                    placeholder={`${row.label}`}
                                                    className="form-control"
                                                    readOnly
                                                  />
                                                </div>
                                                <div className="col-md-4">
                                                  <AvField
                                                    name={row.key}
                                                    value={row.alternative_text}
                                                    placeholder={
                                                      "Enter alternative title"
                                                    }
                                                    className="form-control"
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (prevState) => {
                                                          let scheduling_forms =
                                                            Object.assign(
                                                              {},
                                                              prevState.scheduling_forms
                                                            );
                                                          scheduling_forms[
                                                            k
                                                          ].alternative_text =
                                                            e.target.value;
                                                          return scheduling_forms;
                                                        }
                                                      )
                                                    }
                                                  />
                                                </div>
                                                <div className="col-md-2">
                                                  <AvField
                                                    name={row.key}
                                                    label={`Required`}
                                                    className="form-control mr-5"
                                                    type="checkbox"
                                                    value={row.required}
                                                    checked={row.required}
                                                    disabled={
                                                      k <= 2 ? true : false
                                                    }
                                                    onChange={(e) =>
                                                      this.setState(
                                                        (prevState) => {
                                                          let scheduling_forms =
                                                            Object.assign(
                                                              {},
                                                              prevState.scheduling_forms
                                                            );
                                                          scheduling_forms[
                                                            k
                                                          ].required =
                                                            e.target.checked;
                                                          return scheduling_forms;
                                                        }
                                                      )
                                                    }
                                                  />
                                                </div>
                                                {k > 2 && (
                                                  <div className="col-md-2">
                                                    <button
                                                      type="button"
                                                      className="btn btn-danger btn-sm"
                                                      onClick={this.removeOptionClick.bind(
                                                        this,
                                                        k
                                                      )}
                                                    >
                                                      <i className="ion ion-md-trash">
                                                        {" "}
                                                      </i>
                                                    </button>
                                                  </div>
                                                )}
                                              </div>
                                            </>
                                          );
                                        }
                                      )}
                                      <button
                                        type="button"
                                        className="btn btn-lignt p0 mb-3 btn-sm"
                                        onClick={() =>
                                          this.setState({ addMore: true })
                                        }
                                      >
                                        Add other form field
                                      </button>
                                      {this.state.addMore && (
                                        <div className="col-md-12 mb-3 min-height-200">
                                          <Select
                                            getOptionLabel={(option) =>
                                              option.label
                                            }
                                            getOptionValue={(option) =>
                                              option.key
                                            }
                                            // value={options.filter(({ id }) => id === this.state.country_code)}
                                            isMulti={false}
                                            options={((ids) =>
                                              this.state.tokens.filter(
                                                (n) => !ids.includes(n.key)
                                              ))(
                                              this.state.scheduling_forms.map(
                                                ({ key }) => key
                                              )
                                            )}
                                            classNamePrefix="select2-selection"
                                            name="country_id"
                                            onChange={(e) => {
                                              this.addField(e);
                                            }}
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                    <button
                                      className="btn btn-secondary waves-effect waves-light"
                                      type="button"
                                      onClick={() => this.toggle("1")}
                                    >
                                      Back
                                    </button>
                                    <button
                                      className="btn btn-primary waves-effect waves-light pull-left"
                                      type="submit"
                                    >
                                      Submit
                                    </button>
                                  </ModalFooter>
                                </Col>
                                <Col md={6}></Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId={"4"}>
                              <Row>
                                <Col md={12}>
                                  <div className="EventHead">
                                    <h2>Map an Event Calendar</h2>
                                  </div>
                                  <ModalBody>
                                    <div className="metting-details">
                                      <table className="table table-bordered">
                                        <tbody>
                                          {console.log(
                                            "this.state.records",
                                            this.state.records
                                          )}
                                          {this.state.records.length === 0 ? (
                                            <>
                                              <div
                                                className="alert alert-info"
                                                role="alert"
                                              >
                                                You are not authenticated with
                                                Nylas. Please
                                                <a
                                                  href={`${WebUrl}/google/sync?u_id=${
                                                    uInfo().user_id
                                                  }`}
                                                  className="alert-link"
                                                >
                                                  authenticate here.
                                                </a>
                                              </div>
                                              <button
                                                className="btn btn-secondary"
                                                onClick={() =>
                                                  this.props.history.push(
                                                    "/settings/calendar"
                                                  )
                                                }
                                              >
                                                Skip
                                              </button>
                                            </>
                                          ) : (
                                            this.state.records.map((row, i) => (
                                              <tr key={i}>
                                                <td>
                                                  {row.summary || row.name}
                                                </td>
                                                <td>
                                                  <input
                                                    type="radio"
                                                    name="calendarSelection"
                                                    value={row.id}
                                                    checked={
                                                      this.state
                                                        .selected_calendar_id ===
                                                        row.id ||
                                                      this.state.record
                                                        .nylas_calendar_id ===
                                                        row.id
                                                    }
                                                    onChange={() =>
                                                      this.setState({
                                                        selected_calendar_id:
                                                          row.id,
                                                        selected_calendar_name:
                                                          row.name,
                                                        selected_calendar_summary:
                                                          row.summary,
                                                      })
                                                    }
                                                  />
                                                </td>
                                              </tr>
                                            ))
                                          )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </ModalBody>
                                  <ModalFooter>
                                    <button
                                      className="btn btn-primary waves-effect waves-light pull-left"
                                      type="button"
                                      onClick={() =>
                                        // this.submitGoogleCalanderId()
                                        this.toggle("5")
                                      }
                                    >
                                      Submit
                                    </button>
                                  </ModalFooter>
                                </Col>
                                <Col md={6}></Col>
                              </Row>
                            </TabPane>

                            <TabPane tabId={"5"}>
                              <Row>
                                <Col md={12}>
                                  <div className="EventHead">
                                    <h2>Participants</h2>
                                  </div>
                                  <ModalBody>
                                    <div className="metting-details">
                                      <table className="table table-bordered">
                                        <tbody>
                                          {/* <div className="mb-4">
                                            <label className="block text-sm font-large">
                                              Organizer
                                            </label>
                                            <input
                                              type="email"
                                              value={this.state.email || ""}
                                              readOnly
                                              className="form-control border-1 py-2"
                                            />
                                          </div> */}

                                          <div className="form-group mb-3">
                                            <label>Round-Robin Member(s)</label>
                                            <table className="table">
                                              <thead>
                                                <tr>
                                                  <th>Name</th>
                                                  <th>Email</th>
                                                  {/* <th>Default Event</th> */}
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {console.log(
                                                  "ept",
                                                  this.state
                                                    .meeting_participants
                                                )}
                                                {this.state
                                                  .meeting_participants &&
                                                  this.state.meeting_participants.map(
                                                    (member) => {
                                                      const selectedCalendar =
                                                        member.calendars?.[0];
                                                      return (
                                                        <tr key={member.id}>
                                                          <td>{member.name}</td>
                                                          <td>
                                                            {member.email}
                                                          </td>
                                                          {/* <td>
                                                            {selectedCalendar
                                                              ? selectedCalendar.event_title
                                                              : "No Calendar Available"}
                                                          </td> */}
                                                          {/* {selectedCalendar && (
                                                            <input
                                                              type="hidden"
                                                              name={`team_members_${member.id}`}
                                                              value={
                                                                selectedCalendar.nylas_calendar_id
                                                              }
                                                            />
                                                          )} */}
                                                        </tr>
                                                      );
                                                    }
                                                  )}
                                              </tbody>
                                            </table>
                                          </div>
                                        </tbody>
                                      </table>
                                    </div>
                                  </ModalBody>

                                  <ModalFooter>
                                    <button
                                      className="btn btn-primary waves-effect waves-light pull-left"
                                      type="button"
                                      onClick={() =>
                                        this.submitGoogleCalanderId(
                                          this.state.firstRecord?.calendar_id
                                        )
                                      }
                                    >
                                      Submit
                                    </button>
                                  </ModalFooter>
                                </Col>
                                <Col md={6}></Col>
                              </Row>
                            </TabPane>
                          </TabContent>
                        </AvForm>
                      </Loader>
                    </div>
                  </div>
                </Row>
              </Card>
            </Col>
          </Row>
        )}
      </React.Fragment>
    );
  }
}
