import React from "react";

import { Card, CardBody } from "reactstrap";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import { bcrypt, uToken } from "../../useToken";
import { ApiUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import moment from "moment";
import $ from "jquery";
export default class CalanderView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      offDays: [],
      month_year: new moment().format("YYYY-MM"),
      loader: true,
      dateClick: "",
      events_day: [],
      client_id: props.clientId ? props.clientId : 0,
      calendars: [],
      calendar_id: 0,
    };
  }
  calendarRef = React.createRef();
  calendarRefDay = React.createRef();

  componentDidMount() {
    this.getData(this.state.month_year);
  }

  getData = (month_year, calendar_id = 0) => {
    fetch(`${ApiUrl}` + "dashboard/schedule/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        selected_date: month_year,
        client_id: this.state.client_id,
        calendar_id: calendar_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          //console.log(response.data.record.schedule_log, 'cccccccccccccc')
          this.setState({
            events: response.data.records,
            calendars: response.data.calendars,
            events_day: [],
            calendar_id: response.data.calendar_id,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getDataByDay = (month_year, calendar_id = 0) => {
    fetch(`${ApiUrl}` + "dashboard/schedule/statistics", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        selected_date: month_year,
        client_id: this.state.client_id,
        calendar_id: calendar_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.setState({
            events_day: response.data.records,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleDateClick = (arg) => {
    this.setState({
      dateClick: "",
    });

    $(".fc-daygrid-day").removeClass("selectionDate");

    $("[data-date=" + moment(arg.dateStr).format("YYYY-MM-DD") + "]").addClass(
      "selectionDate"
    );

    var self = this;
    setTimeout(() => {
      self.setState({
        dateClick: arg.dateStr,
      });
    }, 1000);

    this.getDataByDay(arg.dateStr, this.state.calendar_id);
  };

  handleEventClick = (arg) => {
    let client_id = bcrypt(arg.event.extendedProps.client_id);
    let coach_id = bcrypt(arg.event.extendedProps.coach_id);
    this.props.history.push("/client/dialer/" + client_id + "/" + coach_id);
  };

  renderEventContentByDay(eventInfo) {
    return (
      <div className="event-content-day">
        <span className="event-label">
          <p>{eventInfo.event.title}</p>{" "}
          <p>{eventInfo.event._def.extendedProps.time}</p>
        </span>
      </div>
    );
  }

  renderEventContent(eventInfo) {
    return (
      <span className="event-label-month">
        <i className="fas fa-circle"></i>
      </span>
    );
  }

  render() {
    const date = new Date();
    const d = date.getDate();
    const m = date.getMonth();
    const y = date.getFullYear();
    const self = this;
    return (
      <div className="dashboard-calander">
        <div className="calander-full-dashboard">
          <div className="d-flex justify-content-between align-items-center">
            <input
              value={this.state.month_year}
              required="required"
              onChange={(e) => {
                let date = e.target.value;
                if (date == "") {
                  date = new moment().format("YYYY-MM");
                }
                this.setState({
                  loader: false,
                  month_year: date,
                  dateClick: "",
                });

                this.getData(date);

                var self = this;
                setTimeout(() => {
                  self.setState({
                    loader: true,
                  });
                }, 1000);
              }}
              className="form-control"
              type={"month"}
              name="deal_month"
            />
            {this.state.client_id != 0 && (
              <div className="selectboxtop PlusSign CalanderPanel align-items-center h-auto">
                <h5 className="card-title mb-3">
                  <button
                    className="btn btn-primary code-green border-0 float-end btn-sm"
                    onClick={() => this.props.scheduleEvent()}
                  >
                    <i className="fas fa-plus"></i>
                  </button>
                </h5>
              </div>
            )}

            {this.state.client_id == 0 && (
              <div className="MonthlyDropDown p-tracker mb-1">
                <select
                  value={this.state.calendar_id}
                  onChange={(e) => {
                    this.getData(this.state.month_year, e.target.value);
                  }}
                  className="form-control"
                >
                  <option key={0} value={0}>
                    All
                  </option>
                  {this.state.calendars.map((row, i) => {
                    return (
                      <option key={row.calendar_id} value={row.calendar_id}>
                        {row.event_title}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          </div>

          {this.state.loader && (
            <Card className="w-100">
              <CardBody className="p-0">
                <div className="MonthCal mt-4" id="table-full">
                  <FullCalendar
                    // views={{
                    //     dayGridMonth: {
                    //         // titleFormat: { year: 'numeric', month: '2-digit', day: '2-digit' },
                    //         dayHeaderFormat: {
                    //             weekday: 'short'
                    //         }
                    //     }
                    // }}
                    // dayHeaderContent={(args) => {
                    //     return moment(args.date).format('dddd').substring(0, 1)
                    // }}
                    height={"auto"}
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    initialDate={this.state.month_year}
                    handleWindowResize={false}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "",
                      center: "title",
                      right: "",
                    }}
                    events={this.state.events}
                    eventContent={this.renderEventContent}
                    editable={false}
                    droppable={false}
                    dateClick={this.handleDateClick}
                    eventClick={this.handleEventClick}
                    ref={this.calendarRef}
                  />
                </div>
                {this.state.dateClick != "" && (
                  <div className="DayCal">
                    <div className="CalendarSelectDate">
                      {moment(this.state.dateClick).format(
                        "dddd MMMM Do, YYYY"
                      )}
                    </div>
                    <ul className="CalendarTask mt-3">
                      {this.state.events_day.map((row, i) => {
                        return (
                          <li
                            key={i}
                            className="pt-2 pb-2"
                            // onClick={() => {
                            //     console.log(row);
                            //     this.props.scheduleEvent(row);
                            //   }}
                          >
                            <div className="DotTask code-dark"></div>
                            <div className="TaskDescp">
                              {/* <div className="research-date"><p>{moment(row.start).format('MMMM Do')}</p></div> */}
                              <div className="research-date">
                                <p>
                                  {moment(row.server_date).format("MMMM Do")}
                                </p>
                              </div>
                              <hr className="my-1"></hr>
                              <div className="desp-event">
                                {this.state.client_id == 0 && (
                                  <p>
                                    <b>Client Name:</b> {row.with}
                                  </p>
                                )}
                              </div>
                              <p>
                                <b>Title:</b> {row.title}
                              </p>
                              <p>
                                <b>DateTime:</b> {row.date_time_convert}
                              </p>
                              {/* <p><b>DateTime:</b> {row.from_time_convert}</p> */}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {/* <FullCalendar
                                                    height={'auto'}
                                                    plugins={[
                                                        BootstrapTheme,
                                                        timeGridPlugin,
                                                        interactionPlugin
                                                    ]}
                                                    initialView={'timeGridDay'}
                                                    initialDate={this.state.dateClick}
                                                    handleWindowResize={false}
                                                    themeSystem="bootstrap"
                                                    headerToolbar={{
                                                        left: "",
                                                        center: "title",
                                                        right: ''
                                                    }}
                                                    events={this.state.events_day}
                                                    eventContent={this.renderEventContentByDay}
                                                    editable={false}
                                                    droppable={false}
                                                    // dateClick={this.handleDateClick}
                                                    // eventClick={this.handleEventClick}
                                                    ref={this.calendarRefDay}
                                                /> */}
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </div>
      </div>
    );
  }
}
