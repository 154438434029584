import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Container } from "reactstrap";

import { toast, ToastContainer, Flip } from "react-toastify";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import moment from "moment";
import { ApiUrl, WebUrl } from "../../config";
import { uToken } from "../../useToken";
import {
  Dropdown,
  DropdownButton,
  FormControl,
  ListGroup,
  Modal,
} from "react-bootstrap";
import {
  Asia,
  TimezoneMain,
  America,
  Australia,
  Europe,
  Indian,
} from "../../common/data/timezones";
import $ from "jquery";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "react-loader";
import swal from "sweetalert";

const LeadCalander = (props) => {
  const [page_name] = useState("Meet");

  const [leadName, setLeadName] = useState(props.name);
  const [leadEmail, setLeadEmail] = useState(props.email);
  const [leadPhone, setLeadPhone] = useState(props.phone);
  const [info, setInfo] = useState({});
  const [hiddenDays, setHiddenDays] = useState([]);
  const [pickDate, setPickDate] = useState(moment().format("YYYY-MM-DD"));
  const [slots, setSlots] = useState([]);
  const [calendars, setCalendars] = useState([]);
  const [meeting_modal, setMeetingModal] = useState(false);
  const [main, setMain] = useState(
    props.time_zone ? props.time_zone.split("/").shift() : "America"
  );
  const [zone, setZone] = useState(
    props.time_zone ||
      Intl.DateTimeFormat().resolvedOptions().timeZone ||
      "America/Los_Angeles"
  );
  const [zoneLabel, setZoneLabel] = useState(() => {
    if (main === "America") {
      const foundZone = America.find((item) => item.id === zone);
      return foundZone ? foundZone.label : zone;
    }
    return zone;
  });

  const [Timezone, setMainTimeZone] = useState(TimezoneMain);
  const [zones, setZones] = useState(America);

  const calendarRef = React.createRef();

  const [step, setStep] = useState("1");
  const [slot, setSlot] = useState("");

  const [form, setForm] = useState("");
  const [loaded, setLoaded] = useState(true);
  const handleDateClick = (arg) => {
    // bind with an arrow function
    // alert(arg.dateStr)
  };

  useEffect(() => {
    getCalendars();
  }, []);

  useEffect(() => {
    getMeetData();
    console.log(zoneLabel, "zone_lable");
  }, [zone]);

  useEffect(() => {
    getMeetData();
  }, [pickDate]);

  useEffect(() => {
    $("[data-date=" + moment().format("YYYY-MM-DD") + "]").addClass(
      "selectedDate"
    );
  }, []);

  const getCalendars = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "meetings", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          setCalendars(response.data.records);
          response.data.records &&
            response.data.records.forEach((record) => {
              if (record.default_status === "Yes") {
                getMeetData(record.calendar_id);
              }
            });
        }
        setLoaded(true);
      })
      .catch((error) => {});
  };

  const getMeetData = (cal_id) => {
    setLoaded(false);
    let id = cal_id || info.calendar_id || null;

    fetch(`${ApiUrl}` + "get/calander/link", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        calendar_id: id,
        date: pickDate,
        timezone: zone,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setInfo(response.data.record);
          setHiddenDays(response.data.off_days);
          setSlots(response.data.slots);
          setForm(response.data.schedule_form);

          // nylas functionlity
          // if (
          //   response.data.record.schedule_settings.main_region &&
          //   response.data.record.schedule_settings.sub_region
          // ) {
          //   setMain(response.data.record.schedule_settings.main_region);
          //   setZone(response.data.record.schedule_settings.sub_region);
          //   setZoneLabel(() => {
          //     if (
          //       response.data.record.schedule_settings.main_region === "America"
          //     ) {
          //       setZones(America);
          //       const foundZone = America.find(
          //         (item) =>
          //           item.id ===
          //           response.data.record.schedule_settings.sub_region
          //       );
          //       return foundZone
          //         ? foundZone.label
          //         : response.data.record.schedule_settings.sub_region;
          //     } else if (
          //       response.data.record.schedule_settings.main_region === "Asia"
          //     ) {
          //       setZones(Asia);
          //       const foundZone = Asia.find(
          //         (item) =>
          //           item.id ===
          //           response.data.record.schedule_settings.sub_region
          //       );
          //       return foundZone
          //         ? foundZone.label
          //         : response.data.record.schedule_settings.sub_region;
          //     } else if (
          //       response.data.record.schedule_settings.main_region ===
          //       "Australia"
          //     ) {
          //       setZones(Australia);
          //       const foundZone = Australia.find(
          //         (item) =>
          //           item.id ===
          //           response.data.record.schedule_settings.sub_region
          //       );
          //       return foundZone
          //         ? foundZone.label
          //         : response.data.record.schedule_settings.sub_region;
          //     } else if (
          //       response.data.record.schedule_settings.main_region === "Europe"
          //     ) {
          //       setZones(Europe);
          //       const foundZone = Europe.find(
          //         (item) =>
          //           item.id ===
          //           response.data.record.schedule_settings.sub_region
          //       );
          //       return foundZone
          //         ? foundZone.label
          //         : response.data.record.schedule_settings.sub_region;
          //     } else if (
          //       response.data.record.schedule_settings.main_region === "Indian"
          //     ) {
          //       setZones(Indian);
          //       const foundZone = Indian.find(
          //         (item) =>
          //           item.id ===
          //           response.data.record.schedule_settings.sub_region
          //       );
          //       return foundZone
          //         ? foundZone.label
          //         : response.data.record.schedule_settings.sub_region;
          //     }
          //     return zone;
          //   });
          // }
          // else {
          //   setZoneLabel(() => {
          //     if (main === "America") {
          //       const foundZone = America.find((item) => item.id === zone);
          //       return foundZone ? foundZone.label : zone;
          //     }
          //     return zone;
          //   });
          // }

          setZoneLabel(() => {
            if (main === "America") {
              const foundZone = America.find((item) => item.id === zone);
              return foundZone ? foundZone.label : zone;
            }
            return zone;
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
        setLoaded(true);
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
        setLoaded(true);
      });
  };

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul
            className="list-unstyled"
            style={{ maxHeight: "250px", overflow: "auto" }}
          >
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const changeMainZone = (zone) => {
    if (zone == "Asia") {
      setZones(Asia);
      setZone(Asia[0].id);
      setZoneLabel(Asia[0].id);
    } else if (zone == "America") {
      setZones(America);
      setZone(America[0].id);
      setZoneLabel(America[0].label);
    } else if (zone == "Australia") {
      setZones(Australia);
      setZone(Australia[0].id);
      setZoneLabel(Australia[0].id);
    } else if (zone == "Europe") {
      setZone(Europe[0].id);
      setZoneLabel(Europe[0].id);
      setZones(Europe);
    } else if (zone == "Indian") {
      setZone(Indian[0].id);
      setZoneLabel(Indian[0].id);
      setZones(Indian);
    }
    setMain(zone);
  };

  const handleSubmitMeeting = async (event, values) => {
    event.preventDefault();
    console.log(props.client_id, "client_id");
    setLoaded(false);
    values.coach_id = info.coach_id;
    values.coach_partner_id = info.coach_partner_id;
    values.group_id = [info.group_id];
    values.calendar_id = info.calendar_id;
    values.event_title = info.event_title;
    values.location = info.location;
    values.client_time_zone = zone;
    values.pick_date = pickDate;
    values.from_time = slot;
    values.duration = info.duration;
    values.client_id = props.client_id;
    values.schedule_confirmation_email = info.automation_settings
      .confirmation_email
      ? "Y"
      : "N";
    values.pre_meeting_reminder = info.automation_settings.reminder ? "Y" : "N";

    fetch(`${ApiUrl}` + "submit/metting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          swal({
            title: data.message,
            icon: "success",
          }).then(() => {
            props.setMeetingModal(false);
            props.getTaskStatistics("monthly");
          });

          // setTimeout(
          //   () => setStep('1'),
          //   500
          // );
          // getMeetData();

          setLoaded(true);
        } else {
          setLoaded(true);
          swal({
            title: data.message,
            icon: "warning",
          });
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  return (
    <React.Fragment>
      <Container className="cal-custom custom-calander CoachSide px-0">
        <ToastContainer />
        <Loader loaded={loaded}></Loader>
        {/* {step == "1" ? (
          <Row className="calander-meetings">
            <div className="col-lg-12">
              <div className="CustomShadow">
                <div className="row ms-lg-0">
                  <Col md={6} className="px-lg-0">
                    <div className="MeetingCalendar">
                      <div className="TimeDuration">
                      <p className="mb-0 MeetingUser">
                        <i className="fas fa-calendar-alt"></i>{" "}
                        <select 
                          value={info.calendar_id} 
                          onChange={(e) => getMeetData(e.target.value)}
                          className="p-1"
                        >
                          {calendars && calendars.map((row) => (
                            <option key={row.calendar_id} value={row.calendar_id}>
                              {row.internal_name}
                            </option>
                          ))}
                        </select>
                      </p>

                        
                        <p className="mb-0 MeetingUser">
                          <i class="far fa-clock"></i> {info.duration} minutes
                        </p>
                      </div>
                      <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        ref={calendarRef}
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "",
                        }}
                        selectable={true}
                        selectHelper={true}
                        validRange={
                          {
                          }
                        }
                  
                        dateClick={function (info) {
                          var now = moment(new Date()); // today's date
                          var end = moment(info.dateStr); // clicked date
                          var duration = moment.duration(now.diff(end));
                          var days = duration.asDays();

                          if (days <= 0) {
                            if (
                              !hiddenDays.includes(
                                moment(info.dateStr).format("d")
                              )
                            ) {
                              // Remove `selectedDate` and `childselectedDate` from all days
                              $(".fc-daygrid-day").removeClass("selectedDate");
                              $(".fc-daygrid-day")
                                .find(".childselectedDate")
                                .removeClass("childselectedDate");

                              // Add `selectedDate` and `childselectedDate` to the clicked date
                              $("[data-date=" + info.dateStr + "]").addClass(
                                "selectedDate"
                              );
                              $("[data-date=" + info.dateStr + "]")
                                .children()
                                .children()
                                .addClass("childselectedDate");

                              setPickDate(info.dateStr);
                              return true;
                            } else {
                              return false;
                            }
                          } else if (days > 0 && days < 1) {
                            $(".fc-daygrid-day").removeClass("selectedDate");
                            $(".fc-daygrid-day")
                              .find(".childselectedDate")
                              .removeClass("childselectedDate");

                            $(
                              "[data-date=" +
                                moment().format("YYYY-MM-DD") +
                                "]"
                            ).addClass("selectedDate");
                            setPickDate(info.dateStr);
                          } else {
                            info.dayEl.click.disabled = true;
                            return false;
                          }
                        }}
                        selectAllow={function (select) {
                          if (
                            hiddenDays.includes(
                              moment(select.start).format("d")
                            )
                          ) {
                            return false;
                          } else {
                            return true;
                          }
                          }}

                        select={function (info) {}}
                      />
                    </div>
                  </Col>
                  <Col md={6} className="ps-lg-0">
                    <div className="RightSidebar border-left">
                      <h5>
                        <i className="far fa-calendar-alt"></i>{" "}
                        {moment(pickDate).format("dddd, MMMM D, YYYY")}
                      </h5>

                      {slots.length < 1 && <h6>No available times</h6>}

                      <ListGroup horizontal className="slot-list">
                        {slots.map((row, i) => {
                          return (
                            <ListGroup.Item
                              onClick={() => {
                                setStep("2");
                                setSlot(row);
                              }}
                              className="list-group-item"
                              key={i}
                            >
                              {row}
                            </ListGroup.Item>
                          );
                        })}
                      </ListGroup>
                    </div>
                  </Col>
                </div>
                <Col lg={12} className="py-2 border-top px-3">
                  <p>
                    {" "}
                    <button
                      onClick={() => setMeetingModal(true)}
                      className="btn btn-link text-dark pe-1"
                    >
                      <i className="fas fa-globe-americas me-1"></i> {zoneLabel}{" "}
                      <i className="fas fa-angle-down"></i>
                    </button>
                  </p>
                </Col>
              </div>
            </div>
          </Row>
        ) : step == "2" ? (
          <div className="col-lg-12">
            <div className="CustomShadow">
              <Row>
                <Col lg={"12"}>
                  <div className="RightSidebar h-100 py-5 d-flex align-items-center">
                    <div className="MeetingDetails">
                      <span className="MeetingIcon">
                        <i className="far fa-calendar-alt"></i>
                      </span>
                      <h3>{moment(pickDate).format("dddd, MMMM D, YYYY")}</h3>
                      <h4>{slot}</h4>
                      <h4>
                        <i className="fas fa-globe-americas me-1"></i>{" "}
                        {zoneLabel}
                      </h4>
                    </div>
                  </div>
                </Col>
                <Col lg={"12"}>
                  <AvForm
                    onValidSubmit={handleSubmitMeeting}
                    className="needs-validation SchedualForm"
                  >
                    <div className="border-top p-3 BottomButts">
                      <button
                        className="btn btn-outline-primary waves-effect waves-light mr-5"
                        type="button"
                        onClick={() => setStep("1")}
                      >
                        Back
                      </button>
                      <button
                        className="btn btn-primary waves-effect waves-light"
                        type="submit"
                      >
                        Book now
                      </button>
                    </div>
                  </AvForm>
                </Col>
              </Row>
            </div>
          </div>
        ) : (
          <></>
        )} */}
        <div className="nylas-booking d-flex ms-2">
          <i className="fas fa-calendar-alt my-auto"></i>{" "}
          <p className="mb-0 MeetingUser ms-2">
            <select
              className="form-control fw-bold"
              value={info.calendar_id}
              onChange={(e) => getMeetData(e.target.value)}
            >
              {calendars &&
                calendars.map((row) => (
                  <option
                    key={row.calendar_id}
                    value={row.calendar_id}
                    className="fw-bold"
                  >
                    {/* {row.internal_name} {row.duration} */}
                    {row.internal_name} -{" "}
                    {row.duration == 60 ? "1 hour" : `${row.duration} minutes`}
                  </option>
                ))}
            </select>
          </p>
          {/* <p className="mb-0 MeetingUser">
            <i class="far fa-clock"></i> {info.duration} minutes
          </p> */}
        </div>

        {info.booking_id && loaded ? (
          <iframe className="CustomIframe"
            src={`${WebUrl}/schedule?config_id=${info.booking_id}&name=${leadName}&email=${leadEmail}&phone=${leadPhone}`}
            width="100%"
            
          ></iframe>
        ) : (
          !info.booking_id &&
          loaded && (
            <div className="alert alert-info h-auto my-5" role="alert">
              Calendar configuration not found or requires authentication.
            </div>
          )
        )}

        <Modal show={meeting_modal} backdrop={"static"}>
          <Modal.Header>
            <div className="modal-head top-heading" style={{ width: "100%" }}>
              <Modal.Title>Main Timezone</Modal.Title>
              <button
                onClick={() => {
                  setMeetingModal(false);
                }}
                type="button"
                className="btn btn-link text-white"
              >
                <i className="fas fa-times"> </i>
              </button>
            </div>
          </Modal.Header>

          <Modal.Body>
            <div className="d-flex space-between">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className="btn btn-primary"
                >
                  {main == "" ? "Your Timezone" : main}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {Timezone.map((zone, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => changeMainZone(zone)}
                        eventKey={i}
                      >
                        {zone}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className="btn btn-primary"
                >
                  {zoneLabel == "" ? "Your Timezone" : zoneLabel}
                </Dropdown.Toggle>

                <Dropdown.Menu as={CustomMenu}>
                  {zones.map((zone, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => {
                          {
                            setZone(zone.id);
                            setZoneLabel(zone.label);
                          }
                        }}
                        eventKey={i}
                      >
                        {zone.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="info" onClick={() => setMeetingModal(false)}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default LeadCalander;
