import React, { Component, Fragment } from "react";
import {
    Row,
    Col,
    Button,
    Card,
    CardBody,

} from "reactstrap";

import Helmet from "react-helmet";
import {
    dcrypt,
    uToken,
} from "../../useToken";
import { ApiUrl, ProductName, ToastAutoClose } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

export default class GoogleCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.match.params.id ? dcrypt(this.props.match.params.id) : null,
            records: [],
            calander: {}
        };

    }

    componentDidMount() {
        this.state.id != null && this.getData();
    }


    submitGoogleCalanderId = (google_id , nylas_calendar_name) => {
        fetch(`${ApiUrl}` + "update/google/calander", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.calander.calendar_id, google_id: google_id , nylas_calendar_name: nylas_calendar_name, },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.props.history.push('/settings/calendar');
                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    getData = () => {
        fetch(`${ApiUrl}` + "get/google/calander", {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: `Bearer ` + uToken(),
            },

            body: JSON.stringify({ id: this.state.id },
            ),
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.status === true) {
                    this.setState({
                        calander: response.data.calendar,
                        records: response.data.calArr,
                    });

                } else {


                    toast(response.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
                }
            })
            .catch((error) => {
                console.error("Error:", error);
            });
    };

    render() {
        return (
            <React.Fragment>
                <Row>
                    <Col sm={6}>
                        <div className="page-title-box">
                            <h4> Map  "{this.state.calander.event_title}" With  {this.state.calander?.calendar_type} Calendar  </h4>
                            <ol className="breadcrumb m-0">
                                <li key={0} className="breadcrumb-item active">
                                    {ProductName}
                                </li>
                                <li key={1} className="breadcrumb-item">
                                    <Link to="#"> {this.state.calander.event_title} </Link>
                                </li>
                            </ol>
                        </div>
                    </Col>
                    <Col sm={6}>
                        <div className="page-title-box text-align-right">
                            <Button
                                type="button"
                                color="secondary"
                                onClick={() => this.props.history.goBack()}
                            >
                                Back
                            </Button>
                        </div>
                    </Col>
                </Row>

                <Card>
                    <CardBody>
                        <h4> {this.state.calander?.calendar_type} Calendar</h4>
                        <table className="table table-bordered">
                            <tbody>
                                {
                                    this.state.records.map((row, i) => {
                                        return <tr key={i}>
                                            <td>{row.summary || row.name}</td>
                                            <td><button className="btn btn-link" onClick={() => this.submitGoogleCalanderId(row.id, row.name)}>Use This</button></td>
                                        </tr>
                                    })
                                }

                            </tbody>
                        </table>
                    </CardBody>
                </Card>
                <ToastContainer />
            </React.Fragment >
        );
    }
}
