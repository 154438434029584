import React, { useEffect, useRef, useState } from "react";
import Loader from "react-loader";
import swal from "sweetalert";
import {
  ApiUrl,
  AttachementsUrl,
  FroalaLicenseKey,
  HostUrl,
} from "../../config";
import { bcrypt, uInfo, uToken } from "../../useToken";
import ListGroup from "react-bootstrap/ListGroup";
import { Link, useHistory, useLocation } from "react-router-dom";
import $ from "jquery";
import FroalaEditor from "froala-editor";

// Load a plugin.
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/align.min.js";
import "froala-editor/js/plugins/code_beautifier.min.js";
import "froala-editor/js/plugins/code_view.min.js";
import "froala-editor/js/plugins/colors.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/draggable.min.js";
import "froala-editor/js/plugins/font_size.min.js";
import "froala-editor/js/plugins/font_family.min.js";
import "froala-editor/js/plugins/image.min.js";
import "froala-editor/js/plugins/image_manager.min.js";
import "froala-editor/js/plugins/line_breaker.min.js";
// import "froala-editor/js/plugins/quick_insert.min.js"
import "froala-editor/js/plugins/link.min.js";
import "froala-editor/js/plugins/lists.min.js";
import "froala-editor/js/plugins/paragraph_format.min.js";
import "froala-editor/js/plugins/paragraph_style.min.js";
import "froala-editor/js/plugins/video.min.js";
import "froala-editor/js/plugins/table.min.js";
import "froala-editor/js/plugins/url.min.js";
import "froala-editor/js/plugins/emoticons.min.js";
import "froala-editor/js/plugins/file.min.js";
import "froala-editor/js/plugins/entities.min.js";
import "froala-editor/js/plugins/inline_style.min.js";
import "froala-editor/js/plugins/save.min.js";
import "froala-editor/js/plugins/fullscreen.min.js";
import "froala-editor/js/plugins/files_manager.min.js";

// Require Editor CSS files.
import "froala-editor/css/froala_style.min.css";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/plugins/code_view.css";
import "froala-editor/css/plugins/image_manager.css";
import "froala-editor/css/plugins/image.css";
import "froala-editor/css/plugins/file.css";
import "froala-editor/css/plugins/table.css";
import "froala-editor/css/plugins/video.css";

import "froala-editor/css/themes/dark.min.css";
import "froala-editor/css/themes/dark.css";
import "froala-editor/css/themes/gray.min.css";
import "froala-editor/css/themes/gray.css";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ReactPlayer from "react-player/lazy";
import UploadFiles from "../ResourcesDrive/files_upload";

// Initialize editor.
function Froala(props) {
  const editorId = props.editorId || "";
  const purpose = props.purpose || "";
  const questionIndex = props.questionIndex || "";
  const optionIndex = props.optionIndex || "";
  const themeFor = props.themeFor || "";

  const history = useHistory();
  const [setupreview] = useState(
    history.location.pathname.includes("trainingsreview") ? true : false
  );

  const [preview, setPreview] = useState(false);
  const [modal, setModal] = useState(false);
  const [link, setLink] = useState(false);

  const [insideForm] = useState(props.insideForm ? true : false);
  const [nameKey] = useState(props.nameKey ? props.nameKey : "editor_content");
  const [modal2, setModal2] = useState(false);
  const [type, setType] = useState("my-resources");
  const [lists, setLists] = useState({
    files: [],
    folders: [],
  });
  const [folderId, setFolderId] = useState(0);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [libObjs, setLibObjs] = useState("");
  const [page2Class, setpage2Class] = useState("");
  const [subAction, setSubAction] = useState({});
  const [pdfView, setPdfView] = useState("");
  const [fileUploadModal, setFileUploadModal] = useState(false);

  const [onboradingTokendata, setOnboradingToken] = useState(false);
  useEffect(() => {
    if (props.onboradingToken) {
      setOnboradingToken(true);
    }
  }, [props]);

  useEffect(() => {
    //console.log("props onboradingTokendata ----------->  ", props.onboradingToken)
    //console.log("onboradingTokendata ----------->  ", onboradingTokendata)
  }, [onboradingTokendata]);

  var a;

  // const editorRef = useRef([]);

  const editior = (
    usertoken = [],
    clientToken = [],
    system_forms_token = []
  ) => {
    var users = usertoken.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.label }),
      {}
    );

    var clients = clientToken.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.label }),
      {}
    );

    var system_forms = system_forms_token.reduce(
      (obj, item) => Object.assign(obj, { [item.key]: item.label }),
      {}
    );

    if (purpose !== "quiz-content") {
      FroalaEditor.DefineIcon("tkn_list", { NAME: "Tokens", template: "text" });

      FroalaEditor.RegisterCommand("my_dropdown", {
        icon: "tkn_list",
        title: "User Token",
        type: "dropdown",
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: users,
        callback: function (cmd, val) {
          var editor_token = new FroalaEditor(`#edit${editorId}`);
          editor_token.selection.save();
          editor_token.selection.restore();
          editor_token.html.insert(" {" + val + "} ");
        },
        // Callback on refresh.
        refresh: function ($btn) {
          //console.log('do refresh')
          // var editor_token = new FroalaEditor("#edit");
          // console.log( editor_token.html.get());
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          console.log("do refresh when show");
        },
      });

      FroalaEditor.DefineIcon("tkn_list1", {
        NAME: "Client Tokens",
        template: "text",
      });

      FroalaEditor.RegisterCommand("my_dropdown1", {
        icon: "tkn_list1",
        title: "Client Tokens",
        type: "dropdown",
        focus: false,
        undo: false,
        refreshAfterCallback: true,
        options: clients,
        callback: function (cmd, val) {
          var editor_token = new FroalaEditor(`#edit${editorId}`);
          editor_token.selection.save();
          editor_token.selection.restore();
          editor_token.html.insert(" {" + val + "} ");
        },
        // Callback on refresh.
        refresh: function ($btn) {
          //console.log('do refresh')
        },
        // Callback on dropdown show.
        refreshOnShow: function ($btn, $dropdown) {
          console.log("do refresh when show");
        },
      });
    }

    if (uInfo().user_id == "2" && purpose !== "quiz-content") {
      if (!setupreview) {
        FroalaEditor.DefineIcon("tkn_list2", {
          NAME: "System Forms Token",
          template: "text",
        });
        if (uInfo().user_id == "2") {
          console.log(onboradingTokendata);
          FroalaEditor.RegisterCommand("my_dropdown2", {
            icon: "tkn_list2",
            title: "System Forms Token",
            type: "dropdown",
            focus: false,
            undo: false,
            refreshAfterCallback: true,
            options: system_forms,
            callback: function (cmd, val) {
              var editor_token = new FroalaEditor(`#edit${editorId}`);
              editor_token.selection.save();
              editor_token.selection.restore();
              editor_token.html.insert(val);
            },
            // Callback on refresh.
            refresh: function ($btn) {
              //console.log('do refresh')
            },
            // Callback on dropdown show.
            refreshOnShow: function ($btn, $dropdown) {
              //console.log('do refresh when show')
            },
          });
        }
      } else {
        FroalaEditor.RegisterCommand("my_dropdown2", null);
        FroalaEditor.DefineIcon("tkn_list2", null);
      }
    }

    FroalaEditor.DefineIcon("new_image_link", {
      NAME: "upload",
      SVG_KEY: "upload",
    });
    // FroalaEditor.RegisterCommand("new_image_link", {
    //   title: "Upload Images/Videos",
    //   focus: false,
    //   undo: false,
    //   refreshAfterCallback: false,
    //   callback: function () {
    //     window.open(
    //       "/resourcesDrive/my-resources/" + bcrypt(uInfo().user_id),
    //       "_blank"
    //     );
    //   },
    // });

    FroalaEditor.DefineIcon("img_lib", { NAME: "Resources", template: "text" });
    FroalaEditor.RegisterCommand("img_lib", {
      title: "Resource Center",
      focus: true,
      undo: true,
      refreshAfterCallback: true,
      callback: function () {
        setLibObjs(this);
        open_media_library();
      },
    });

    FroalaEditor.DefineIcon("add_files", {
      NAME: "Upload Files",
      template: "text",
    });

    FroalaEditor.RegisterCommand("add_files", {
      title: "Add New Files",
      focus: false,
      undo: false,
      refreshAfterCallback: true,
      callback: function () {
        setFileUploadModal(true);
      },
    });

    // FroalaEditor.DefineIcon('upload_iframe_link_icon', { NAME: 'Embed Codes', template: 'text' })
    // FroalaEditor.RegisterCommand('upload_iframe_link', {
    //     title: 'Embed Codes',
    //     icon: 'upload_iframe_link_icon',
    //     focus: false,
    //     undo: false,
    //     refreshAfterCallback: false,
    //     callback: function () {
    //         setModal(true);
    //     }
    // });

    //-----------------------

    // const handleInsertPDF = () => {
    //   const file = prompt("Enter PDF URL");
    //   if (file) {
    //     const editor = editorRef.current.editor;
    //     editor.html.insert(`<div class="pdf-viewer" data-file="${file}"></div>`);
    //   }
    // };

    // const customButtons = {
    //   insertPDF: {
    //     title: 'Insert PDF',
    //     icon: '<i class="fa fa-file-pdf-o"></i>',
    //     callback: handleInsertPDF
    //   }
    // };

    //-----------------------

    document.getElementById(`edit${editorId}`).classList.remove("fr-view");
    // a = new FroalaEditor("#edit", {
    a = new FroalaEditor(`#edit${editorId}`, {
      key: FroalaLicenseKey,
      toolbarInline: props.inlineEditior == true ? true : false,
      toolbarSticky: true,
      initOnClick: false,
      fileUpload: false,
      videoPaste: false,
      videoUpload: true,
      fontSizeSelection: true,
      attribution: false,

      // toolbarButtons: [
      //   [
      //     "bold",
      //     "italic",
      //     "underline",
      //     "strikeThrough",
      //     "textColor",
      //     "backgroundColor",
      //     "emoticons",
      //   ],
      //   [
      //     "paragraphFormat",
      //     "fontSize",
      //     "align",
      //     "formatOL",
      //     "formatUL",
      //     "indent",
      //     "outdent",
      //   ],
      //   [
      //     "document",
      //     "insertImage",
      //     "insertLink",
      //     "insertVideo",
      //     "undo",
      //     "redo",
      //     "html",
      //     "img_lib",
      //     "my_dropdown",
      //     "my_dropdown1",
      //     "my_dropdown2",
      //     "new_image_link",
      //   ],
      // ],

      toolbarButtons: (() => {
        if (props.purpose != "quiz-content") {
          return [
            [
              "bold",
              "italic",
              "underline",
              "strikeThrough",
              "textColor",
              "backgroundColor",
              "emoticons",
            ],
            [
              "paragraphFormat",
              "fontSize",
              "align",
              "formatOL",
              "formatUL",
              "indent",
              "outdent",
            ],
            [
              "document",
              "insertImage",
              "insertFile",
              "insertLink",
              "insertVideo",
              "undo",
              "redo",
              "html",
              "img_lib",
              "add_files",
              "my_dropdown",
              "my_dropdown1",
              "my_dropdown2",
              "new_image_link",
            ],
          ];
        } else {
          return [
            [
              "bold",
              "italic",
              "underline",
              "textColor",
              "paragraphFormat",
              "fontSize",
              "align",
              "formatOL",
              "formatUL",
              "indent",
              "outdent",
              "document",
              "insertImage",
              "insertFile",
              "insertVideo",
              "undo",
              "redo",
              "img_lib",
              "add_files",
              "new_image_link",
            ],
          ];
        }
      })(),

      fileUpload: true,
      filesManagerUploadURL: `${ApiUrl}/uploadFile`, 
      filesManagerUploadMethod: "POST",
      filesManagerUploadParam: "file",
      filesManagerMaxSize: 52428800, 
      filesManagerAllowedTypes: ["*"],

      theme: themeFor ? "gray" : "",
      // Set a preloader.
      imageManagerPreloader: "",
      requestWithCORS: false,
      // Set page size.
      imageManagerPageSize: 20,

      // Set a scroll offset (value in pixels).
      imageManagerScrollOffset: 10,

      // Set the load images request URL.
      imageManagerLoadURL: `${ApiUrl}get/image/gallery`,

      // Set the load images request type.
      imageManagerLoadMethod: "GET",

      // Additional load params.
      imageManagerLoadParams: { user_id: uInfo().user_id },

      // Set the delete image request URL.
      imageManagerDeleteURL: `${ApiUrl}delete/image/gallery`,

      // Set the delete image request type.
      imageManagerDeleteMethod: "GET",

      // Additional delete params.
      imageManagerDeleteParams: { user_id: uInfo().user_id },

      imageUploadURL: `${ApiUrl}save/froala/image`,
      imageUploadParams: {
        user_id: uInfo().user_id,
        title: "Froala-editior-upload",
      },

      //Video upload start

      videoUploadParam: "file",

      // Set the video upload URL.
      videoUploadURL: `${ApiUrl}save/froala/video`,

      // Additional upload params.
      videoUploadParams: {
        user_id: uInfo().user_id,
        title: "Froala-editior-upload",
      },

      // Set request type.
      videoUploadMethod: "POST",

      // Set max video size to 50MB.
      videoMaxSize: 500 * 1024 * 1024,

      // Allow to upload MP4, WEBM and OGG
      videoAllowedTypes: ["mp4", "webm", "ogg", "jpg"],

      events: {
        "file.beforeUpload": function (files) {
          console.log("Before upload:", files);
        },
        "file.uploaded": function (response) {
          console.log("Upload success:", response);
        },
        "file.error": function (error, response) {
          console.error("Upload error:", error, response);
        },
        "imageManager.error": function (error, response) {
          // Bad link. One of the returned image links cannot be loaded.
          if (error.code == 10) {
            console.log(error.code);
          }

          // Error during request.
          else if (error.code == 11) {
            console.log(error.code);
          }

          // Missing imagesLoadURL option.
          else if (error.code == 12) {
            console.log(error.code);
          }

          // Parsing response failed.
          else if (error.code == 13) {
            console.log(error.code);
          }

          swal({
            title: error.message,
            icon: "warning",
          });
        },
        "imageManager.imagesLoaded": function (data) {
          // Do something when the request finishes with success.
          console.log("Images have been loaded.");
        },
        "imageManager.imageLoaded": function ($img) {
          // Do something when an image is loaded in the image manager
          console.log("Image has been loaded.");
        },
        "imageManager.beforeDeleteImage": function ($img) {
          // Do something before deleting an image from the image manager.
          console.log("Image will be deleted.");
        },
        "imageManager.imageDeleted": function (data) {
          // Do something after the image was deleted from the image manager.
          console.log("Image has been deleted.");
        },
        "image.beforeUpload": function (images) {
          // Return false if you want to stop the image upload.
        },
        "image.uploaded": function (response) {
          // Image was uploaded to the server.
        },
        "image.inserted": function ($img, response) {
          // Image was inserted in the editor.
        },
        "image.replaced": function ($img, response) {
          // Image was replaced in the editor.
        },
        "image.error": function (error, response) {
          // Bad link.
          if (error.code == 1) {
            console.log(error.code, "code-error");
          }

          // No link in upload response.
          else if (error.code == 2) {
            console.log(error.code, "code-error");
          }

          // Error during image upload.
          else if (error.code == 3) {
            console.log(error.code, "code-error");
          }

          // Parsing response failed.
          else if (error.code == 4) {
            console.log(error.code, "code-error");
          }

          // Image too text-large.
          else if (error.code == 5) {
            console.log(error.code, "code-error");
          }

          // Invalid image type.
          else if (error.code == 6) {
            console.log(error.code, "code-error");
          }

          // Image can be uploaded only to same domain in IE 8 and IE 9.
          else if (error.code == 7) {
            console.log(error.code, "code-error");
          }

          // Response contains the original server response to the request if available.
        },

        "video.beforeUpload": function (images) {
          // Return false if you want to stop the image upload.
        },
        "video.uploaded": function (response) {
          // Image was uploaded to the server.
        },
        "video.inserted": function ($img, response) {
          // Image was inserted in the editor.
        },
        "video.replaced": function ($img, response) {
          // Image was replaced in the editor.
        },

        initialized: function () {
          const editor = this;
          document.getElementById(`preview${editorId}`).innerHTML =
            editor.html.get();

          if (!insideForm) {
            if (typeof props.handleSubmit === "function") {
              document
                .getElementById(props.btnId)
                .addEventListener("click", function (event) {
                  props.handleSubmit(event, editor.html.get());
                });
            }
          }
        },
        contentChanged: function () {
          const editor = this;

          if (props.question_content_set) {
            props.handleQuestionContentChange(questionIndex, editor.html.get());
          }

          if (props.question_option_set) {
            props.handleQuestionOptionsContentChange(
              questionIndex,
              optionIndex,
              editor.html.get()
            );
          }

          if (!props.useranswer && props.openended_ques) {
            props.handleOpenEndedChange(
              props.useranswer,
              this.id - 1,
              editor.html.get()
            );
          }
          if (props.useranswer && props.openended_ques) {
            props.handleOpenEndedAnswerChange(
              props.useranswer,
              this.id - 1,
              editor.html.get()
            );
          }

          document.getElementById(`preview${editorId}`).innerHTML =
            editor.html.get();
        },
      },
    });
  };

  useEffect(() => {
    getcoachdata();
  }, []);

  useEffect(() => {
    fetchDriveResource(0);
  }, [type]);

  function open_media_library(search = "") {
    fetchDriveResource(0);
    setModal2(true);
  }

  const fetchDriveResource = (id, search = "") => {
    fetch(`${ApiUrl}` + "get/drive/lists", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({
        type: type,
        user_id: uInfo().user_id,
        idx: id,
        search: search,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setLists(response.data.records);
          setFolderId(response.data.folder_id);
          setBreadcrumbs(response.data.breadcrumbs);
          setCurrentPage(1);
        } else {
          swal({
            title: response.message,
            icon: "warning",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  function selectGalleryImage(row, type = "link") {
    var dynamic_froala_element_id = new FroalaEditor(
      "#" + libObjs.$oel.get(0).id
    );

    let iframeCode = "";

    if (row.file_type.match("image.*")) {
      iframeCode = '<img src="' + row.url + '" class="fr-fic fr-dib fr-fil">';
    } else if (row.file_type.match("video.*")) {
      let url_video = `${HostUrl}/video/videostatic/` + bcrypt(row.url);
      iframeCode =
        '<span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable"><iframe src="' +
        url_video +
        '" controls="" class="fr-draggable"></iframe></span>';

      // iframeCode = '<span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable"><video src="' + row.url + '" controls="" class="fr-draggable">Your browser does not support HTML5 video.</video></span>';
    } else if (row.file_type.match("audio.*")) {
      let url_audio = `${HostUrl}/audio/audiostatic/` + bcrypt(row.url);
      iframeCode =
        '<span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable"><iframe src="' +
        url_audio +
        '" controls="" class="fr-draggable"></iframe></span>';
      // iframeCode = '<span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable"><audio src="' + row.url + '" controls="" class="fr-draggable">Your browser does not support HTML5 video.</audio></span>';
    } else if (row.file_type.match("pdf.*")) {
      // iframeCode = '<span contenteditable="false" draggable="true" class="fr-video fr-dvb fr-draggable"><embed src="' + row.url + '#toolbar=0" type="application/pdf" width="100%" /></span>';
      let url_pdf = `${HostUrl}/pdf/pdfstatic/` + bcrypt(row.url);
      iframeCode =
        '<span contenteditable="false" draggable="true"  class="fr-video fr-dvb fr-draggable"><iframe src="' +
        url_pdf +
        '" scrolling="no" type="application/pdf" style="width:100%; height:1024px;" ></iframe></span>';
    } else if (
      row.file_type.match("openxmlformats") ||
      row.file_type.match("application/msword") ||
      row.file_type.match("openxmlformats") ||
      row.file_type.match("ms-powerpoint") ||
      row.file_type.match("csv") ||
      row.file_type.match("ms-excel") ||
      row.file_type.match("xlsx")
    ) {
      let url_ms = `${HostUrl}/ms/msofficestatic/` + bcrypt(row.url);
      iframeCode =
        '<span contenteditable="false" draggable="true"  class="fr-video fr-dvb fr-draggable"><iframe  src="' +
        url_ms +
        '" scrolling="no" style="width:100%; height:1024px;" ></iframe></span>';
    } else {
      iframeCode =
        '<span class="fr-video fr-deletable fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true"><iframe  height="315" src="https://docs.google.com/viewer?url=' +
        row.url +
        '&embedded=true" title="' +
        row.name +
        '" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></span>';
    }

    if (type == "link") {
      dynamic_froala_element_id.html.insert(
        '<a rel="noopener noreferrer" target="_blank" href="' +
          row.url +
          '">' +
          row.name +
          "</a>",
        true
      );
    } else {
      dynamic_froala_element_id.html.insert(iframeCode, true);
    }

    // dynamic_froala_element_id.html.insert('<img src="'+img_url+'" class="fr-fic fr-dib fr-fil">',true);

    // var iframeCode = '<span class="fr-video fr-deletable fr-fvc fr-dvb fr-draggable" contenteditable="false" draggable="true"><iframe width="560" height="315" src="' + img_url + '" title="YouTube video player" frameborder="0" allowfullscreen="" class="fr-draggable"></iframe></span>';
    // dynamic_froala_element_id.html.insert(iframeCode);

    $("#" + libObjs.$oel.get(0).id).val(dynamic_froala_element_id.html.get());
    setModal2(false);
    setLibObjs("");
  }

  const getcoachdata = () => {
    fetch(`${ApiUrl}` + "get/tokens/lists/" + uInfo().user_id, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
      body: JSON.stringify({ id: uInfo().user_id }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          // if (props.use_token == 'users') {
          //     editior(response.data.user_token);
          // }

          // if (props.use_token == 'client') {
          //     editior(response.data.clients_token);
          // }
          editior(
            response.data.user_token,
            response.data.clients_token,
            response.data.system_forms_token
          );
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const makeIframe = () => {
    if (link != "") {
      var iframe = document.createElement("iframe");
      iframe.style = "height:" + 360 + "px; width:100%; border:none";
      iframe.setAttribute("allowTransparency", true);
      iframe.src = link;

      navigator.clipboard.writeText(
        `<iframe allowtransparency="true" src="${link}" style="height: 360px; width: 100%; border: none;"></iframe>`
      );

      alert("copied");
    } else {
      alert("Link is required to generate the code");
    }
  };

  const FileAction = (object) => {
    setSubAction({});
    setTimeout(() => {
      object.ext = object.url.split(".").pop();
      setSubAction(object);
      setpage2Class("slide-in");
    }, 100);
  };

  const closeFileActionModal = (folderId = "") => {
    setFileUploadModal(false);
    if (folderId != "") {
      fetchDriveResource(folderId);
    }
    // setAction(0);
    // setActionModal(false);
    // setSubFileAction({});

    // setSubAction({});
    // setInfo({
    //     record: {},
    //     shareUser: [],
    // });
    // setUsers([]);
    // setSubActionModal(false);
    // setSubFileActionModal(false);

    // setShare(false);
    // setpage2Class("slide-out2");
  };

  const itemsPerPage = 8;
  const paginationRange = 5;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const itemsToShow = lists.files.slice(startIndex, endIndex);

  const totalPages = Math.ceil(lists.files.length / itemsPerPage);

  const paginationNumbers = [];
  const halfRange = Math.floor(paginationRange / 2);
  let startRange = currentPage - halfRange;
  let endRange = currentPage + halfRange;

  if (totalPages <= paginationRange) {
    startRange = 1;
    endRange = totalPages;
  } else {
    if (startRange < 1) {
      endRange += Math.abs(startRange) + 1;
      startRange = 1;
    } else if (endRange > totalPages) {
      startRange -= endRange - totalPages;
      endRange = totalPages;
    }
  }

  for (let i = startRange; i <= endRange; i++) {
    paginationNumbers.push(i);
  }

  return (
    <>
      <div className="froala">
        {/* <div id="buttons">
                <button className='btn btn-link' title='click here to preview' onClick={() => setPreview(!preview)}><i className='fas fa-eye'></i></button>
            </div> */}

        {!insideForm && (
          <div
            id={`editor${editorId}`}
            className={`${!preview ? "" : "d-none"}`}
          >
            <div id={`edit${editorId}`}>
              <div dangerouslySetInnerHTML={{ __html: props.content }} />
            </div>
          </div>
        )}

        {insideForm && (
          <textarea
            name={nameKey}
            id={`edit${editorId}`}
            value={props.content}
          ></textarea>
        )}

        <div
          id={`preview${editorId}`}
          className={`${preview ? "" : "d-none"}`}
        ></div>

        <Modal
          isOpen={modal}
          fade={true}
          className=""
          centered={true}
          toggle={() => {
            setModal(false);
            setLink("");
          }}
        >
          <ModalHeader>
            <div className="row">
              <div className={`mb-4`}>
                <h4 className="card-title">Embed Codes</h4>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="from-group">
              <input
                type={"text"}
                placeholder={"Enter url to generate the code"}
                onChange={(e) => setLink(e.target.value)}
                className="form-control"
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <button
              className="btn btn-info btn-sm"
              onClick={() => {
                setModal(false);
                setLink("");
              }}
            >
              Close
            </button>

            <button
              className="btn btn-primary btn-sm"
              onClick={() => makeIframe()}
            >
              Copy Code
            </button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={modal2}
          fade={true}
          className="MediaPopup"
          centered={true}
          toggle={() => {
            setModal2(false);
          }}
          size="lg"
        >
          {/* <ModalHeader className='py-2'>
                    <div className="row">
                        <div className={``}>
                            <h4 className="card-title">
                                Embed Codes
                            </h4>
                        </div>
                    </div>
                </ModalHeader> */}
          <ModalBody className="pt-0">
            <div className="ModalHeader d-flex">
              <ul className="mb-2 nav nav-tabs w-50">
                <li className="nav-item">
                  <button
                    onClick={() => setType("my-resources")}
                    type="button"
                    className={`nav-link ${type == "my-resources" && "active"}`}
                  >
                    My Resources
                  </button>
                </li>
                <li className="nav-item">
                  <button
                    onClick={() => setType("shared-with-me")}
                    type="button"
                    className={`nav-link ${
                      type == "shared-with-me" && "active"
                    }`}
                  >
                    Share With me
                  </button>
                </li>
              </ul>
              <ul className="mb-2 nav nav-tabs w-50 text-end d-flex justify-content-end">
                <li className="nav-item">
                  <button
                    className="btn btn-link fs-4 p-0"
                    onClick={() => {
                      setModal2(false);
                    }}
                  >
                    <i className="mdi mdi-close-thick"></i>
                  </button>
                </li>
              </ul>
            </div>

            <div className="w-100 mt-2 editior-modal-drive">
              <ol className="breadcrumb">
                {breadcrumbs.map((row, i) => {
                  if (breadcrumbs.length == i + 1) {
                    return (
                      <li
                        key={i}
                        className="breadcrumb-item active d-flex align-items-center"
                      >
                        <button className="btn btn-sm btn-link" disabled>
                          {row.name}
                        </button>
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={i}
                        className="breadcrumb-item d-flex align-items-center"
                      >
                        <button
                          className="btn btn-sm btn-link"
                          onClick={() => fetchDriveResource(row.id)}
                        >
                          {row.name}
                        </button>
                      </li>
                    );
                  }
                })}
              </ol>
              <div className="d-flex">
                <div className="form-group mb-2 w-100">
                  <input
                    className="form-control"
                    type="text"
                    id="myInput"
                    onKeyUp={(e) =>
                      fetchDriveResource(folderId, e.target.value)
                    }
                    placeholder="Search by titles or keywords"
                    title="search by titles or keywords"
                  />
                </div>

                {type == "my-resources" && (
                  <div className="drive-action-button">
                    <button
                      title="Add new files"
                      className="btn text-dark"
                      onClick={(e) => {
                        e.preventDefault();
                        setFileUploadModal(true);
                      }}
                    >
                      <i className="fas fa-file-upload"></i>
                    </button>
                  </div>
                )}
              </div>

              <div className="folders ">
                <div className="row w-100">
                  {lists.folders.map((row, i) => {
                    return (
                      <div className="col-lg-3" key={i}>
                        <button
                          className="btn btn-md p-0 mb-1 w-100"
                          onClick={() => fetchDriveResource(row.id)}
                          key={i}
                        >
                          <div className="folder-editor d-flex align-items-center bg-light px-2 py-1">
                            <i className="fas fa-folder me-2"></i>
                            <p title={row.name}>{row.name}</p>
                          </div>
                        </button>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="folders">
                <div className="row w-100">
                  {itemsToShow.map((row, i) => {
                    return (
                      <div className="col-lg-3" key={i}>
                        <div className="folders p-2 px-0">
                          <div className="d-flex justify-content-between px-2 pb-1"></div>
                          <div className="editor-image-buttons position-relative">
                            <div className="icon-class-design d-flex align-items-center">
                              {row.file_type.match("image.*") ? (
                                <img
                                  src={row.url}
                                  className="img img-thumbnail"
                                />
                              ) : row.file_type.match("video.*") ? (
                                <div className="f-video-preview">
                                  <ReactPlayer
                                    // light={true}
                                    width="100%"
                                    height={"151px"}
                                    url={row.url}
                                    playing={false}
                                    onError={(e) => console.log("onError", e)}
                                    onStart={() => console.log("onStart")}
                                    controls={false}
                                  />
                                  {/* <i className="fab fa-youtube"></i> */}
                                </div>
                              ) : row.file_type.match("audio.*") ? (
                                <i className="far fa-file-audio"></i>
                              ) : row.file_type.match("pdf") ? (
                                <i className="far fa-file-pdf"></i>
                              ) : row.file_type.match("ms-powerpoint") ? (
                                <i className="fas fa-file-powerpoint"></i>
                              ) : row.file_type.match("csv") ||
                                row.file_type.match("ms-excel") ||
                                row.file_type.match("xlsx") ? (
                                <i className="far fa-file-excel"></i>
                              ) : row.file_type.match("openxmlformats") ||
                                row.file_type.match("application/msword") ? (
                                <i className="far fa-file-word"></i>
                              ) : (
                                <i className="far fa-file"></i>
                              )}
                            </div>
                            <div className="action-editior-button">
                              <div className="info ">
                                <p>{row.name}</p>
                              </div>
                              <button
                                onClick={() => selectGalleryImage(row, "link")}
                                title="Insert as dowload link"
                                className="btn btn-sm btn-rounded btn-danger"
                              >
                                <i className="fas fa-link"></i>
                              </button>
                              {(row.file_type.match("image.*") ||
                                row.file_type.match("video.*") ||
                                row.file_type.match("audio.*") ||
                                row.file_type.match("pdf") ||
                                row.file_type.match("application/msword") ||
                                row.file_type.match("openxmlformats") ||
                                row.file_type.match("ms-powerpoint") ||
                                row.file_type.match("csv") ||
                                row.file_type.match("ms-excel") ||
                                row.file_type.match("xlsx")) && (
                                <>
                                  <button
                                    onClick={() =>
                                      selectGalleryImage(row, "embed")
                                    }
                                    title="Insert as file"
                                    className="btn btn-sm btn-rounded btn-primary ml-3"
                                  >
                                    <i className="fas fa-photo-video"></i>
                                  </button>
                                  <button
                                    onClick={() => FileAction(row)}
                                    title="Click here to view"
                                    className="btn btn-sm btn-rounded btn-primary ml-3"
                                  >
                                    <i className="fas fa-eye"></i>
                                  </button>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div
                  className={`pagenation d-flex justify-content-center mt-2 ${
                    itemsToShow.length < 1 && "d-none"
                  }`}
                >
                  <button
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className="page-link"
                  >
                    Previous
                  </button>
                  {paginationNumbers.map((pageNumber) => (
                    <button
                      key={pageNumber}
                      onClick={() => setCurrentPage(pageNumber)}
                      className={
                        currentPage === pageNumber
                          ? "active page-link"
                          : "page-link"
                      }
                    >
                      {pageNumber}
                    </button>
                  ))}
                  <button
                    disabled={currentPage === totalPages}
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className="page-link"
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
          </ModalBody>

          {/* <ModalFooter>
                    <button className='btn btn-info' onClick={() => { setModal2(false); }}>Close</button>
                </ModalFooter> */}
        </Modal>

        {/* FILE UPLOAD MODAL */}
        <Modal
          isOpen={fileUploadModal}
          fade={true}
          size="md"
          className="DriveModal"
          toggle={() => closeFileActionModal(folderId)}
          centered={true}
          backdrop={"static"}
        >
          <div className="p-2">
            <div className="">
              <div className="modal-header p-0">
                <div className="modal-title pl-3">
                  <h5>Upload File</h5>
                </div>
                <button
                  onClick={() => closeFileActionModal(folderId)}
                  className="mb-3 btn btn-link pull-right BtnCenterClose"
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
              <UploadFiles
                userId={uInfo().user_id}
                folderId={folderId}
                // closeFileModal={closeFileActionModal}
              />
            </div>
          </div>
        </Modal>
        {/* FILE UPLOAD MODAL */}
      </div>

      <div
        className={`page3 ${page2Class} custom-scroll froala-editor-view-file`}
      >
        <div>
          <div className="modal-head top-heading">
            <h2> {subAction.name} </h2>
            <button
              onClick={() => {
                setSubAction({});
                setpage2Class("slide-out2");
              }}
              type="button"
              className="btn btn-link text-white"
            >
              <i className="fas fa-times"> </i>
            </button>
          </div>
          <div className="modal-body">
            <div className="h_iframe">
              {subAction?.file_type?.match("image.*") ? (
                <img src={subAction.url} className="img img-thumbnail" />
              ) : subAction?.file_type?.match("video.*") ? (
                <video width={"100%"} height={"100%"} controls="controls">
                  <source src={subAction.url} />
                  Your browser does not support the HTML5 Video element.
                </video>
              ) : subAction?.file_type?.match("audio.*") ? (
                <audio width={"100%"} height={"100%"} controls="controls">
                  <source src={subAction.url} />
                  Your browser does not support the HTML5 Audio element.
                </audio>
              ) : (
                // <embed src={subAction.url} type={subAction?.file_type} width="100%" height="600px" />
                <iframe
                  src={`https://docs.google.com/viewer?url=${subAction.url}&embedded=true#toolbar=0`}
                ></iframe>
              )}
              {/* {
                                subAction?.file_type?.match('image.*') || subAction?.file_type?.match('video.*') ? (
                                    <div className="responsive-iframe">
                                        <iframe src={`${subAction.url}`} width="640" height="100%" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
                                    </div>
                                ) : (
                                    <iframe src={`https://docs.google.com/viewer?url=${subAction.url}&embedded=true`}></iframe>
                                )
                            } */}
              {/* <iframe src={`https://view.officeapps.live.com/op/embed.aspx?src=${subAction.url}`}></iframe> */}
              {/* <iframe src={subAction.url} frameborder="0" allowfullscreen></iframe> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Froala;
