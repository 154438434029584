import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { bcrypt, hasRole, uInfo, uRole } from "../../useToken";

const ProfileSettings = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    // console.log(window.location,'loc')
    const pathName = window.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [window.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%" }} ref={ref}>
        <div id="sidebar-menu" className="CoachSidebar">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/settings/profile" className="">
                {/* <i className="ion ion-md-settings"></i> */}
                <span>{"Profile"}</span>
              </Link>
            </li>

            {hasRole(uInfo(), ["team"]) && (
              <li>
                <Link to="/settings/coachpartners" className="">
                  {/* <i className="ion ion-md-settings"></i> */}
                  <span>{"Team"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["settings_integration"]) && (
              <li>
                <Link to="/settings/integrations" className="">
                  <span>{"Integrations"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["credits"]) && (
              <li>
                <Link to="/settings/credits" className="">
                  <span>{"Credits"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["calendar"]) && (
              <li>
                <Link to="/settings/calendar" className="">
                  <span>{"Calendar"}</span>
                </Link>
              </li>
            )}
             {/* {hasRole(uInfo(), ["calendar"]) && (
              <li>
                <Link to="/scheduler-editor" className="">
                  <i className="ion ion-md-settings"></i>
                  <span>{"Scheduler"}</span>
                </Link>
              </li>
            )} */}
            {hasRole(uInfo(), ["notifications"]) && (
              <li>
                <Link to="/settings/notifications" className="">
                  <span>{"Notifications"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["theme_settings"]) && (
              <li>
                <Link to="/settings/themes" className="dropdown-item">
                  {"Theme Setting"}
                </Link>
              </li>
            )}
           
            {hasRole(uInfo(), ["phone_numbers"]) && (
              <li>
                <Link to="/settings/numbers/list" className="">
                  {/* <i className="ion ion-md-settings"></i> */}
                  <span>{"Phone Numbers"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["billing"]) && (
              <li>
                <Link to="/settings/billing" className="">
                  {/* <i className="ion ion-md-settings"></i> */}
                  <span>{"Billing"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["transaction_log"]) && (
              <li>
                <Link to="/settings/transactionlog" className="">
                  {/* <i className="ion ion-md-settings"></i> */}
                  <span>{"Transaction Logs"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["payment"]) && (
              <li>
                <Link to="/settings/payment" className="">
                  {/* <i className="ion ion-md-settings"></i> */}
                  {"Payment"}
                </Link>
              </li>
            )}

            {hasRole(uInfo(), ["global_constraint_setting"]) && (
              <li>
                <Link to="/settings/global" className="">
                  {/* <i className="ion ion-md-settings"></i> */}
                  <span>{"Global Setting"}</span>
                </Link>
              </li>
            )}
            {hasRole(uInfo(), ["api"]) && (
              <li>
                <Link to="/settings/custom/api" className="">
                  {/* <i className="ion ion-md-cloud-outline"></i> */}
                  {"Api"}
                </Link>
              </li>
            )}
            {/* <div>
              {hasRole(uInfo(), ["website_builder"]) && (
                uInfo().domain_status == 1 ? (
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span>Website Builder</span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      <li>
                        <Link to="/branding">Branding </Link>
                      </li>
                      <li>
                        <Link to="/manage-contents">Manage contents</Link>
                      </li>
                      <li>
                        <Link to="/manage-products">Manage products</Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <li>
                    <Link to="/build/website" className="">
                      {"Build Website"}
                    </Link>
                  </li>
                )
              )}
            </div> */}
            <div>
              {hasRole(uInfo(), ["website_builder"]) &&
                (uInfo().domain_status == 1 ? (
                  <li>
                    <Link to="/#" className="has-arrow waves-effect">
                      <span>Website Builder </span>
                    </Link>
                    <ul className="sub-menu" aria-expanded="false">
                      {/* <li>
                        <Link to={`/website/build/${bcrypt(uInfo().user_id)}`}>Website Wizard</Link>
                      </li> */}
                      {hasRole(uInfo(), ["blogs"]) && (
                      <li>
                        <Link to="/manage-blogs">Blogs</Link>
                      </li>
                      )}
                      {hasRole(uInfo(), ["landing_page_settings"]) && (
                      <li>
                        <Link to="/landing-page-setting">
                          Landing Page Setting
                        </Link>
                      </li>
                      )}
                      {hasRole(uInfo(), ["book_download"]) && (
                      <li>
                        <Link to="/book-contents">
                          Book(Pathway To Profit - W Graphics)
                        </Link>
                      </li>
                      )}
                      {hasRole(uInfo(), ["blocked_ip"]) && (
                      <li>
                        <Link to="/blocked/ip">Blocked IP</Link>
                      </li>
                      )}
                    </ul>
                  </li>
                ) : (
                  <li>
                    <Link
                      to={`/website/build/${bcrypt(uInfo().user_id)}`}
                      className=""
                    >
                      Website Builder <i class="fas fa-arrow-up"></i>
                    </Link>
                  </li>
                ))}
            </div>

           
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

export default ProfileSettings;
