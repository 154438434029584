import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  CardTitle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardText,
  CardHeader,
  Container,
} from "reactstrap";

import Helmet from "react-helmet";

import { toast, ToastContainer, Flip } from "react-toastify";

import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import moment from "moment";
import { ApiUrl, WebUrl } from "../../config";
import {
  Dropdown,
  DropdownButton,
  FormControl,
  ListGroup,
  Modal,
} from "react-bootstrap";
import {
  Asia,
  TimezoneMain,
  America,
  Australia,
  Europe,
  Indian,
} from "../../common/data/timezones";
import $ from "jquery";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Loader from "react-loader";
import swal from "sweetalert";

const MeetingCalander = (props) => {
  const [page_name] = useState("Meet");
  const [info, setInfo] = useState({});
  const [hiddenDays, setHiddenDays] = useState([]);
  const [pickDate, setPickDate] = useState(moment().format("YYYY-MM-DD"));
  const [slots, setSlots] = useState([]);
  const [modal, setModal] = useState(false);
  const [main, setMain] = useState(
    localStorage.getItem("selectedMain") || "America"
  );
  const [zone, setZone] = useState(
    localStorage.getItem("selectedZone") || "America/Los_Angeles"
  );
  const [zoneLabel, setZoneLabel] = useState(
    localStorage.getItem("selectedZoneLabel") || "Pacific Standard Time (PST)"
  );

  const [Timezone, setMainTimeZone] = useState(TimezoneMain);
  const [zones, setZones] = useState(America);

  const calendarRef = React.createRef();

  const [step, setStep] = useState("1");
  const [slot, setSlot] = useState("");

  const [form, setForm] = useState("");
  const [loaded, setLoaded] = useState(true);
  const handleDateClick = (arg) => {
    // bind with an arrow function
    // alert(arg.dateStr)
  };

  useEffect(() => {
    getData();
    console.log(zoneLabel, "zone_lable");
  }, [zone]);

  // useEffect(() => {
  //   if (info.booking_id) {
  //     window.location.href = `${WebUrl}/schedule?config_id=${info.booking_id}`;
  //   }
  // }, [info]);

  useEffect(() => {
    getData();
  }, [pickDate]);

  useEffect(() => {
    $("[data-date=" + moment().format("YYYY-MM-DD") + "]").addClass(
      "selectedDate"
    );
  }, []);

  const getData = () => {
    setLoaded(false);
    fetch(`${ApiUrl}` + "get/calander/link", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        link: window.location.href,
        date: pickDate,
        timezone: zone,
      }),
    })
      .then((response) => response.json())
      //Then with the data from the response in JSON...
      .then((response) => {
        if (response.status === true) {
          setInfo(response.data.record);
          setHiddenDays(response.data.off_days);
          setSlots(response.data.slots);
          setForm(response.data.schedule_form);
          console.log(zone, "zonezone");
          setZoneLabel(() => {
            if (main === "America") {
              const foundZone = America.find((item) => item.id === zone);
              return foundZone ? foundZone.label : zone;
            }
            return zone;
          });
          setLoaded(true);
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
          setLoaded(true);
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul
            className="list-unstyled"
            style={{ maxHeight: "250px", overflow: "auto" }}
          >
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const changeMainZone = (zone) => {
    if (zone == "Asia") {
      setZones(Asia);
      setZone(Asia[0].id);
      setZoneLabel(Asia[0].id);
    } else if (zone == "America") {
      setZones(America);
      setZone(America[0].id);
      setZoneLabel(America[0].label);
    } else if (zone == "Australia") {
      setZones(Australia);
      setZone(Australia[0].id);
      setZoneLabel(Australia[0].id);
    } else if (zone == "Europe") {
      setZone(Europe[0].id);
      setZoneLabel(Europe[0].id);
      setZones(Europe);
    } else if (zone == "Indian") {
      setZone(Indian[0].id);
      setZoneLabel(Indian[0].id);
      setZones(Indian);
    }
    setMain(zone);
    localStorage.setItem("selectedMain", zone);
    localStorage.setItem("selectedZone", zones[0].id);
    localStorage.setItem("selectedZoneLabel", zones[0].label);
  };

  const handleZoneChange = (zoneId, zoneLabel) => {
    setZone(zoneId);
    setZoneLabel(zoneLabel);
    localStorage.setItem("selectedZone", zoneId);
    localStorage.setItem("selectedZoneLabel", zoneLabel);
  };

  const handleSubmit = async (event, values) => {
    setLoaded(false);
    values.coach_id = info.coach_id;
    values.coach_partner_id = info.coach_partner_id;
    values.group_id = [info.group_id];
    values.calendar_id = info.calendar_id;
    values.event_title = info.event_title;
    values.location = info.location;
    values.client_time_zone = zone;
    values.pick_date = pickDate;
    values.from_time = slot;
    values.duration = info.duration;
    values.schedule_confirmation_email = info.automation_settings
      .confirmation_email
      ? "Y"
      : "N";
    values.pre_meeting_reminder = info.automation_settings.reminder ? "Y" : "N";

    fetch(`${ApiUrl}` + "submit/metting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === true) {
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls success' });
          swal({
            title: data.message,
            icon: "success",
          });
          setTimeout(() => setStep("1"), 500);
          getData();
          setLoaded(true);
        } else {
          setLoaded(true);
          swal({
            title: data.message,
            icon: "warning",
          });
          // toast(data.message, { hideProgressBar: true, position: "top-center", transition: Flip, className: 'toast-custom-cls error' });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{page_name}</title>
      </Helmet>
      <Container className="cal-custom custom-calander mb-4">
        <ToastContainer />
        <Loader loaded={loaded}></Loader>
        {/* <Row className="calander-meetings">
          <div className="col-lg-10 offset-lg-1">
           
            <div className="CustomShadow"> */}

        {info.booking_id && (
          <iframe
            src={`${WebUrl}/nylas/public/scheduling?config_id=${info.booking_id}`}
            // src={`${WebUrl}/schedule?config_id=${info.booking_id}`}
            width="100%"
            height="700px"
          ></iframe>
        )}

        {/* </div>
          </div>
        </Row> */}

        <Modal show={modal} backdrop={"static"}>
          <Modal.Header>
            <Modal.Title>Main Timezone</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="d-flex space-between">
              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className="btn btn-primary"
                >
                  {main == "" ? "Your Timezone" : main}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {Timezone.map((zone, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => changeMainZone(zone)}
                        eventKey={i}
                      >
                        {zone}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>

              <Dropdown>
                <Dropdown.Toggle
                  id="dropdown-autoclose-true"
                  className="btn btn-primary"
                >
                  {zoneLabel == "" ? "Your Timezone" : zoneLabel}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  {zones.map((zone, i) => {
                    return (
                      <Dropdown.Item
                        onClick={() => handleZoneChange(zone.id, zone.label)}
                        eventKey={i}
                      >
                        {zone.label}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button color="info" onClick={() => setModal(false)}>
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default MeetingCalander;
