import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
} from "reactstrap";
import DefaultCalendarCreate from "../../Meeting/defaultCalendarCreate.js";


export default class NotificaionSetting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      from: this.props.from ? this.props.from : "",
    };
   
  }


  render() {
    return (
      <div className="mt-1">
        <div className="d-flex align-items-center gap-2 StepHeading">
          <div className="StepNumber">
            41 <i className="fas fa-arrow-right"></i>
          </div>{" "}
          <h3 className="m-0">Default Calendar Settings</h3>
        </div>
        <Row>
          <Col lg="12 my-3">
            <Row>
             <DefaultCalendarCreate />
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
