import React, { Component, Fragment } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  Button,
  Modal,
  ModalHeader,
  Input,
  ModalBody,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import {
  Dropdown,
  DropdownButton,
  FormControl,
  ListGroup,
} from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import googleCalendar from "../../assets/images/google-calendar.png";
import meetGoogle from "../../assets/images/meet.png";
import outlookTeams from "../../assets/images/outlook.png";
import zoom_icon from "../../assets/images/zoom.png";
// import zoomIcon from "../../assets/images/zoomus-icon.svg";
import Loader from "react-loader";
import Select from "react-select";
import Helmet from "react-helmet";
import {
  AvForm,
  AvField,
  AvInput,
  AvRadioGroup,
  AvRadio,
  AvCheckboxGroup,
  AvCheckbox,
  AvGroup,
  AvFeedback,
} from "availity-reactstrap-validation";

import {
  currentDateTime,
  dcrypt,
  bcrypt,
  getPages,
  getPermission,
  getRoles,
  isAllowed,
  uInfo,
  uRole,
  uToken,
} from "../../useToken";
import {
  Asia,
  TimezoneMain,
  America,
  Australia,
  Europe,
  Indian,
} from "../../common/data/timezones";
import { ApiUrl, ProductName, ToastAutoClose, WebUrl } from "../../config";
import { toast, ToastContainer, Flip } from "react-toastify";
import { Link } from "react-router-dom";

import moment from "moment";
import swal from "sweetalert";

export default class MettingCreate extends Component {
  constructor(props) {
    super(props);
    const query = new URLSearchParams(props.location.search);
    this.state = {
      id: this.props.match.params.id
        ? dcrypt(this.props.match.params.id)
        : null,
      email_new: "",
      nylas_provider: "",
      has_zoom: false,
      page_name: this.props.match.params.id ? "Edit Event" : "Create Event",
      from: this.props.from ? this.props.from : "",
      loaded: true,
      records: [],
      calendars: [],
      selected_calendar_id: "",
      nylas_calendar_name: "",
      firstRecord: {},
      memberData: [],
      selectedMembers: [],
      selectedParticipants: [],
      zonesModal: false,
      main: localStorage.getItem("selectedMain") || "America",
      zone: localStorage.getItem("selectedZone") || "America/Los_Angeles",
      zoneLabel:
        localStorage.getItem("selectedZoneLabel") ||
        "Pacific Standard Time (PST)",
      Timezone: TimezoneMain,
      zones: America,
      value: "",
      eventTitle: "",
      record: {
        add_lead: "yes",
        meeting_type: "one-on-one",
        internal_name: "",
        event_title: "",
        location: "",
        location_type: "None",
        meeting_provider: "",
        description: "",
        permission: "",
        group_id: "",
        duration: "15",
        calendar_url: `${window.location.origin}/meet/${moment()
          .toDate()
          .getTime()}/${uInfo().name.replaceAll(" ", "_").toLowerCase()}`,
      },
      scheduling: {
        title: "Sample Calendar",
        scheduling_method: "",
        main_region: localStorage.getItem("selectedMain") || "America",
        sub_region:
          localStorage.getItem("selectedZone") || "America/Los_Angeles",
        minutes_before_event: 15,
        email_subject: "",
        custom_email: "",
        custom_email_body: "",
        reminders: [],
        WorkingDays: [
          {
            key: "1",
            day: "Mon",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "2",
            day: "Tue",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "3",
            day: "Wed",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "4",
            day: "Thu",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "5",
            day: "Fri",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "on",
          },
          {
            key: "6",
            day: "Sat",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "off",
          },
          {
            key: "7",
            day: "Sun",
            start: "9:00 AM",
            end: "6:00 PM",
            available: "off",
          },
        ],
      },
      scheduling_forms: [
        // {
        //   key: "first_name",
        //   label: "First Name",
        //   alternative_text: "",
        //   required: true,
        // },
        {
          key: "name",
          label: "Name",
          alternative_text: "",
          required: true,
        },
        {
          key: "email",
          label: "Email",
          alternative_text: "",
          required: true,
        },
        {
          key: "phone_number",
          label: "Phone Number",
          alternative_text: "",
          required: true,
        },
      ],

      automation: {
        confirmation_email: false,
        confirmation_sms: false,
        reminder: false,
        reminders: [
          {
            interval: "1",
            type: "day_before",
          },
        ],
      },
      groups: [],
      permissions: [],
      tag_id: [],
      location_type: "None",
      minutes: "Minutes",
      tab: query.get("tab") ? query.get("tab") : "1",
      tokens: [],
      warningAlert: false,
    };

    this.addField = this.addField.bind(this);
    this.assignData = this.assignData.bind(this);
    this.handleMemberChange = this.handleMemberChange.bind(this);
  }

  componentDidMount() {
    this.state.id != null && this.getData();
    this.state.id != null && this.getSycnCalendarData(this.state.id);
    this.assignData();
    this.getFormData();
    this.getAuthEmail();
  }

  handleSelectChange = (e) => {
    console.log("conferencing ");
    let locationType = "None";

    if (
      e.target.value == "google" ||
      e.target.value == "microsoft" ||
      e.target.value == "zoom"
    ) {
      locationType = "conference_meeting";
    } else if (e.target.value == "None") {
      locationType = "None";
    } else {
      locationType = "custom";
    }

    console.log("location", locationType);
    console.log("e", e.target.value);
    this.setState({
      record: {
        ...this.state.record,
        location_type: locationType,
        meeting_provider: e.target.value,
      },
    });
  };

  handleSelectDurationChange = (e) => {
    this.setState({
      record: {
        ...this.state.record,
        duration: e.target.value,
      },
    });
  };

  handleScheduleMethod = (e) => {
    this.setState({
      scheduling: {
        ...this.state.scheduling,
        scheduling_method: e.target.value,
      },
    });
  };

  CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={(e) => this.setState({ value: e.target.value })}
            value={this.state.value}
          />
          <ul
            className="list-unstyled"
            style={{ maxHeight: "250px", overflow: "auto" }}
          >
            {React.Children.toArray(children).filter(
              (child) =>
                !this.state.value ||
                child.props.children.toLowerCase().startsWith(this.state.value)
            )}
          </ul>
        </div>
      );
    }
  );

  handleZoneChange = (zoneId, zoneLabel) => {
    this.setState({
      zone: zoneId,
      zoneLabel: zoneLabel,
      scheduling: {
        ...this.state.scheduling,
        sub_region: zoneId,
      },
    });

    // localStorage.setItem("selectedZone", zoneId);
    // localStorage.setItem("selectedZoneLabel", zoneLabel);
  };

  changeMainZone = (zone) => {
    let selectedZone;
    let selectedZoneLabel;
    let selectedZones;

    if (zone === "Asia") {
      selectedZones = Asia;
      selectedZone = Asia[0].id;
      selectedZoneLabel = Asia[0].label;
    } else if (zone === "America") {
      selectedZones = America;
      selectedZone = America[0].id;
      selectedZoneLabel = America[0].label;
    } else if (zone === "Australia") {
      selectedZones = Australia;
      selectedZone = Australia[0].id;
      selectedZoneLabel = Australia[0].label;
    } else if (zone === "Europe") {
      selectedZones = Europe;
      selectedZone = Europe[0].id;
      selectedZoneLabel = Europe[0].label;
    } else if (zone === "Indian") {
      selectedZones = Indian;
      selectedZone = Indian[0].id;
      selectedZoneLabel = Indian[0].label;
    }

    this.setState({
      zones: selectedZones,
      zone: selectedZone,
      zoneLabel: selectedZoneLabel,
      main: zone,
      scheduling: {
        ...this.state.scheduling,
        main_region: zone,
      },
    });

    // Set the values in localStorage
    // localStorage.setItem("selectedMain", zone);
    // localStorage.setItem("selectedZone", selectedZone);
    // localStorage.setItem("selectedZoneLabel", selectedZoneLabel);
  };

  getFormData = () => {
    fetch(`${ApiUrl}` + "get/meeting/formdata", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          if (
            response.data.default_availability &&
            response.data.default_availability.WorkingDays.length > 0 &&
            this.state.id == null
          ) {
            console.log("shd set default or not", this.state.id);

            let selectedZoneLabel;
            let selectedZones;
            let main_region = response.data.default_availability.main_region;
            let sub_region = response.data.default_availability.sub_region;

            if (main_region === "Asia") {
              selectedZones = Asia;
              selectedZoneLabel = Asia.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "America") {
              selectedZones = America;
              selectedZoneLabel = America.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "Australia") {
              selectedZones = Australia;
              selectedZoneLabel = Australia.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "Europe") {
              selectedZones = Europe;
              selectedZoneLabel = Europe.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "Indian") {
              selectedZones = Indian;
              selectedZoneLabel = Indian.find(
                (region) => region.id === sub_region
              )?.label;
            }

            this.setState({
              zones: selectedZones,
              zone: sub_region,
              zoneLabel: selectedZoneLabel,
              main: main_region,
              scheduling: {
                ...this.state.scheduling,
                WorkingDays: response.data.default_availability.WorkingDays,
                main_region: main_region,
                sub_region: sub_region,
              },
            });
          }

          this.setState({
            groups: response.data.groups,
            permissions: response.data.coachPartners,
            // tokens: response.data.tokens.default_fields,
            tokens: response.data.tokens.default_fields.filter(
              (field) =>
                field.key !== "first_name" &&
                field.key !== "last_name" &&
                field.key !== "phone" &&
                field.key !== "unsubscribe" &&
                field.key !== "currency_symbol"
            ),
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      //Then with the error genereted...
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getData = () => {
    fetch(`${ApiUrl}` + "get/edit/meeting", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ` + uToken(),
      },

      body: JSON.stringify({
        id: this.state.id,
        coach_id: this.state.coach_id,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true && response.data.record) {
          this.setState({
            record: response.data.record,
            selectedPermission: response.data.record.permissions,
          });

          if (response.data.schedule_settings) {
            this.setState({
              scheduling: response.data.schedule_settings,
            });

            let selectedZoneLabel;
            let selectedZones;
            let main_region = response.data.schedule_settings.main_region;
            let sub_region = response.data.schedule_settings.sub_region;

            if (main_region === "Asia") {
              selectedZones = Asia;
              selectedZoneLabel = Asia.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "America") {
              selectedZones = America;
              selectedZoneLabel = America.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "Australia") {
              selectedZones = Australia;
              selectedZoneLabel = Australia.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "Europe") {
              selectedZones = Europe;
              selectedZoneLabel = Europe.find(
                (region) => region.id === sub_region
              )?.label;
            } else if (main_region === "Indian") {
              selectedZones = Indian;
              selectedZoneLabel = Indian.find(
                (region) => region.id === sub_region
              )?.label;
            }

            this.setState({
              zones: selectedZones,
              zone: sub_region,
              zoneLabel: selectedZoneLabel,
              main: main_region,
              scheduling: {
                ...this.state.scheduling,
                main_region: main_region,
                sub_region: sub_region,
              },
            });
          }

          if (response.data.record.nylas_calendar_id) {
            this.setState({
              selected_calendar_id: response.data.record.nylas_calendar_id,
            });
          }

          if (response.data.record.nylas_calendar_name) {
            this.setState({
              nylas_calendar_name: response.data.record.nylas_calendar_name,
            });
          }

          if (response.data.schedule_form) {
            this.setState({
              scheduling_forms: response.data.schedule_form,
            });
          }

          if (response.data.automation_settings) {
            this.setState({
              automation: response.data.automation_settings,
            });
          }

          if (response.data.meeting_participants) {
            this.setState({
              selectedParticipants: response.data.meeting_participants.map(
                (participant) => participant.user_id
              ),
            });
          }

          this.setState((prevState) => {
            let record = Object.assign({}, prevState.record);
            record.permission = response.data.record.permission;
            return { record };
          });
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  getNylasData = async (queryString = "", data) => {
    this.setState({ loaded: false });

    try {
      const response = await fetch(`${ApiUrl}` + "meetings" + queryString, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ` + uToken(),
        },
        body: JSON.stringify(data),
      });

      const result = await response.json();

      if (result.status === true) {
        const firstRecord =
          result.data.records.length > 0 ? result.data.records[0] : null;
        // console.log("First Record:", firstRecord);

        if (firstRecord) {
          this.setState({
            total_pages: result.data.total,
            records: [firstRecord],
            roles: result.data.roles,
            firstRecord: firstRecord,
          });
          return firstRecord;
        }
      } else {
        toast(result.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      this.setState({ loaded: true });
    }
  };

  handleSubmit = async (event, errors, values) => {
    values.id = this.state.id;

    if (values.event_title === "") {
      swal({
        title: "Please add your event title",
        icon: "warning",
      });
      return;
    }

    for (let reminder of this.state.scheduling.reminders) {
      if (!reminder.email_subject || reminder.email_subject === "") {
        swal({
          title: "Please set email subject for all reminders",
          icon: "warning",
        });
        return;
      }
    }

    if (this.state.record.add_lead === "yes" && values.group_id === "") {
      swal({
        title: "Please select a group",
        icon: "warning",
      });
      return;
    }

    if (this.state.tab === "1") {
      this.toggle("2");
      return;
    } else if (this.state.tab === "2") {
      this.toggle("6");
      return;
    } else if (this.state.tab === "6") {
      this.toggle("3");
      return;
    } else if (this.state.tab === "3") {
      if (!this.handleNextButtonClick(null, false)) {
        return false;
      }
      this.setState({ loaded: false });

      values.schedule_settings = JSON.stringify(this.state.scheduling);
      values.schedule_form = JSON.stringify(this.state.scheduling_forms);
      values.automation_settings = JSON.stringify(this.state.automation);
      values.location_type = this.state.record.location_type;
      values.meeting_provider = this.state.record.meeting_provider;
      values.duration = this.state.record.duration;

      try {
        const response = await fetch(`${ApiUrl}add/meeting`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${uToken()}`,
          },
          body: JSON.stringify(values),
        });

        const data = await response.json();
        this.setState({ loaded: true });

        if (data.status === true) {
          if (!this.state.id && this.form) {
            this.form.reset();
          }
          const firstRecord = await this.getNylasData();
          console.log("First Record Response:", firstRecord);

          // Check if firstRecord has a valid calendar_id
          if (this.state.id) {
            console.log("Latest Calendar ID:", this.state.id);
            this.getSycnCalendarData(this.state.id);
            this.props.history.push(
              `/meeting/add/${bcrypt(this.state.id)}?tab=4`
            );
          }

          if (!this.state.id && firstRecord?.calendar_id) {
            console.log("Latest Calendar ID:", firstRecord.calendar_id);
            this.getSycnCalendarData(firstRecord.calendar_id);
            this.props.history.push(
              `/meeting/add/${bcrypt(firstRecord.calendar_id)}?tab=4`
            );
          } else {
            console.log(
              "No calendar ID found, calling getSycnCalendarData without ID."
            );
            this.getSycnCalendarData();
          }

          this.toggle("4");
        } else {
          toast(data.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      console.log("Not found");
      this.setState({ warningAlert: true });
    }
  };

  assignData(email = null) {
    fetch(`${ApiUrl}team/list`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          const activeMembers = response.data.records.filter(
            (record) => record.status === "Active"
          );
          console.log("active mem", activeMembers);
          const currentUser = {
            id: uInfo().user_id,
            name: uInfo().name,
            email: email || this.state.email || this.state.email_new,
            type: "Organizer",
            selected_calendar_id: this.state.selected_calendar_id,
            nylas_calendar_name: this.state.nylas_calendar_name,
            calendars: [
              {
                nylas_calendar_id: this.state.selected_calendar_id,
                event_title: "",
              },
            ],
          };

          this.setState({
            memberData: [currentUser, ...activeMembers],
          });
          // this.setState({ memberData: activeMembers });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleMemberChange(selectedOptions) {
    this.setState({ selectedMembers: selectedOptions });
  }

  handleLeadChange = (e) => {
    this.setState((prevState) => ({
      record: {
        ...prevState.record,
        add_lead: e.target.value,
      },
    }));
  };

  toggleRoundRobin = (e) => {
    // this.setState((prevState) => ({
    //   record: {
    //     ...prevState.record,
    //     meeting_type: e.target.value,
    //   },
    // }));

    this.setState({
      record: {
        ...this.state.record,
        meeting_type:
          e.target.value === "round-robin" ? "round-robin" : "one-on-one",
      },
    });
  };

  handleGroup = (selectedGroup) => {
    this.setState((prevState) => {
      let record = Object.assign({}, prevState.record);
      record.group_id = selectedGroup.group_id;
      return { record };
    });
  };

  handlePermission = (selectedPermission) => {
    var filter = [];
    if (selectedPermission) {
      selectedPermission.map((fil) => {
        filter.push(fil.user_id);
      });
    }

    this.setState((prevState) => {
      let record = Object.assign({}, prevState.record);
      record.permission = filter.toString();
      return { record };
    });

    this.setState({
      selectedPermission: selectedPermission,
    });
  };

  toggle = (index) => {
    if (this.state.tab !== index) {
      this.setState({
        tab: index,
      });
    }
  };

  StartHours = () => {
    const items = [];
    new Array(24).fill().forEach((acc, index) => {
      items.push(moment({ hour: index }).format("h:mm A"));
      items.push(moment({ hour: index, minute: 30 }).format("h:mm A"));
    });
    return items;
  };

  isStartTimeBeforeEndTime = (startTime, endTime) => {
    const start = moment(startTime, "h:mm A");
    const end = moment(endTime, "h:mm A");
    return start.isBefore(end);
  };

  handleNextButtonClick = (e, shdToggle) => {
    if (e) {
      e.preventDefault();
    }

    const { WorkingDays } = this.state.scheduling;
    let isValid = true;
    let errorMessage = "";

    for (let i = 0; i < WorkingDays.length; i++) {
      const day = WorkingDays[i];

      if (day.available === "on") {
        const isValidTime = this.isStartTimeBeforeEndTime(day.start, day.end);
        if (!isValidTime) {
          isValid = false;
          errorMessage = `Start time must be earlier than the End time for ${day.day}.`;
          break;
        }
      }
    }

    if (!isValid) {
      toast(errorMessage, {
        hideProgressBar: true,
        position: "top-center",
        transition: Flip,
        className: "toast-custom-cls error",
        autoClose: 2000,
      });
      return false;
    }

    if (shdToggle) {
      this.toggle("6");
    } else {
      return true;
    }
  };

  addField = (data) => {
    this.setState((prevState) => ({
      scheduling_forms: [
        ...prevState.scheduling_forms,
        {
          [`label`]: data.label,
          key: data.key,
          [`alternative_text`]: data.alternative_text,
          required: false,
        },
      ],
    }));
    this.setState({
      addMore: false,
    });
  };

  removeReminder = (index) => {
    let automation = this.state.automation;
    automation.reminders.splice(index, 1);
    this.setState(automation);
  };

  removeOptionClick(k, e) {
    let scheduling_forms = [...this.state.scheduling_forms];
    scheduling_forms.splice(k, 1);
    this.setState({ scheduling_forms });
  }

  getSycnCalendarData = (calendarId) => {
    this.setState({ loaded: false });

    fetch(`${ApiUrl}get/google/calander`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({ id: calendarId }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          console.log("calendar response", response);
          let email = "";
          try {
            const authData = JSON.parse(response.data.calendar.auth_token);
            email = authData.email || "";
            this.assignData(email);
          } catch (error) {
            console.error("Error parsing auth_token:", error);
          }

          this.setState({
            records: response.data.calArr.filter(
              (calendar) => !calendar.read_only
            ),
            email,
            loaded: true,
          });
        } else {
          console.log("calendar response");
          this.setState({ records: [], email: "", loaded: true });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loaded: true });
      });
  };

  getAuthEmail = () => {
    this.setState({ loaded: false });

    fetch(`${ApiUrl}get/google/calander/email`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      // body: JSON.stringify({ id: calendarId }),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log("nylas auth user", response);
        if (response.status === true) {
          this.setState({
            email_new: response.data.email,
            nylas_provider: response.data.nylas_provider,
            has_zoom: response.data.has_zoom,

            loaded: true,
          });
        } else {
          this.setState({
            email_new: "",
            nylas_provider: "",
            has_zoom: false,
            loaded: true,
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        this.setState({ loaded: true });
      });
  };

  submitGoogleCalanderId = async (calendarId) => {
    if (!this.state.memberData || this.state.memberData.length === 0) {
      swal({
        title: "No members available.",
        icon: "warning",
      });
      return false;
    }
    if (this.state.record.meeting_type == "round-robin") {
      if (
        !this.state.selectedParticipants ||
        this.state.selectedParticipants.length === 0
      ) {
        swal({
          title: "No participants selected.",
          icon: "warning",
        });
        return false;
      }
    }

    const {
      selected_calendar_summary,
      selected_calendar_name,
      selected_calendar_id,
      nylas_calendar_name,
    } = this.state;

    let participant_calendars = [];
    if (this.state.record.meeting_type === "one-on-one") {
      const matchedMember = this.state.memberData.find(
        (member) => member.id === uInfo().user_id
      );

      if (matchedMember) {
        participant_calendars = [
          {
            member_id: matchedMember.id,
            nylas_calendar_id:
              matchedMember.calendars?.[0]?.nylas_calendar_id || "",
          },
        ];
      }
    } else {
      participant_calendars = this.state.memberData
        .filter((member) => this.state.selectedParticipants.includes(member.id))
        .map((member) => ({
          member_id: member.id,
          nylas_calendar_id: member.calendars?.[0]?.nylas_calendar_id || "",
        }));
    }

    try {
      const response = await fetch(`${ApiUrl}update/google/calander`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${uToken()}`,
        },
        body: JSON.stringify({
          id: calendarId,
          google_id: selected_calendar_id || "",
          nylas_calendar_name:
            selected_calendar_summary ||
            selected_calendar_name ||
            nylas_calendar_name ||
            "",
          // meeting_type: "Nylas",
          participant_calendars,
          meeting_type: this.state.record.meeting_type,
          scheduling_method: this.state.scheduling.scheduling_method,
        }),
      });

      const data = await response.json();

      if (data.status === true) {
        const firstRecord = await this.getNylasData();
        if (firstRecord?.calendar_id) {
          this.getSycnCalendarData(firstRecord.calendar_id);
        } else {
          console.log(
            "No calendar ID found, calling getSycnCalendarData without ID."
          );
          this.getSycnCalendarData();
        }

        this.props.history.push("/settings/calendar");
      } else {
        toast(data.message, {
          hideProgressBar: true,
          position: "top-center",
          transition: Flip,
          className: "toast-custom-cls error",
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  submitMemberGoogleCalanderId = (calendarId) => {
    if (this.state.selected_calendar_id === "") {
      swal({
        title: "Please choose a calendar.",
        icon: "warning",
      });
      return false;
    }

    const {
      selected_calendar_summary,
      selected_calendar_name,
      selected_calendar_id,
      nylas_calendar_name,
    } = this.state;
    let participant_calendars = [];
    console.log("this.state.email", this.state.email);
    console.log("matchedMember", this.state.memberData);

    const matchedMember = this.state.memberData.find(
      // (member) => member.email === this.state.email
      (member) => member.id === uInfo().user_id
    );
    if (matchedMember) {
      participant_calendars = [
        {
          member_id: matchedMember.id,
          nylas_calendar_id:
            matchedMember.calendars?.[0]?.nylas_calendar_id || "",
        },
      ];
    }

    fetch(`${ApiUrl}update/google/calander`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${uToken()}`,
      },
      body: JSON.stringify({
        id: calendarId,
        google_id: selected_calendar_id || "",
        nylas_calendar_name:
          selected_calendar_summary ||
          selected_calendar_name ||
          nylas_calendar_name ||
          "",
        calendar_type: "Nylas",
        participant_calendars,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === true) {
          this.props.history.push("/settings/calendar");
        } else {
          toast(response.message, {
            hideProgressBar: true,
            position: "top-center",
            transition: Flip,
            className: "toast-custom-cls error",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  handleCheckboxChange = (event, user_id) => {
    const { selectedParticipants } = this.state;
    if (event.target.checked) {
      this.setState({
        selectedParticipants: [...selectedParticipants, user_id],
      });
    } else {
      this.setState({
        selectedParticipants: selectedParticipants.filter(
          (id) => id !== user_id
        ),
      });
    }
  };

  // Handle change in meeting reminder duration
  handleCustomEmail = (event) => {
    this.setState({
      scheduling: {
        ...this.state.scheduling,
        custom_email: event.target.value,
      },
    });
  };

  handleCustomEmailBody = (event) => {
    this.setState({
      scheduling: {
        ...this.state.scheduling,
        custom_email_body: event.target.value,
      },
    });
  };

  handleMeetingReminderDuration = (event, index) => {
    const newReminders = [...this.state.scheduling.reminders];
    newReminders[index].minutes_before_event = event.target.value;
    this.setState({
      scheduling: { ...this.state.scheduling, reminders: newReminders },
    });
  };

  handleMeetingRecipient = (event, index) => {
    const newReminders = [...this.state.scheduling.reminders];
    newReminders[index].recipient = event.target.value;
    this.setState({
      scheduling: { ...this.state.scheduling, reminders: newReminders },
    });
  };

  // Handle change in email subject
  handleEmailSubjectChange = (event, index) => {
    const newReminders = [...this.state.scheduling.reminders];
    newReminders[index].email_subject = event.target.value;
    this.setState({
      scheduling: { ...this.state.scheduling, reminders: newReminders },
    });
  };

  // Add new meeting reminder
  addMeetingReminder = () => {
    this.setState((prevState) => {
      const currentReminders = Array.isArray(prevState.scheduling.reminders)
        ? prevState.scheduling.reminders
        : [];

      return {
        scheduling: {
          ...prevState.scheduling,
          reminders: [
            ...currentReminders,
            {
              minutes_before_event: 15,
              email_subject: this.state.eventTitle
                ? this.state.eventTitle
                : this.state.record.event_title
                ? `Event Reminder - ${this.state.record.event_title}`
                : "",
            },
          ],
        },
      };
    });
  };

  // Remove a meeting reminder
  removeMeetingReminder = (index) => {
    const newReminders = [...this.state.scheduling.reminders];
    newReminders.splice(index, 1);
    this.setState({
      scheduling: { ...this.state.scheduling, reminders: newReminders },
    });
  };

  handleEventTitle = (e) => {
    e.preventDefault();
    this.setState({
      eventTitle: "Event Reminder - " + e.target.value,
    });

    const updatedReminders = this.state.scheduling.reminders.map((reminder) => {
      if (!reminder.email_subject) {
        reminder.email_subject = "Event Reminder - " + e.target.value;
      }
      return reminder;
    });

    this.setState((prevState) => ({
      scheduling: {
        ...prevState.scheduling,
        reminders: updatedReminders,
      },
    }));
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title> {this.state.page_name} </title>
        </Helmet>
        <Row>
          <Col sm={6}>
            <div className="page-title-box">
              <h4> {this.state.page_name} </h4>
              <ol className="breadcrumb m-0">
                <li key={0} className="breadcrumb-item active">
                  {ProductName}
                </li>
                <li key={1} className="breadcrumb-item">
                  <Link to="#"> {this.state.page_name} </Link>
                </li>
              </ol>
            </div>
          </Col>
          <Col sm={6}>
            <div className="page-title-box text-align-right">
              <Button
                className="btn-sm"
                type="button"
                color="secondary"
                // onClick={() => this.props.history.goBack()}
                onClick={() => this.props.history.push("/settings/calendar")}
              >
                Back
              </Button>
            </div>
          </Col>
        </Row>
        <ToastContainer />
        {this.state.warningAlert ? (
          <SweetAlert
            title="Please fill all fields"
            warning
            onConfirm={() => {
              this.setState({
                warningAlert: false,
              });
            }}
          />
        ) : null}
        <Row>
          <Col className="col-lg-8 offset-lg-2">
            <Card className="CustomShadow NlyasTabs">
              <Row className="h-100">
                <div className="col-lg-3 pe-0">
                  <Nav tabs className="d-flex flex-column pt-0">
                    <NavItem>
                      <NavLink
                        className={this.state.tab == 1 ? "active me-0" : "me-0"}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        Overview
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.tab == 2 ? "active me-0" : "me-0"}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        Availability
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.tab == 6 ? "active me-0" : "me-0"}
                        onClick={() => {
                          this.toggle("6");
                        }}
                      >
                        Email & Reminders
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.tab == 3 ? "active me-0" : "me-0"}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        Booking Form
                      </NavLink>
                      <NavItem>
                        <NavLink
                          className={
                            this.state.tab == 4 ? "active me-0" : "me-0"
                          }
                          onClick={() => {
                            this.getSycnCalendarData(this.state.id);
                            this.toggle("4");
                          }}
                        >
                          Map Event Calendar
                        </NavLink>
                      </NavItem>
                      {uInfo().type === "Coach" && (
                        <NavItem>
                          <NavLink
                            className={
                              this.state.tab == 5 ? "active me-0" : "me-0"
                            }
                            onClick={() => {
                              this.toggle("5");
                            }}
                          >
                            Participants
                          </NavLink>
                        </NavItem>
                      )}
                    </NavItem>
                  </Nav>
                </div>
                <div className="col-lg-9 ps-0">
                  <div className="CustomBorder">
                    <Loader loaded={this.state.loaded} className="spinner">
                      <AvForm
                        onSubmit={this.handleSubmit}
                        ref={(c) => (this.form = c)}
                        className="needs-validation"
                      >
                        <TabContent activeTab={this.state.tab}>
                          <TabPane tabId="1">
                            <Row>
                              <Col md={12}>
                                <div className="EventHead">
                                  <h2>Event Information</h2>
                                </div>
                                <ModalBody>
                                  <div className="col-md-12 mb-3  d-none">
                                    <AvField
                                      name="meeting_type"
                                      value={this.state.record.meeting_type}
                                      label="Calendar type"
                                      className="form-select"
                                      type="select"
                                      disabled
                                    >
                                      <option value={"one-on-one"}>
                                        One-on-one
                                      </option>
                                      <option value={"group"}>Group</option>
                                      <option value={"round-robin"}>
                                        Round-robin
                                      </option>
                                    </AvField>
                                  </div>

                                  <div className="col-md-12 mb-3">
                                    <AvField
                                      name="event_title"
                                      value={this.state.record.event_title}
                                      label="Event title"
                                      className="form-control"
                                      required
                                      onChange={(e) => this.handleEventTitle(e)}
                                    />
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <label>Event duration </label>
                                    <div className="d-flex">
                                      <select
                                        className="form-select me-2"
                                        value={this.state.record.duration}
                                        onChange={
                                          this.handleSelectDurationChange
                                        }
                                      >
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                        <option value="60">60</option>
                                      </select>
                                      <input
                                        className="form-control"
                                        value={"Minutes"}
                                        readOnly
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-4 mb-3">
                                    <label>Event meeting or location</label>
                                    <div className="d-flex">
                                      <div class="input-group">
                                        <div class="input-group-prepend">
                                          <span
                                            class="input-group-text h-100"
                                            id="validationTooltipUsernamePrepend"
                                            style={{ width: "50px" }}
                                          >
                                            {this.state.record.location_type ===
                                            "None" ? (
                                              <i
                                                className="mdi mdi-map-marker-off-outline"
                                                style={{ fontSize: "15.8px" }}
                                              ></i>
                                            ) : this.state.record
                                                .location_type ===
                                              "conference_meeting" ? (
                                              <img
                                                // src={
                                                //   this.state.nylas_provider ===
                                                //   "google"
                                                //     ? meetGoogle
                                                //     : outlookTeams
                                                // }
                                                src={
                                                  this.state.record
                                                    .meeting_provider ===
                                                  "google"
                                                    ? meetGoogle
                                                    : this.state.record
                                                        .meeting_provider ===
                                                      "microsoft"
                                                    ? outlookTeams
                                                    : this.state.record
                                                        .meeting_provider ===
                                                      "zoom"
                                                    ? zoom_icon
                                                    : ""
                                                }
                                              />
                                            ) : (
                                              <i
                                                className="mdi mdi-map-marker-outline"
                                                style={{ fontSize: "15.8px" }}
                                              ></i>
                                            )}
                                          </span>
                                        </div>
                                        <select
                                          className="form-select me-2"
                                          value={
                                            // this.state.record.location_type
                                            this.state.record.meeting_provider
                                          }
                                          onChange={this.handleSelectChange}
                                        >
                                          <option value="None">None</option>
                                          <option value="custom">
                                            Custom Location
                                          </option>

                                          {/* {this.state.nylas_provider ===
                                            "google" && (
                                            <option value="conference_meeting">
                                              Google Meet
                                            </option>
                                          )}

                                          {this.state.nylas_provider ===
                                            "microsoft" && (
                                            <option value="conference_meeting">
                                              Microsoft Teams
                                            </option>
                                          )} */}

                                          {this.state.nylas_provider ===
                                            "google" && (
                                            <option value="google">
                                              Google Meet
                                            </option>
                                          )}

                                          {this.state.nylas_provider ===
                                            "microsoft" && (
                                            <option value="microsoft">
                                              Microsoft Teams
                                            </option>
                                          )}

                                          {this.state.has_zoom && (
                                            <option value="zoom">
                                              Zoom Meetings
                                            </option>
                                          )}
                                        </select>
                                      </div>
                                    </div>
                                  </div>
                                  {this.state.record.location_type &&
                                    this.state.record.location_type !==
                                      "None" &&
                                    this.state.record.location_type !==
                                      "conference_meeting" && (
                                      <div className="col-md-12 mb-3">
                                        <AvField
                                          name="location"
                                          value={this.state.record.location}
                                          label="Location"
                                          className="form-control"
                                        />
                                      </div>
                                    )}

                                  <div className="col-md-12 mb-2">
                                    <label className="d-flex">
                                      Add as
                                      <div className="d-flex ms-2 me-3">
                                        <input
                                          className="my-auto me-1 ms-1"
                                          type="radio"
                                          value="yes"
                                          checked={
                                            this.state.record.add_lead === "yes"
                                          }
                                          onChange={this.handleLeadChange}
                                        />
                                        <p className="my-auto">Lead</p>
                                      </div>
                                      <div className="d-flex">
                                        <input
                                          className="my-auto me-1 ms-1"
                                          type="radio"
                                          value="no"
                                          checked={
                                            this.state.record.add_lead === "no"
                                          }
                                          onChange={this.handleLeadChange}
                                        />
                                        <p className="my-auto">Guest</p>
                                      </div>
                                    </label>
                                  </div>

                                  {this.state.record.add_lead === "yes" && (
                                    <div className="col-md-12 mb-3">
                                      <label className="control-label">
                                        {" "}
                                        Group{" "}
                                      </label>
                                      <Select
                                        getOptionLabel={(option) =>
                                          option.title
                                        }
                                        getOptionValue={(option) =>
                                          option.group_id
                                        }
                                        value={this.state.groups.filter(
                                          ({ group_id }) =>
                                            group_id ===
                                            this.state.record.group_id
                                        )}
                                        isMulti={false}
                                        options={this.state.groups}
                                        classNamePrefix="select2-selection"
                                        name="groups_id"
                                        onChange={(e) => {
                                          this.handleGroup(e);
                                        }}
                                      />
                                      <AvField
                                        required
                                        type="hidden"
                                        name="group_id"
                                        value={this.state.record.group_id}
                                      />
                                    </div>
                                  )}

                                  <div className="col-md-12 mb-3 d-none">
                                    <label className="control-label">
                                      {" "}
                                      Assign to{" "}
                                    </label>
                                    <Select
                                      getOptionLabel={(option) => option.name}
                                      getOptionValue={(option) =>
                                        option.user_id
                                      }
                                      isMulti={true}
                                      value={this.state.selectedPermission}
                                      options={this.state.permissions}
                                      classNamePrefix="select2-selection"
                                      onChange={(e) => {
                                        this.handlePermission(e);
                                      }}
                                    />
                                    <AvField
                                      type="hidden"
                                      name="permission"
                                      value={this.state.record.permission}
                                    />
                                  </div>

                                  <div className="col-md-12 mb-3">
                                    <AvField
                                      name="description"
                                      value={this.state.record.description}
                                      label="Event description"
                                      className="form-control"
                                      type="textarea"
                                    />
                                  </div>
                                </ModalBody>
                                <ModalFooter className="border-0 pt-0">
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                  >
                                    Next
                                  </button>
                                </ModalFooter>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={"2"}>
                            <Row>
                              <Col md={12}>
                                <div className="EventHead">
                                  <h2>Event Availability</h2>
                                </div>
                                <ModalBody>
                                  <div className="row">
                                    <div className="col-md-12 mb-3">
                                      <AvField
                                        name="calendar_url"
                                        value={this.state.record.calendar_url}
                                        className="form-control"
                                        type="hidden"
                                        readOnly
                                      />

                                      <AvField
                                        name="main_region"
                                        value={
                                          this.state.scheduling.main_region
                                        }
                                        className="form-control"
                                        type="hidden"
                                      />
                                      <AvField
                                        name="sub_region"
                                        value={this.state.scheduling.sub_region}
                                        className="form-control"
                                        type="hidden"
                                      />
                                    </div>

                                    <div className="col-md-12 mb-3">
                                      <div className="d-flex">
                                        <div className="d-flex me-3">
                                          <label className="me-1">
                                            Set Region :{" "}
                                          </label>
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              id="dropdown-autoclose-true"
                                              className="btn btn-primary"
                                            >
                                              {this.state.main == ""
                                                ? "Your Timezone"
                                                : this.state.main}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                              {this.state.Timezone.map(
                                                (zone, i) => {
                                                  return (
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        this.changeMainZone(
                                                          zone
                                                        )
                                                      }
                                                      eventKey={i}
                                                    >
                                                      {zone}
                                                    </Dropdown.Item>
                                                  );
                                                }
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>

                                        <div className="d-flex">
                                          <label className="me-1">
                                            Set Subregion :{" "}
                                          </label>
                                          <Dropdown>
                                            <Dropdown.Toggle
                                              id="dropdown-autoclose-true"
                                              className="btn btn-primary"
                                            >
                                              {this.state.zoneLabel == ""
                                                ? "Your Timezone"
                                                : this.state.zoneLabel}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu as={this.CustomMenu}>
                                              {this.state.zones.map(
                                                (zone, i) => {
                                                  return (
                                                    <Dropdown.Item
                                                      onClick={() =>
                                                        this.handleZoneChange(
                                                          zone.id,
                                                          zone.label
                                                        )
                                                      }
                                                      eventKey={i}
                                                    >
                                                      {zone.label}
                                                    </Dropdown.Item>
                                                  );
                                                }
                                              )}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                    </div>

                                    <label>Set Availability</label>
                                    {this.state.scheduling.WorkingDays.map(
                                      (d, j) => {
                                        return (
                                          <div key={j} className="row mb-3">
                                            <div className="col-md-3">
                                              <AvGroup>
                                                <AvInput
                                                  placeholder="Enter business day"
                                                  className="form-control"
                                                  name={"day_" + d.key}
                                                  value={d.day}
                                                  readOnly
                                                  required
                                                />
                                                <AvFeedback>
                                                  {" "}
                                                  Required Field *
                                                </AvFeedback>
                                              </AvGroup>
                                            </div>
                                            <div className="col-md-3">
                                              <AvField
                                                type="select"
                                                name={"available_" + d.key}
                                                label=""
                                                value={d.available}
                                                onChange={(e) =>
                                                  this.setState((prevState) => {
                                                    let WorkingDays =
                                                      Object.assign(
                                                        {},
                                                        prevState.scheduling
                                                          .WorkingDays
                                                      );
                                                    WorkingDays[j].available =
                                                      e.target.value;
                                                    return {
                                                      WorkingDays,
                                                    };
                                                  })
                                                }
                                              >
                                                <option value="on">On</option>
                                                <option value="off">Off</option>
                                              </AvField>
                                            </div>
                                            <div className="col-md-3">
                                              <AvField
                                                type="select"
                                                name={"start_time_" + d.key}
                                                label=""
                                                value={d.start}
                                                onChange={(e) =>
                                                  this.setState((prevState) => {
                                                    let WorkingDays =
                                                      Object.assign(
                                                        {},
                                                        prevState.scheduling
                                                          .WorkingDays
                                                      );
                                                    WorkingDays[j].start =
                                                      e.target.value;
                                                    return {
                                                      WorkingDays,
                                                    };
                                                  })
                                                }
                                                disabled={
                                                  d.available == "off"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {this.StartHours().map(
                                                  (hour) => {
                                                    return (
                                                      <option
                                                        key={hour}
                                                        value={hour}
                                                      >
                                                        {hour}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </AvField>
                                            </div>
                                            <div className="col-md-3">
                                              <AvField
                                                type="select"
                                                name={"end_time_" + d.key}
                                                label=""
                                                value={d.end}
                                                onChange={(e) =>
                                                  this.setState((prevState) => {
                                                    let WorkingDays =
                                                      Object.assign(
                                                        {},
                                                        prevState.scheduling
                                                          .WorkingDays
                                                      );
                                                    WorkingDays[j].end =
                                                      e.target.value;
                                                    return {
                                                      WorkingDays,
                                                    };
                                                  })
                                                }
                                                disabled={
                                                  d.available == "off"
                                                    ? true
                                                    : false
                                                }
                                              >
                                                {this.StartHours().map(
                                                  (hour) => {
                                                    return (
                                                      <option
                                                        key={hour}
                                                        value={hour}
                                                      >
                                                        {hour}
                                                      </option>
                                                    );
                                                  }
                                                )}
                                              </AvField>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}

                                    <div className="col-md-12 mb-3 d-none">
                                      <AvField
                                        name="title"
                                        value={this.state.scheduling.title}
                                        label="Title"
                                        className="form-control"
                                        onChange={(e) =>
                                          this.setState((prevState) => {
                                            let scheduling = Object.assign(
                                              {},
                                              prevState.scheduling
                                            );
                                            scheduling.title = e.target.value;
                                            return { scheduling };
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </ModalBody>
                                <ModalFooter>
                                  <button
                                    className="btn btn-secondary waves-effect waves-light"
                                    type="button"
                                    onClick={() => this.toggle("1")}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="btn btn-primary waves-effect waves-light pull-left"
                                    // type="submit"
                                    type="button"
                                    onClick={(e) =>
                                      this.handleNextButtonClick(e, true)
                                    }
                                  >
                                    Next
                                  </button>
                                </ModalFooter>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </TabPane>
                          <TabPane tabId={"3"}>
                            <Row>
                              <Col md={12}>
                                <div className="EventHead">
                                  <h2>Booking Form</h2>
                                </div>
                                <ModalBody>
                                  <div className="metting-details">
                                    <p>
                                      Ask prospects and customers a few
                                      qualifying questions as they are booking
                                      meetings with you.
                                    </p>
                                    {this.state.scheduling_forms.map(
                                      (row, k) => {
                                        return (
                                          <>
                                            <div
                                              className="row mb-3 mt-3"
                                              key={k}
                                            >
                                              <div className="col-md-4">
                                                <AvField
                                                  name={row.key}
                                                  placeholder={`${row.label}`}
                                                  className="form-control"
                                                  readOnly
                                                />
                                              </div>
                                              <div className="col-md-4">
                                                <AvField
                                                  name={row.key}
                                                  value={row.alternative_text}
                                                  placeholder={
                                                    "Enter alternative title"
                                                  }
                                                  className="form-control"
                                                  onChange={(e) =>
                                                    this.setState(
                                                      (prevState) => {
                                                        let scheduling_forms =
                                                          Object.assign(
                                                            {},
                                                            prevState.scheduling_forms
                                                          );
                                                        scheduling_forms[
                                                          k
                                                        ].alternative_text =
                                                          e.target.value;
                                                        return scheduling_forms;
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                              <div className="col-md-2">
                                                <AvField
                                                  name={row.key}
                                                  label={`Required`}
                                                  className="form-control mr-5"
                                                  type="checkbox"
                                                  value={row.required}
                                                  checked={row.required}
                                                  disabled={
                                                    k <= 2 ? true : false
                                                  }
                                                  onChange={(e) =>
                                                    this.setState(
                                                      (prevState) => {
                                                        let scheduling_forms =
                                                          Object.assign(
                                                            {},
                                                            prevState.scheduling_forms
                                                          );
                                                        scheduling_forms[
                                                          k
                                                        ].required =
                                                          e.target.checked;
                                                        return scheduling_forms;
                                                      }
                                                    )
                                                  }
                                                />
                                              </div>
                                              {k > 2 && (
                                                <div className="col-md-2">
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger btn-sm"
                                                    onClick={this.removeOptionClick.bind(
                                                      this,
                                                      k
                                                    )}
                                                  >
                                                    <i className="ion ion-md-trash">
                                                      {" "}
                                                    </i>
                                                  </button>
                                                </div>
                                              )}
                                            </div>
                                          </>
                                        );
                                      }
                                    )}

                                    <button
                                      type="button"
                                      className="btn btn-primary my-2 p-1"
                                      onClick={() =>
                                        this.setState({ addMore: true })
                                      }
                                    >
                                      + Add other form field
                                    </button>
                                    {this.state.addMore && (
                                      <div className="col-md-12 mb-3 min-height-200">
                                        <Select
                                          getOptionLabel={(option) =>
                                            option.label
                                          }
                                          getOptionValue={(option) =>
                                            option.key
                                          }
                                          isMulti={false}
                                          options={((ids) =>
                                            this.state.tokens.filter(
                                              (n) => !ids.includes(n.key)
                                            ))(
                                            this.state.scheduling_forms.map(
                                              ({ key }) => key
                                            )
                                          )}
                                          classNamePrefix="select2-selection"
                                          name="country_id"
                                          onChange={(e) => {
                                            this.addField(e);
                                          }}
                                        />
                                      </div>
                                    )}
                                  </div>
                                </ModalBody>
                                <ModalFooter>
                                  <button
                                    className="btn btn-secondary waves-effect waves-light"
                                    type="button"
                                    onClick={() => this.toggle("1")}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="btn btn-primary waves-effect waves-light pull-left"
                                    type="submit"
                                  >
                                    Submit
                                  </button>
                                </ModalFooter>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId={"4"}>
                            <Row>
                              <Col md={12}>
                                <div className="EventHead">
                                  <h2>Map an Event Calendar</h2>
                                </div>
                                <ModalBody>
                                  <div className="metting-details">
                                    <table className="table table-bordered">
                                      <tbody>
                                        {this.state.records.length === 0 ? (
                                          <>
                                            <div
                                              className="alert alert-info"
                                              role="alert"
                                            >
                                              Please complete the steps above to
                                              continue.
                                            </div>
                                            {/* <button
                                              className="btn btn-secondary"
                                              onClick={() =>
                                                this.props.history.push(
                                                  "/settings/calendar"
                                                )
                                              }
                                            >
                                              Skip
                                            </button> */}
                                          </>
                                        ) : (
                                          this.state.records.map((row, i) => (
                                            <tr key={i}>
                                              <td>{row.summary || row.name}</td>
                                              <td>
                                                <input
                                                  type="radio"
                                                  name="calendarSelection"
                                                  value={row.id}
                                                  checked={
                                                    this.state
                                                      .selected_calendar_id ===
                                                    row.id
                                                  }
                                                  onChange={() => {
                                                    this.assignData();
                                                    this.setState({
                                                      selected_calendar_id:
                                                        row.id,
                                                      nylas_calendar_name:
                                                        row.name,
                                                      selected_calendar_name:
                                                        row.name,
                                                      selected_calendar_summary:
                                                        row.summary,
                                                      memberData:
                                                        this.state.memberData.map(
                                                          (member) =>
                                                            member.id ===
                                                            uInfo().user_id
                                                              ? {
                                                                  ...member,
                                                                  calendars: [
                                                                    {
                                                                      nylas_calendar_id:
                                                                        row.id,
                                                                      event_title:
                                                                        "",
                                                                    },
                                                                    ...member.calendars.slice(
                                                                      1
                                                                    ),
                                                                  ],
                                                                }
                                                              : member
                                                        ),
                                                    });
                                                  }}
                                                />
                                              </td>
                                            </tr>
                                          ))
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </ModalBody>
                                {uInfo().type === "Coach" && (
                                  <ModalFooter>
                                    <button
                                      className="btn btn-primary waves-effect waves-light pull-left"
                                      type="button"
                                      onClick={() => {
                                        if (
                                          this.state.selected_calendar_id === ""
                                        ) {
                                          swal({
                                            title: "Please choose a calendar.",
                                            icon: "warning",
                                          });
                                          return;
                                        }

                                        this.toggle("5");
                                      }}
                                    >
                                      Next
                                    </button>
                                  </ModalFooter>
                                )}

                                {uInfo().type === "CoachPartner" &&
                                  this.state.records.length > 0 && (
                                    <ModalFooter>
                                      <button
                                        className="btn btn-primary waves-effect waves-light pull-left"
                                        type="button"
                                        onClick={() =>
                                          this.submitMemberGoogleCalanderId(
                                            this.state.id
                                          )
                                        }
                                      >
                                        Submit
                                      </button>
                                    </ModalFooter>
                                  )}
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </TabPane>

                          <TabPane tabId={"5"}>
                            <Row>
                              <Col md={12}>
                                <div className="EventHead">
                                  <h2>Participants</h2>
                                </div>
                                <ModalBody>
                                  <div className="metting-details">
                                    <div className="mb-4">
                                      <label className="block text-sm font-large">
                                        Organizer
                                      </label>
                                      <input
                                        type="email"
                                        value={
                                          this.state.email ||
                                          this.state.email_new ||
                                          ""
                                        }
                                        readOnly
                                        className="form-control border-1 py-2"
                                      />
                                    </div>
                                    <table className="table table-bordered">
                                      <tbody>
                                        <div className="col-md-12 mb-2">
                                          <label className="d-flex">
                                            Round-Robin Mode:
                                            <div className="d-flex">
                                              <input
                                                className="my-auto me-1 ms-1"
                                                type="radio"
                                                value="one-on-one"
                                                checked={
                                                  this.state.record
                                                    .meeting_type ===
                                                  "one-on-one"
                                                }
                                                onChange={this.toggleRoundRobin}
                                              />
                                              <p className="my-auto">No</p>
                                            </div>
                                            <div className="d-flex ms-2 me-3">
                                              <input
                                                className="my-auto me-1 ms-1"
                                                type="radio"
                                                value="round-robin"
                                                checked={
                                                  this.state.record
                                                    .meeting_type ===
                                                  "round-robin"
                                                }
                                                onChange={this.toggleRoundRobin}
                                              />
                                              <p className="my-auto">Yes</p>
                                            </div>
                                          </label>
                                        </div>
                                        {this.state.record.meeting_type ===
                                          "round-robin" && (
                                          <>
                                            <div className="col-md-12 mb-3 d-flex">
                                              <label className="my-auto">
                                                Select a scheduling method :
                                              </label>

                                              <div className="d-flex">
                                                <select
                                                  className="form-select me-2"
                                                  value={
                                                    this.state.scheduling
                                                      .scheduling_method
                                                  }
                                                  onChange={
                                                    this.handleScheduleMethod
                                                  }
                                                >
                                                  <option value="max-availability">
                                                    Max Availability
                                                  </option>
                                                  <option value="max-fairness">
                                                    Max Fairness
                                                  </option>
                                                </select>
                                              </div>
                                            </div>

                                            <div className="form-group mb-3">
                                              {/* <label>Round-Robin Member(s)</label> */}
                                              <table className="table">
                                                <thead>
                                                  <tr>
                                                    <th>Select</th>
                                                    <th>Name</th>
                                                    <th>Email</th>
                                                    <th>Type</th>
                                                    <th>Default Event</th>
                                                  </tr>
                                                </thead>
                                                <tbody>
                                                  {this.state.memberData &&
                                                    this.state.memberData.map(
                                                      (member) => {
                                                        const selectedCalendar =
                                                          member.calendars?.[0];
                                                        const isSelected =
                                                          this.state.selectedParticipants.includes(
                                                            member.id
                                                          );

                                                        return (
                                                          <tr key={member.id}>
                                                            <td>
                                                              <input
                                                                type="checkbox"
                                                                checked={
                                                                  isSelected
                                                                }
                                                                onChange={(e) =>
                                                                  this.handleCheckboxChange(
                                                                    e,
                                                                    member.id
                                                                  )
                                                                }
                                                                disabled={
                                                                  !selectedCalendar &&
                                                                  member.id !==
                                                                    uInfo()
                                                                      .user_id
                                                                }
                                                              />
                                                            </td>
                                                            <td>
                                                              {member.name}
                                                            </td>
                                                            <td>
                                                              {member.email}
                                                            </td>
                                                            <td>
                                                              {member.type}
                                                            </td>
                                                            <td>
                                                              {selectedCalendar
                                                                ? selectedCalendar.event_title
                                                                : "No Calendar Available"}
                                                            </td>
                                                            {selectedCalendar && (
                                                              <input
                                                                type="hidden"
                                                                name={`team_members_${member.id}`}
                                                                value={
                                                                  selectedCalendar.nylas_calendar_id
                                                                }
                                                              />
                                                            )}
                                                          </tr>
                                                        );
                                                      }
                                                    )}
                                                </tbody>
                                              </table>
                                            </div>
                                          </>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </ModalBody>

                                <ModalFooter>
                                  <button
                                    className="btn btn-primary waves-effect waves-light pull-left"
                                    type="button"
                                    onClick={() =>
                                      this.submitGoogleCalanderId(this.state.id)
                                    }
                                  >
                                    Submit
                                  </button>
                                </ModalFooter>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </TabPane>

                          {/* EVENT EMAILS AND REMINDERS */}
                          <TabPane tabId={"6"}>
                            <Row>
                              <Col md={12}>
                                <div className="EventHead">
                                  <h2>Email & Reminders</h2>
                                </div>
                                <ModalBody>
                                  <div className="meeting-details">
                                    {/* MEETING REMINDER SECTION */}
                                    <div className="col-md-12 mb-3">
                                      <AvField
                                        name="custom_email"
                                        value={
                                          this.state.scheduling.custom_email
                                        }
                                        label="Custom Email Title"
                                        className="form-control mb-2"
                                        onChange={(e) =>
                                          this.handleCustomEmail(e)
                                        }
                                      />

                                      <AvField
                                        name="custom_email_body"
                                        value={
                                          this.state.scheduling
                                            .custom_email_body
                                        }
                                        label="Additional Body"
                                        className="form-control"
                                        type="textarea"
                                        onChange={(e) =>
                                          this.handleCustomEmailBody(e)
                                        }
                                      />

                                      <h5 className="mb-3 mt-3">
                                        Meeting Reminders
                                      </h5>
                                      {this.state.scheduling.reminders &&
                                        this.state.scheduling.reminders.map(
                                          (reminder, index) => (
                                            <div
                                              className="row align-items-center mb-2"
                                              key={index}
                                            >
                                              {/* Reminder Duration */}
                                              <div className="col-md-3">
                                                <label>Reminder Time</label>
                                                <div className="d-flex">
                                                  <select
                                                    required
                                                    name={`minutes_before_event_${index}`}
                                                    className="form-select me-2"
                                                    value={
                                                      reminder.minutes_before_event
                                                    }
                                                    onChange={(e) =>
                                                      this.handleMeetingReminderDuration(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    <option value="15">
                                                      15
                                                    </option>
                                                    <option value="30">
                                                      30
                                                    </option>
                                                    <option value="45">
                                                      45
                                                    </option>
                                                    <option value="60">
                                                      60
                                                    </option>
                                                  </select>
                                                  <input
                                                    className="form-control"
                                                    value={"Minutes"}
                                                    readOnly
                                                  />
                                                </div>
                                              </div>

                                              {/* EMAIL RECEIPTS */}
                                              <div className="col-md-3">
                                                <label>Recipient</label>
                                                <div className="d-flex">
                                                  <select
                                                    name={`recipient${index}`}
                                                    className="form-select me-2"
                                                    value={reminder.recipient}
                                                    onChange={(e) =>
                                                      this.handleMeetingRecipient(
                                                        e,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    {/* <option value="">
                                                      Set Recipient
                                                    </option> */}

                                                    <option value="all">
                                                      All
                                                    </option>
                                                    <option value="host">
                                                      Host
                                                    </option>
                                                    <option value="guest">
                                                      Guest
                                                    </option>
                                                  </select>
                                                </div>
                                              </div>

                                              {/* Email Subject */}
                                              <div
                                                className="col-md-5"
                                                key={index}
                                              >
                                                {/* <label>Email Subject</label> */}
                                                {/* <input
                                                  type="text"
                                                  className="form-control"
                                                  placeholder="Enter Email Subject"
                                                  value={reminder.email_subject}
                                                  onChange={(e) =>
                                                    this.handleEmailSubjectChange(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                /> */}
                                                <AvField
                                                  key={index}
                                                  name={`email_subject_${index}`}
                                                  value={reminder.email_subject}
                                                  label="Email Subject"
                                                  className="form-control"
                                                  required
                                                  onChange={(e) =>
                                                    this.handleEmailSubjectChange(
                                                      e,
                                                      index
                                                    )
                                                  }
                                                />
                                              </div>

                                              {/* Remove Button */}
                                              <div className="col-md-1 text-end">
                                                <button
                                                  type="button"
                                                  className="btn btn-danger btn-sm mt-4"
                                                  onClick={() =>
                                                    this.removeMeetingReminder(
                                                      index
                                                    )
                                                  }
                                                >
                                                  X
                                                </button>
                                              </div>
                                            </div>
                                          )
                                        )}

                                      {/* Add Reminder Button */}
                                      <button
                                        type="button"
                                        className="btn btn-sm btn-primary mt-2"
                                        onClick={this.addMeetingReminder}
                                      >
                                        + Add Reminder
                                      </button>
                                    </div>
                                  </div>
                                </ModalBody>

                                <ModalFooter>
                                  <button
                                    className="btn btn-secondary waves-effect waves-light"
                                    type="button"
                                    onClick={() => this.toggle("2")}
                                  >
                                    Back
                                  </button>
                                  <button
                                    className="btn btn-primary waves-effect waves-light"
                                    type="submit"
                                  >
                                    Next
                                  </button>
                                </ModalFooter>
                              </Col>
                              <Col md={6}></Col>
                            </Row>
                          </TabPane>
                        </TabContent>
                      </AvForm>
                    </Loader>
                  </div>
                </div>
              </Row>
            </Card>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
